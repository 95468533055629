import {
    LOAD_CUSTOMER,
    UPDATE_COMPLETED,
    UPDATE_ANNOTATION,
    ADD_READING_TIME,
    UPDATE_EXAM_STATUS,
    DELETE_EXAM_PARTICIPATION,
    DOWNLOAD_CERTIFICATE,
    UPDATE_BLOCK_COURSE_PARTICIPATION,
    BOOK_COURSE_PARTICIPATION_EXTENSION,
    ADD_PAYMENT,
    DELETE_PAYMENT,
    DOWNLOAD_INVOICE,
    UPDATE_IS_PAID,
    TRIGGER_PAYMENT_REQUEST,
    CORRECT_INVOICE,
} from './actions';
import { GET_CUSTOMER, GET_LOADING } from './selectors';

export const CustomerActions = {
    LOAD_CUSTOMER,
    UPDATE_COMPLETED,
    UPDATE_ANNOTATION,
    ADD_READING_TIME,
    UPDATE_EXAM_STATUS,
    DELETE_EXAM_PARTICIPATION,
    DOWNLOAD_CERTIFICATE,
    UPDATE_BLOCK_COURSE_PARTICIPATION,
    BOOK_COURSE_PARTICIPATION_EXTENSION,
    ADD_PAYMENT,
    DELETE_PAYMENT,
    DOWNLOAD_INVOICE,
    UPDATE_IS_PAID,
    TRIGGER_PAYMENT_REQUEST,
    CORRECT_INVOICE,
};

export const CustomerSelectors = {
    GET_CUSTOMER,
    GET_LOADING,
};

export * from './effects';
export * from './reducer';
