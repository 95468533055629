import { Injectable } from '@angular/core';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Id, UUID } from '@cna/shared/generic-types';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../apps/ak-jura/frontends/backoffice/src/environments/environment';
import { Repetitorium } from '@cna/projects/ak-jura/shared/interfaces';

@Injectable({ providedIn: 'root' })
export class RepetitoriumService extends EntityCollectionServiceBase<Repetitorium> {
    constructor(
        elementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient
    ) {
        super('Repetitorium', elementsFactory);
    }

    /***
     * return all Examinables for the given moduleId WITHOUT saving them to the store.
     * @param courseId UUID of the course Entity.
     */
    getByCourseId(courseId: Id | UUID): Observable<Repetitorium> {
        this.setLoading(true);
        const ret = this.http.get<Repetitorium>(
            environment.apiHost + '/repetitorium/',
            {
                params: {
                    courseId:
                        typeof courseId === 'string'
                            ? courseId
                            : courseId.toString(),
                },
            }
        );
        this.setLoading(false);

        return ret;
    }
}
