<p-table
    [value]="timeExpenditures$ | async"
    dataKey="courseParticipation.id"
    [autoLayout]="true"
    rowExpandMode="single"
>
    <ng-template pTemplate="header">
        <tr>
            <th></th>
            <th>Name</th>
            <th>Produkt</th>
            <th>Rechtsgebiet</th>
            <th>Lernzeit</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <p>Dieser Nutzer hat noch keine Lernzeiten erfasst.</p>
    </ng-template>
    <ng-template pTemplate="body" let-course let-expanded="expanded">
        <tr class="course-row">
            <td>
                <button
                    type="button"
                    pButton
                    pRipple
                    [pRowToggler]="course"
                    class="p-button-text p-button-rounded p-button-plain p-mr-2"
                    [icon]="
                        expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                    "
                ></button>
            </td>
            <td>{{ course.courseParticipation.bookedCourse.description }}</td>
            <td>
                {{
                    course.courseParticipation.bookedCourse.type
                        | courseType: course.courseParticipation.isTest
                }}
            </td>
            <td>
                {{
                    course.courseParticipation.bookedCourse.associatedLegalArea
                        .name
                }}
            </td>
            <td>{{ course.duration | secondsToHours }}</td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-course>
        <tr>
            <td></td>
            <td colspan="3">
                <p-table
                    [value]="course.months"
                    dataKey="month"
                    styleClass="p-datatable-sm"
                    [autoLayout]="true"
                    rowExpandMode="single"
                >
                    <ng-template
                        pTemplate="body"
                        let-month
                        let-expandedLvl2="expanded"
                    >
                        <tr class="month-row">
                            <td>
                                <button
                                    type="button"
                                    pButton
                                    pRipple
                                    [pRowToggler]="month"
                                    class="p-button-text p-button-rounded p-button-plain p-mr-2"
                                    [icon]="
                                        expandedLvl2
                                            ? 'pi pi-chevron-down'
                                            : 'pi pi-chevron-right'
                                    "
                                ></button>
                            </td>
                            <td>{{ month.month | date: 'yyyy LLLL' }}</td>
                            <td>{{ month.duration | secondsToHours }}</td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-month>
                        <tr>
                            <td></td>
                            <td colspan="2" style="padding: 0">
                                <p-table
                                    [value]="month.timeExpenditures"
                                    dataKey="date"
                                    styleClass="p-datatable-sm"
                                    [autoLayout]="true"
                                >
                                    <ng-template
                                        pTemplate="body"
                                        let-timeExpenditure
                                    >
                                        <tr class="day-row">
                                            <td style="width: 200px">
                                                {{
                                                    timeExpenditure.date
                                                        | date: 'dd.MM.yyyy'
                                                }}
                                            </td>
                                            <td>
                                                {{
                                                    timeExpenditure.duration
                                                        | secondsToHours
                                                }}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </td>
        </tr>
    </ng-template>
</p-table>
