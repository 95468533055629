import {
    SET_FILTER,
    SET_QUERY,
    LOAD_FILTER_VALUES,
    TOGGLE_SELECT,
    SELECT_ALL,
    DESELECT_ALL,
    SEND_MAIL_TO_SELECTED,
    DOWNLOAD_USER_EXPORT,
    DOWNLOAD_USER_EXPORT_SUCCESS,
    DOWNLOAD_USER_EXPORT_FAILURE,
} from './actions';
import {
    GET_QUERY,
    GET_FILTER,
    GET_CURRENT_LIST,
    GET_TOTAL,
    GET_LOADING,
    GET_ONE,
    GET_LEGAL_AREAS_FILTER_VALUES,
    GET_EXAM_FILTER_VALUES,
    GET_SELECTED,
    GET_SELECTED_COUNT,
    GET_IS_NONE_SELECTED,
    GET_IS_ALL_SELECTED,
} from './selectors';

export const CustomerListActions = {
    SET_QUERY,
    SET_FILTER,
    LOAD_FILTER_VALUES,
    TOGGLE_SELECT,
    SELECT_ALL,
    DESELECT_ALL,
    MAIL_TO_SELECTED: SEND_MAIL_TO_SELECTED,
    DOWNLOAD_USER_EXPORT,
    DOWNLOAD_USER_EXPORT_SUCCESS,
    DOWNLOAD_USER_EXPORT_FAILURE,
};

export const CustomerListSelectors = {
    GET_QUERY,
    GET_FILTER,
    GET_CURRENT_LIST,
    GET_TOTAL,
    GET_LOADING,
    GET_ONE,
    GET_LEGAL_AREAS_FILTER_VALUES,
    GET_EXAM_FILTER_VALUES,
    GET_SELECTED,
    GET_SELECTED_COUNT,
    GET_IS_NONE_SELECTED,
    GET_IS_ALL_SELECTED,
};

export * from './effects';
export * from './reducer';
