import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { HttpClient } from '@angular/common/http';
import * as fromGlobal from './actions';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { EntityCollectionService, EntityServices } from '@ngrx/data';
import { Book, Examinable } from '@cna/projects/ak-jura/shared/interfaces';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';

@Injectable()
export class GlobalEffects {
    private examinableService: EntityCollectionService<Examinable>;
    constructor(
        private actions$: Actions,
        private http: HttpClient,
        private entityServices: EntityServices,
        private messageService: MessageService,
        private store: Store
    ) {
        this.examinableService =
            entityServices.getEntityCollectionService<Examinable>('Examinable');
    }

    //----------------------------------------------------------------------
    //Insert examinables bulk
    //----------------------------------------------------------------------

    insertBulkExaminables$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromGlobal.insertBulkExaminables),
            switchMap(action => {
                return this.http.post(
                    environment.apiHost + '/examinable/bulk',
                    {
                        bulk: action.examinables,
                    }
                );
            }),
            map((examinables: Examinable[]) =>
                fromGlobal.insertBulkExaminablesSuccess({
                    examinables: examinables,
                })
            ),
            catchError(() => of(fromGlobal.insertBulkExaminablesFailure()))
        );
    });

    /***
     * updates the local metadata store with getAll cause of the maybe deleted entities
     */
    insertBulkExaminablesSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(fromGlobal.insertBulkExaminablesSuccess),
                tap(() => {
                    this.examinableService.getAll();
                    this.messageService.add({
                        severity: 'success',
                        summary: 'Favoriten erfolgreich gespeichert!',
                        detail: 'Die Favoriten für diesen Kurs erfolgreich gespeichert!',
                    });
                })
            );
        },
        { dispatch: false }
    );

    insertBulkExaminablesFailure$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(fromGlobal.insertBulkExaminablesFailure),
                tap(err => {
                    // Show Error Toast
                    this.messageService.add({
                        severity: 'error',
                        summary: 'Fehler beim speichern der Favoriten!',
                        detail: 'Beim speichern der Favoriten ist ein Fehler aufgetreten, bitte versuchen sie es später noch einmal!',
                    });
                })
            );
        },
        { dispatch: false }
    );

    //----------------------------------------------------------------------
    //Get Examinables by ModuleId
    //----------------------------------------------------------------------

    getExaminablesByModuleId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromGlobal.getExaminablesByModuleId),
            mergeMap(action =>
                this.http.get<Examinable[]>(
                    environment.apiHost +
                        '/examinable/byModuleId/' +
                        action.moduleId
                )
            ),
            map((val: Examinable[]) =>
                fromGlobal.getExaminablesByModuleIdSuccess({ examinables: val })
            ),
            catchError(err => of(fromGlobal.getExaminablesByModuleIdFailure))
        );
    });

    //----------------------------------------------------------------------
    //Get Examinables by ModuleId
    //----------------------------------------------------------------------

    getBookById$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromGlobal.getBookById),
            switchMap(action =>
                this.http.get(environment.apiHost + '/book/' + action)
            ),
            map((val: Book) => fromGlobal.getBookByIdSuccess({ book: val })),
            catchError(err => fromGlobal.getBookByIdFailure)
        );
    });

    getBookByIdSuccess$ = createEffect(
        () => {
            return this.actions$.pipe(
                ofType(fromGlobal.getBookByIdSuccess),
                tap(() => {
                    this.messageService.add({
                        severity: 'info',
                        summary: ' Laden erfolgreich',
                    });
                })
            );
        },
        { dispatch: false }
    );

    getBookByIdFailure$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromGlobal.getBookByIdFailure),
            tap(err => {
                console.log('err getbookbyId Error');
                console.log(err);
            })
        );
    });
}
