import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExaminableService } from './services/examinable.service';
import { ModuleDocumentTemplateService } from './services/module-document-template.service';
import { RepetitoriumService } from './services/repetitorium.service';

@NgModule({
    imports: [CommonModule],
    providers: [
        ExaminableService,
        ModuleDocumentTemplateService,
        RepetitoriumService,
    ],
})
export class DataServicesModule {}
