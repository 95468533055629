<div *ngIf="showPage">
    <cna-status-bar
        [authService]="authService"
        [environment]="environment"
        [showLogo]="false"
        [showReservationsListButton]="false"
    ></cna-status-bar>
    <header>
        <div class="p-d-flex">
            <button
                pButton
                type="button"
                icon="pi pi-align-center"
                label="Menü"
                class="p-button-lg p-button-outlined p-button-secondary p-button-raised p-d-flex p-as-center p-mr-2"
                (click)="menu.toggle($event)"
                style="height: 70px; width: 160px"
            ></button>
            <img
                src="../assets/AkJuraLogo.svg"
                routerLink=""
                alt="image"
                style="width: 300px; border: none; outline: none"
            />
            <p-tieredMenu
                #menu
                [model]="menuItems"
                [popup]="true"
                appendTo="body"
            ></p-tieredMenu>
        </div>
    </header>

    <router-outlet></router-outlet>
    <p-toast position="bottom-right"></p-toast>
</div>
