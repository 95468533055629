import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CUSTOMER_LIST_STORE_NAME } from './reducer';
import { CustomerListState } from '../../model';

/** Helper selectors */
const GET_STATE = createFeatureSelector<CustomerListState>(
    CUSTOMER_LIST_STORE_NAME
);

const GET_QUERIED_SUBS = createSelector(GET_STATE, ({ query, subs }) =>
    subs.slice(query.first, query.first + query.rows)
);

const GET_DICTIONARY = createSelector(GET_STATE, ({ data }) => data);

const GET_FILTER_VALUES = createSelector(
    GET_STATE,
    ({ filterValues }) => filterValues
);

const GET_ALL_SUBS = createSelector(GET_STATE, ({ subs }) => subs);

/** Global Selectors */
export const GET_QUERY = createSelector(GET_STATE, ({ query }) => query);

export const GET_FILTER = createSelector(GET_STATE, ({ filter }) => filter);

export const GET_CURRENT_LIST = createSelector(
    GET_QUERIED_SUBS,
    GET_DICTIONARY,
    (subs, dictionary) =>
        subs.map(sub => dictionary[sub]).filter(res => res !== undefined)
);

export const GET_TOTAL = createSelector(GET_STATE, ({ subs }) => subs.length);

export const GET_LOADING = createSelector(GET_STATE, ({ loading }) => loading);

export const GET_ONE = (sub: string) =>
    createSelector(GET_DICTIONARY, dictionary => dictionary[sub]);

export const GET_EXAM_FILTER_VALUES = createSelector(
    GET_FILTER_VALUES,
    ({ exam }) => exam
);

export const GET_LEGAL_AREAS_FILTER_VALUES = createSelector(
    GET_FILTER_VALUES,
    ({ legalAreas }) => legalAreas
);

export const GET_SELECTED = createSelector(
    GET_STATE,
    ({ selected }) => selected
);

export const GET_SELECTED_COUNT = createSelector(
    GET_SELECTED,
    selected => selected.length
);

export const GET_IS_NONE_SELECTED = createSelector(
    GET_SELECTED_COUNT,
    selectedCount => selectedCount === 0
);

export const GET_IS_ALL_SELECTED = createSelector(
    GET_SELECTED,
    GET_ALL_SUBS,
    (selectedSubs, currentSubs) =>
        selectedSubs.length !== 0 &&
        currentSubs.reduce(
            (result, sub) => result && selectedSubs.includes(sub),
            true
        )
);

/** Internal Selectors */
export const GET_SUBS_TO_LOAD = createSelector(
    GET_QUERIED_SUBS,
    GET_DICTIONARY,
    (subs, dictionary) => subs.filter(sub => !dictionary[sub])
);
