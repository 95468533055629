import { createAction, props } from '@ngrx/store';
import {
    CustomerDetails,
    TimeExpenditure,
    Payment,
    CourseParticipationExtension,
    CustomerDetailsCourseParticipation,
    CustomerDetailsCourseParticipationInvoice,
} from '../../../../../../../shared/interfaces/src';
import type {
    CurrencyValue,
    UUID,
} from '../../../../../../../../../shared/generic-types/src';
import { ExamStatus } from '../../../../../../../shared/interfaces/src/lib/data-management/exam/exam-participation';
import { Dictionary } from '../../utils';
import { BookingType } from '../../model';

export const LOAD_CUSTOMER = createAction(
    '[Customer] Load customer details',
    props<{ id: UUID }>()
);

export const LOAD_CUSTOMER_SUCCESS = createAction(
    '[Customer] Load customer details success',
    props<{ customer: Dictionary<CustomerDetails> }>()
);

export const LOAD_CUSTOMER_FAILURE = createAction(
    '[Customer] Load customer details failure',
    props<{ message: string }>()
);

export const UPDATE_COMPLETED = createAction(
    '[Customer | CourseParticipation] Update completed flag',
    props<{ sub: UUID; id: UUID; completed: boolean }>()
);

export const UPDATE_COMPLETED_SUCCESS = createAction(
    '[Customer | CourseParticipation] Update completed flag success',
    props<{ sub: UUID; id: UUID; completed: boolean }>()
);

export const UPDATE_COMPLETED_FAILURE = createAction(
    '[Customer | CourseParticipation] Update completed failure',
    props<{ message: string }>()
);

export const UPDATE_ANNOTATION = createAction(
    '[Customer] Update annotation',
    props<{ id: UUID; sub: UUID; annotation: string }>()
);

export const UPDATE_ANNOTATION_SUCCESS = createAction(
    '[Customer] Update annotation success',
    props<{ id: UUID; sub: UUID; annotation: string }>()
);

export const UPDATE_ANNOTATION_FAILURE = createAction(
    '[Customer] Update annotation failure',
    props<{ message: string }>()
);

export const ADD_READING_TIME = createAction(
    '[customer] Add Reading time',
    props<{ timeExpenditure: TimeExpenditure; sub: UUID }>()
);

export const ADD_READING_TIME_SUCCESS = createAction(
    '[customer] Add Reading time success',
    props<{ timeExpenditure: TimeExpenditure; sub: UUID }>()
);

export const ADD_READING_TIME_FAILURE = createAction(
    '[customer] Add Reading time failure',
    props<{ message: string }>()
);

export const UPDATE_EXAM_STATUS = createAction(
    '[Customer] Update exam participation status',
    props<{ examParticipationId: UUID; status: ExamStatus; sub: UUID }>()
);

export const UPDATE_EXAM_STATUS_SUCCESS = createAction(
    '[Customer] Update exam participation status success',
    props<{ examParticipationId: UUID; status: ExamStatus; sub: UUID }>()
);

export const UPDATE_EXAM_STATUS_FAILURE = createAction(
    '[Customer] Update exam participation status failure',
    props<{ message: string }>()
);

export const DELETE_EXAM_PARTICIPATION = createAction(
    '[Customer] Delete exam participation',
    props<{ examParticipationId: UUID; sub: UUID }>()
);

export const DELETE_EXAM_PARTICIPATION_SUCCESS = createAction(
    '[Customer] Delete exam participation success',
    props<{ examParticipationId: UUID; sub: UUID }>()
);

export const DELETE_EXAM_PARTICIPATION_FAILURE = createAction(
    '[Customer] Delete exam participation failure',
    props<{ message: string }>()
);

export const DOWNLOAD_CERTIFICATE = createAction(
    '[Customer] Download certificate',
    props<{ sub: UUID; courseParticipationId }>()
);

export const DOWNLOAD_CERTIFICATE_SUCCESS = createAction(
    '[Customer] Download certificate success'
);

export const DOWNLOAD_CERTIFICATE_FAILURE = createAction(
    '[Customer] Download certificate failure',
    props<{ message: string }>()
);

export const UPDATE_BLOCK_COURSE_PARTICIPATION = createAction(
    '[Customer] Update course participation is blocked',
    props<{ courseParticipationId: UUID; isBlocked: boolean; sub: UUID }>()
);

export const UPDATE_BLOCK_COURSE_PARTICIPATION_SUCCESS = createAction(
    '[Customer] Update course participation is blocked success',
    props<{ courseParticipationId: UUID; isBlocked: boolean; sub: UUID }>()
);

export const UPDATE_BLOCK_COURSE_PARTICIPATION_FAILURE = createAction(
    '[Customer] Update course participation is blocked failure',
    props<{ message: string }>()
);

export const BOOK_COURSE_PARTICIPATION_EXTENSION = createAction(
    '[Customer] Book course participation extension',
    props<{ courseParticipationId: UUID; duration: number; sub: UUID }>()
);

export const BOOK_COURSE_PARTICIPATION_EXTENSION_SUCCESS = createAction(
    '[Customer] Book course participation extension success',
    props<{
        sub: UUID;
        courseParticipationExtension: CourseParticipationExtension;
        newValidUntil: string;
    }>()
);

export const BOOK_COURSE_PARTICIPATION_EXTENSION_FAILURE = createAction(
    '[Customer] Book course participation extension failure',
    props<{ message: string }>()
);

export const ADD_PAYMENT = createAction(
    '[Customer] Add payment',
    props<{
        date: string;
        amount: number;
        invoiceId: UUID;
        bookingType: BookingType;
        sub: UUID;
        customerId: UUID;
        isCorrection: boolean;
    }>()
);

export const ADD_PAYMENT_SUCCESS = createAction(
    '[Customer] Add payment success',
    props<{ payment: Payment; sub: UUID }>()
);

export const ADD_PAYMENT_FAILURE = createAction(
    '[Customer] Add payment failure',
    props<{ message: string }>()
);

export const DELETE_PAYMENT = createAction(
    '[Customer] Delete payment',
    props<{ id: UUID; sub: UUID; amount: CurrencyValue }>()
);

export const DELETE_PAYMENT_SUCCESS = createAction(
    '[Customer] Delete payment success',
    props<{ id: UUID; sub: UUID; amount: CurrencyValue }>()
);

export const DELETE_PAYMENT_FAILURE = createAction(
    '[Customer] Delete payment failure',
    props<{ message: string }>()
);

export const DOWNLOAD_INVOICE = createAction(
    '[Customer] Download Invoice',
    props<{
        invoiceId: UUID;
        bookingType: BookingType;
        isCorrection: boolean;
    }>()
);

export const DOWNLOAD_INVOICE_SUCCESS = createAction(
    '[Customer] Download Invoice success'
);

export const DOWNLOAD_INVOICE_FAILURE = createAction(
    '[Customer] Download Invoice failure',
    props<{ message: string }>()
);

export const UPDATE_IS_PAID = createAction(
    '[Customer] Update is Paid',
    props<{ sub: UUID; bookingType: BookingType; id: UUID; isPaid: boolean }>()
);

export const UPDATE_IS_PAID_SUCCESS = createAction(
    '[Customer] Update is Paid success',
    props<{ sub: UUID; bookingType: BookingType; id: UUID; isPaid: boolean }>()
);

export const UPDATE_IS_PAID_FAILURE = createAction(
    '[Customer] Update is Paid',
    props<{ message: string }>()
);

export const TRIGGER_PAYMENT_REQUEST = createAction(
    '[Customer] Trigger payment request',
    props<{ id: UUID; sub: UUID; bookingType: BookingType }>()
);

export const TRIGGER_PAYMENT_REQUEST_SUCCESS = createAction(
    '[Customer] Trigger payment request success',
    props<{ id: UUID; sub: UUID; bookingType: BookingType }>()
);

export const TRIGGER_PAYMENT_REQUEST_FAILURE = createAction(
    '[Customer] Trigger payment request failure',
    props<{ message: string }>()
);

export const CORRECT_INVOICE = createAction(
    '[Customer] Correct invoice',
    props<{
        sub: UUID;
        totalAmount: number;
        bookingType: BookingType;
        invoiceId: string;
    }>()
);

export const CORRECT_INVOICE_SUCCESS = createAction(
    '[Customer] Correct invoice success',
    props<{
        sub: UUID;
        invoice: CustomerDetailsCourseParticipationInvoice['corrections'][number];
    }>()
);

export const CORRECT_INVOICE_FAILURE = createAction(
    '[Customer] Correct invoice failure',
    props<{ message: string }>()
);
