import { Component, Input, OnChanges } from '@angular/core';
import { CourseStatus } from '../../../../../../../shared/interfaces/src';

@Component({
    selector: 'cna-course-status',
    templateUrl: './course-status.component.html',
    styleUrls: ['./course-status.component.scss'],
})
export class CourseStatusComponent {
    @Input() status: CourseStatus | null;

    CourseStatus = CourseStatus;
}
