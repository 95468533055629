import { Filter } from './filter';
import { Observable } from 'rxjs';

export class MultiSelectFilter<T> extends Filter<T[] | undefined, T[]> {
    private options: { label: string; value: T }[];

    constructor(
        options:
            | { label: string; value: T }[]
            | Observable<{ label: string; value: T }[]>,
        defaultValue = []
    ) {
        super(defaultValue, false);

        if (options instanceof Observable) {
            options.subscribe(asyncOptions => (this.options = asyncOptions));
        } else {
            this.options = options;
        }
    }

    protected inputValueFromFilterValue(filterValue: T[] | undefined): T[] {
        if (filterValue === undefined) {
            return [];
        }
        return filterValue;
    }

    protected filterValueFromInputValue(inputValue: T[]): T[] | undefined {
        if (inputValue.length === 0) {
            return undefined;
        }
        return inputValue;
    }
}
