import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { filter, takeUntil } from 'rxjs/operators';
import { InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';

@Component({
    selector: 'cna-status-bar',
    templateUrl: './status-bar.component.html',
    styleUrls: ['./status-bar.component.css'],
})
export class StatusBarComponent implements OnInit, OnDestroy {
    @Input() authService;
    @Input() environment;
    @Input() showLogo: boolean;
    @Input() showReservationsListButton: boolean;

    userIsLoggedIn: boolean;
    private readonly _destroying$ = new Subject<void>();

    constructor(
        private router: Router,
        private msalBroadcastService: MsalBroadcastService
    ) {}

    ngOnInit(): void {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.setLoginDisplay();
            });
    }

    setLoginDisplay() {
        this.userIsLoggedIn =
            this.authService.instance.getAllAccounts().length > 0;
    }

    logoutClicked() {
        this.authService.logoutRedirect();
    }

    changePasswordClicked() {
        this.authService.loginRedirect({
            ...this.authService.instance.config.auth,
            authority: this.environment.passwordResetAuthority,
        });
    }

    loginClicked() {
        this.authService.loginPopup();
    }

    async showReservationsClicked() {
        await this.router.navigate(['myReservations']);
    }

    ngOnDestroy(): void {
        this._destroying$.next(null);
        this._destroying$.complete();
    }
}
