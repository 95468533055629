import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CustomerListService } from '../../services/customer-list.service';
import {
    SET_QUERY,
    SET_FILTER,
    LOAD_SUBS_SUCCESS,
    LOAD_SUBS_FAILURE,
    LOAD_LIST_DATA_SUCCESS,
    LOAD_LIST_DATA_FAILURE,
    LOAD_SUBS,
    LOAD_LIST_DATA,
    LOAD_FILTER_VALUES,
    LOAD_FILTER_VALUES_SUCCESS,
    LOAD_FILTER_VALUES_FAILURE,
    SEND_MAIL_TO_SELECTED,
    SEND_MAIL_TO_SELECTED_SUCCESS,
    SEND_MAIL_TO_SELECTED_FAILURE,
    DOWNLOAD_USER_EXPORT,
    DOWNLOAD_USER_EXPORT_SUCCESS,
    DOWNLOAD_USER_EXPORT_FAILURE,
} from './actions';
import {
    catchError,
    exhaustMap,
    map,
    mergeMap,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import {
    GET_FILTER,
    GET_QUERY,
    GET_SELECTED,
    GET_SUBS_TO_LOAD,
} from './selectors';
import { of } from 'rxjs';

@Injectable()
export class CustomerListEffects {
    constructor(
        private store: Store,
        private actions$: Actions,
        private service: CustomerListService
    ) {}

    loadSubsOnFilterUpdate$ = createEffect(() =>
        this.actions$.pipe(ofType(SET_FILTER), map(LOAD_SUBS))
    );

    loadSubs$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOAD_SUBS),
            withLatestFrom(this.store.select(GET_FILTER)),
            mergeMap(([, filter]) => this.service.getSubs(filter)),
            map(LOAD_SUBS_SUCCESS),
            catchError(error => of(LOAD_SUBS_FAILURE(error)))
        )
    );

    resetQueryOnLoadSubsSuccess$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOAD_SUBS_SUCCESS),
            withLatestFrom(this.store.select(GET_QUERY)),
            map(([, query]) =>
                SET_QUERY({
                    query: {
                        ...query,
                        first: 0,
                    },
                })
            )
        )
    );

    loadListDataOnQueryUpdate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(SET_QUERY),
            withLatestFrom(this.store.select(GET_SUBS_TO_LOAD)),
            map(([, subs]) => LOAD_LIST_DATA({ subs }))
        )
    );

    loadListDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOAD_LIST_DATA),
            mergeMap(({ subs }) => this.service.getListData(subs)),
            map(LOAD_LIST_DATA_SUCCESS),
            catchError(error => of(LOAD_LIST_DATA_FAILURE(error)))
        )
    );

    loadFilterValues$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOAD_FILTER_VALUES),
            mergeMap(() => this.service.getFilterValues()),
            map(LOAD_FILTER_VALUES_SUCCESS),
            catchError(error => of(LOAD_FILTER_VALUES_FAILURE({ error })))
        )
    );

    sendMailToSelected = createEffect(() =>
        this.actions$.pipe(
            ofType(SEND_MAIL_TO_SELECTED),
            withLatestFrom(this.store.select(GET_SELECTED)),
            mergeMap(([, selected]) => this.service.sendMailToMany(selected)),
            map(SEND_MAIL_TO_SELECTED_SUCCESS),
            catchError(error => of(SEND_MAIL_TO_SELECTED_FAILURE({ error })))
        )
    );

    downloadUserExport$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DOWNLOAD_USER_EXPORT),
            exhaustMap(() => this.service.downloadUserList()),
            map(DOWNLOAD_USER_EXPORT_SUCCESS),
            catchError(() => of(DOWNLOAD_USER_EXPORT_FAILURE()))
        )
    );
}
