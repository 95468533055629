import { Filter } from './filter';

export class DateFilter extends Filter<Date | undefined, Date | null> {
    constructor() {
        super(null, false);
    }

    protected inputValueFromFilterValue(
        filterValue: Date | undefined
    ): Date | null {
        if (filterValue === undefined) {
            return null;
        }
        return filterValue;
    }

    protected filterValueFromInputValue(
        inputValue: Date | null
    ): Date | undefined {
        if (inputValue === null) {
            return undefined;
        }
        return inputValue;
    }
}
