import { Pipe, PipeTransform } from '@angular/core';
import {
    CourseStatus,
    CourseType,
    CustomerListModuleStatus,
} from '../../../../../../shared/interfaces/src';
import { NumberToNumberingPipe } from '../../../../../../../../angular/ui/pipes/src/lib/number-to-numbering.pipe';

@Pipe({ name: 'courseStatus' })
export class CourseStatusPipe implements PipeTransform {
    constructor(private numberingPipe: NumberToNumberingPipe) {}

    transform(
        status?: CustomerListModuleStatus[],
        courseType?: CourseType,
        completed = false
    ): CourseStatus {
        if (!status) {
            return null;
        }

        if (completed) {
            return CourseStatus.EDUCATION_DONE;
        }

        switch (courseType) {
            case CourseType.TrainingCourse:
                return !this.isDone(status[0])
                    ? CourseStatus.TRAINING_IN_PROGRESS
                    : CourseStatus.TRAINING_DONE;
            case CourseType.EducationCourse:
                for (const moduleStatus of status) {
                    if (!this.isDone(moduleStatus)) {
                        switch (moduleStatus.sequentialNumber) {
                            case 1:
                                return CourseStatus.EDUCATION_MODULE_1;
                            case 2:
                                return CourseStatus.EDUCATION_MODULE_2;
                            case 3:
                                return CourseStatus.EDUCATION_MODULE_3;
                            case 4:
                                return CourseStatus.EDUCATION_MODULE_4;
                        }
                    }
                }
                return CourseStatus.EDUCATION_REPETITORIUM;
            default:
                throw new Error('Unknown course type: ' + courseType);
        }
    }

    private isDone(moduleStatus: CustomerListModuleStatus): boolean {
        return moduleStatus.controlTestPassed;
    }
}
