import { Injectable } from '@angular/core';
import {
    CourseParticipationExtension,
    CustomerBaseData,
    CustomerDetails,
    CustomerDetailsCourseParticipation,
    CustomerDetailsCourseParticipationInvoice,
    CustomerDetailsExamParticipation,
    CustomerListData,
    Invoice,
    Payment,
    TimeExpenditure,
} from '../../../../../../shared/interfaces/src';
import { forkJoin, Observable, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import type {
    CurrencyValue,
    UUID,
} from '../../../../../../../../shared/generic-types/src';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/backoffice/src/environments/environment';
import { BookingType, CourseParticipation } from '../model';
import { Dictionary } from '../utils';
import { map, mergeMap } from 'rxjs/operators';
import { saveAs } from 'file-saver';
import {
    ExamParticipation,
    ExamStatus,
} from '../../../../../../shared/interfaces/src/lib/data-management/exam/exam-participation';
import { DocxCreatorService } from '../../../../../../../../angular/file-creation/src/lib/docx-creator.service';
import { LearningModuleDocumentInfo } from '../../../../../../shared/interfaces/src/lib/learning-module-document-info';

@Injectable()
export class CustomerService {
    private readonly API_HOST: string;

    constructor(
        private http: HttpClient,
        private docxCreatorService: DocxCreatorService
    ) {
        this.API_HOST = environment.apiHost;
    }

    getCustomerDetails(
        id: UUID
    ): Observable<{ customer: Dictionary<CustomerDetails> }> {
        if (id) {
            return this.http
                .get<CustomerDetails>(`${this.API_HOST}/customer/${id}`)
                .pipe(
                    map(customer => ({
                        customer: {
                            [customer.sub]: customer,
                        },
                    }))
                );
        } else {
            return throwError(new Error('No customer id provided'));
        }
    }

    updateCompleted(
        sub: UUID,
        id: UUID,
        completed: boolean
    ): Observable<{
        sub: UUID;
        id: UUID;
        completed: boolean;
    }> {
        return this.http
            .patch<CourseParticipation>(
                `${this.API_HOST}/course-participation/${id}`,
                {
                    completed,
                }
            )
            .pipe(
                map(cp => ({
                    sub,
                    id,
                    completed: cp.completed,
                }))
            );
    }

    putAnnotation(
        sub: UUID,
        id: UUID,
        annotation: string,
        customerListData: CustomerListData
    ): Observable<{
        sub: UUID;
        id: UUID;
        annotation: string;
    }> {
        return this.http
            .put<CustomerBaseData>(
                `${this.API_HOST}/customer/${id}/annotation`,
                {
                    annotation,
                }
            )
            .pipe(
                map(result => ({
                    sub: result.sub,
                    id: result.id,
                    annotation: result.annotation,
                }))
            );
    }

    postPayment(
        customerId: UUID,
        sub: UUID,
        paymentDate: string,
        amount: number,
        bookingType: BookingType,
        invoiceId: UUID,
        isCorrection: boolean
    ): Observable<{ payment: Payment; sub: UUID }> {
        let invoice:
            | { courseParticipationInvoiceId: UUID }
            | { examParticipationInvoiceId: UUID }
            | { courseParticipationExtensionInvoiceId: UUID }
            | { courseParticipationInvoiceCorrectionId: UUID };
        switch (bookingType) {
            case BookingType.EXTENSION:
                invoice = { courseParticipationExtensionInvoiceId: invoiceId };
                break;
            case BookingType.COURSE:
                if (isCorrection) {
                    invoice = {
                        courseParticipationInvoiceCorrectionId: invoiceId,
                    };
                } else {
                    invoice = { courseParticipationInvoiceId: invoiceId };
                }
                break;
            case BookingType.EXAM:
                invoice = { examParticipationInvoiceId: invoiceId };
                break;
            default:
                throw new Error('Unknown booking type ' + bookingType);
        }
        return this.http
            .post<Payment>(`${this.API_HOST}/payment`, {
                customerId,
                paymentDate,
                amount,
                ...invoice,
            })
            .pipe(map(payment => ({ sub, payment })));
    }

    deletePayment(
        id: UUID,
        sub: UUID,
        amount: CurrencyValue
    ): Observable<{ id: UUID; sub: UUID; amount: CurrencyValue }> {
        return this.http
            .delete<void>(`${this.API_HOST}/payment/${id}`)
            .pipe(map(() => ({ id, sub, amount })));
    }

    getInvoice(
        bookingType: BookingType,
        id: UUID,
        isCorrection: boolean
    ): Observable<boolean> {
        let invoice$: Observable<Invoice>;
        switch (bookingType) {
            case BookingType.COURSE:
                if (isCorrection) {
                    invoice$ = this.http.get<Invoice>(
                        `${this.API_HOST}/invoices/course-participation/correction/${id}`
                    );
                } else {
                    invoice$ = this.http.get<Invoice>(
                        `${this.API_HOST}/invoices/course-participation/${id}`
                    );
                }
                break;
            case BookingType.EXTENSION:
                invoice$ = this.http.get<Invoice>(
                    `${this.API_HOST}/invoices/course-participation-extension/${id}`
                );
                break;
            case BookingType.EXAM:
                invoice$ = this.http.get<Invoice>(
                    `${this.API_HOST}/invoices/exam-participation/${id}`
                );
                break;
            default:
                throw new Error('Unknown booking type ' + bookingType);
        }

        return invoice$.pipe(
            map(invoice => {
                saveAs(
                    this.base64ToBlob(
                        invoice.base64Content,
                        invoice.contentType
                    ),
                    invoice.filename
                );
                return true;
            })
        );
    }

    patchExamStatus(
        sub: UUID,
        status: ExamStatus,
        examParticipationId: UUID
    ): Observable<{
        sub: UUID;
        status: ExamStatus;
        examParticipationId: UUID;
    }> {
        return this.http
            .patch<ExamParticipation>(
                `${this.API_HOST}/exam-participation/${examParticipationId}`,
                {
                    status,
                }
            )
            .pipe(
                map(response => ({
                    sub,
                    status: response.status,
                    examParticipationId: response.id,
                }))
            );
    }

    patchCourseParticipationIsBlocked(
        sub: UUID,
        courseParticipationId: UUID,
        isBlocked: boolean
    ): Observable<{
        sub: UUID;
        courseParticipationId: UUID;
        isBlocked: boolean;
    }> {
        return this.http
            .patch<CourseParticipation>(
                `${this.API_HOST}/course-participation/${courseParticipationId}`,
                {
                    isBlocked,
                }
            )
            .pipe(
                map(response => ({
                    sub,
                    courseParticipationId: response.id,
                    isBlocked: response.isBlocked,
                }))
            );
    }

    postTimeExpenditure(
        sub: UUID,
        timeExpenditure: TimeExpenditure
    ): Observable<{ sub: UUID; timeExpenditure: TimeExpenditure }> {
        return this.http
            .post<TimeExpenditure>(`${this.API_HOST}/time-expenditure`, {
                ...timeExpenditure,
            })
            .pipe(
                map(response => ({
                    sub,
                    timeExpenditure: response,
                }))
            );
    }

    postCourseParticipationFreeExtension(
        sub: UUID,
        courseParticipationId: UUID,
        extensionMonthsNumber: number
    ): Observable<{
        sub: UUID;
        courseParticipationExtension: CourseParticipationExtension;
        newValidUntil: string;
    }> {
        return this.http
            .post<CourseParticipationExtension & { newValidUntil: string }>(
                `${this.API_HOST}/course-participation/free-extension`,
                {
                    courseParticipationId,
                    extensionMonthsNumber,
                }
            )
            .pipe(
                map(courseParticipationExtension => ({
                    sub,
                    courseParticipationExtension,
                    newValidUntil: courseParticipationExtension.newValidUntil,
                }))
            );
    }

    postPaymentRequest(
        sub: UUID,
        id: UUID,
        bookingType: BookingType
    ): Observable<{ sub: UUID; id: UUID; bookingType: BookingType }> {
        throw new Error('Not implemented');
        // TODO implementation of send payment request mail
    }

    patchIsPaid(
        sub: UUID,
        id: UUID,
        bookingType: BookingType,
        isPaid: boolean
    ): Observable<{
        sub: UUID;
        id: UUID;
        bookingType: BookingType;
        isPaid: boolean;
    }> {
        switch (bookingType) {
            case BookingType.COURSE:
                return this.http
                    .patch<CourseParticipation>(
                        `${this.API_HOST}/course-participation/${id}`,
                        {
                            isPaid,
                        }
                    )
                    .pipe(
                        map(courseParticipation => ({
                            sub,
                            bookingType,
                            isPaid: courseParticipation.isPaid,
                            id: courseParticipation.id,
                        }))
                    );
            case BookingType.EXTENSION:
                return this.http
                    .patch<CourseParticipationExtension>(
                        `${this.API_HOST}/course-participation/extension/${id}`,
                        {
                            isPaid,
                        }
                    )
                    .pipe(
                        map(courseParticipationExtension => ({
                            sub,
                            bookingType,
                            isPaid: courseParticipationExtension.isPaid,
                            id: courseParticipationExtension.id,
                        }))
                    );
            case BookingType.EXAM:
                return this.http
                    .patch<ExamParticipation>(
                        `${this.API_HOST}/exam-participation/${id}`,
                        {
                            isPaid,
                        }
                    )
                    .pipe(
                        map(examParticipation => ({
                            sub,
                            bookingType,
                            isPaid: examParticipation.isPaid,
                            id: examParticipation.id,
                        }))
                    );
            default:
                throw new Error('Unknown booking type: ' + bookingType);
        }
    }

    downloadCertificate(
        sub: UUID,
        courseParticipationId: UUID,
        customerListData: CustomerListData,
        courseParticipation: CustomerDetailsCourseParticipation,
        examParticipation: CustomerDetailsExamParticipation
    ): Observable<{ sub: UUID; courseParticipationId: UUID }> {
        return forkJoin([
            forkJoin(
                courseParticipation.moduleProgress.map(mP =>
                    this.http
                        .get<LearningModuleDocumentInfo[]>(
                            `${this.API_HOST}/module-document-info`,
                            {
                                params: {
                                    moduleId: mP.moduleId,
                                    courseParticipationId,
                                    // bibliographies: 'true',
                                },
                            }
                        )
                        .pipe(map(lm => lm[0]))
                )
            ),
            this.http.get<TimeExpenditure[]>(
                `${this.API_HOST}/time-expenditure`,
                {
                    params: {
                        filter: `courseParticipationId||$eq||${courseParticipationId}`,
                    },
                }
            ),
        ]).pipe(
            mergeMap(async ([learningModules, timeExpenditures]) => {
                await this.docxCreatorService.createEducationCourseCertificate(
                    customerListData,
                    courseParticipation,
                    examParticipation.exam,
                    learningModules,
                    timeExpenditures
                );
                return {
                    sub,
                    courseParticipationId,
                };
            })
        );
    }

    deleteExamParticipation(
        sub: UUID,
        examParticipationId: UUID
    ): Observable<{ sub: UUID; examParticipationId: UUID }> {
        return this.http
            .delete<void>(
                `${this.API_HOST}/exam-participation/${examParticipationId}`
            )
            .pipe(
                map(() => ({
                    sub,
                    examParticipationId,
                }))
            );
    }

    getTimeExpenditure(
        courseParticipationIds: UUID[]
    ): Observable<TimeExpenditure[]> {
        return this.http.get<TimeExpenditure[]>(
            `${this.API_HOST}/time-expenditure`,
            {
                params: {
                    select: 'courseParticipationId,duration,lastRead',
                    filter: `courseParticipationId||$in||${courseParticipationIds.join(
                        ','
                    )}`,
                    sort: 'courseParticipationId,ASC',
                },
            }
        );
    }

    postCourseParticipationInvoiceCorrection(
        invoiceId: UUID,
        totalAmount: number
    ): Observable<
        CustomerDetailsCourseParticipationInvoice['corrections'][number]
    > {
        return this.http.post<
            CustomerDetailsCourseParticipationInvoice['corrections'][number]
        >(
            `${this.API_HOST}/invoices/course-participation/${invoiceId}/correction`,
            {
                totalAmount,
            }
        );
    }

    /**
     * Decodes base64 content and generates a Blob from it.
     * The processing is done in chunks of 512bytes because of performance optimization.
     *
     * @see https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     * @param base64Content
     * @param contentType
     * @private
     */
    private base64ToBlob(base64Content: string, contentType: string): Blob {
        const byteCharacters = atob(base64Content);
        const byteArrays = [];

        const sliceSize = 512;
        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            byteArrays.push(new Uint8Array(byteNumbers));
        }

        return new Blob(byteArrays, { type: contentType });
    }
}
