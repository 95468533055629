import { TransactionType } from './transaction-type.enum';
import { isTransaction, Transaction } from './transaction';
import type { UUID } from '../../../../../../../../../shared/generic-types/src';
import { BookingType } from './booking-type.enum';

export class Payment extends Transaction {
    bookingType: BookingType;
}

export function isPayment(payment: unknown): payment is Payment {
    return (
        isTransaction(payment) &&
        payment.transactionType === TransactionType.PAYMENT
    );
}
