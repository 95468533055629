import { Injectable, OnDestroy } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import {
    debounceTime,
    distinctUntilChanged,
    map,
    takeUntil,
} from 'rxjs/operators';
import {
    CustomerListActions,
    CustomerListSelectors,
} from '../+state/customer-list';
import type { UUID } from '../../../../../../../../shared/generic-types/src';
import {
    BalanceStatus,
    CourseStatus,
    CourseType,
    CustomerListData,
} from '../../../../../../shared/interfaces/src';
import { ExamStatus } from '../../../../../../shared/interfaces/src/lib/data-management/exam/exam-participation';
import {
    CustomerListFilter,
    DateFilter,
    Filter,
    FilterFields,
    MultiSelectFilter,
    SelectFilter,
    StringFilter,
} from '../model';
import {
    BalanceStatusPipe,
    CourseStatusEnumPipe,
    CourseStatusPipe,
    CourseTypePipe,
    ExamStatePipe,
} from '../pipes';
import { flatten } from '../utils';

@Injectable()
export class FilterService implements OnDestroy {
    private _destroying$ = new Subject<void>();

    readonly filter = new Map<FilterFields, Filter<unknown, unknown>>([
        [FilterFields.displayName, new StringFilter()],
        [
            FilterFields.balance,
            new MultiSelectFilter<BalanceStatus>(
                Object.values(BalanceStatus).map(status => ({
                    label: this.balanceStatusPipe.transform(status),
                    value: status,
                }))
            ),
        ],
        [
            FilterFields.legalAreaCode,
            new MultiSelectFilter<UUID | false>(
                this.store
                    .select(CustomerListSelectors.GET_LEGAL_AREAS_FILTER_VALUES)
                    .pipe(
                        takeUntil(this._destroying$),
                        map(options => [
                            <{ label: string; value: false }>{
                                label: '-',
                                value: false,
                            },
                            ...options
                                .map(({ id, code }) => ({
                                    label: code,
                                    value: id,
                                }))
                                .sort((la1, la2) =>
                                    la1.label.localeCompare(la2.label)
                                ),
                        ])
                    )
            ),
        ],
        [
            FilterFields.products,
            new MultiSelectFilter<
                CourseType | 'TEST' | 'EXTENSION' | 'EXAM' | false
            >([
                {
                    label: '-',
                    value: false,
                },
                ...Object.values(CourseType).map(status => ({
                    label: this.courseTypePipe.transform(status, false),
                    value: status,
                })),
                {
                    label: 'Testzugang',
                    value: 'TEST',
                },
                {
                    label: 'Verlängerung',
                    value: 'EXTENSION',
                },
                {
                    label: 'Prüfung',
                    value: 'EXAM',
                },
            ]),
        ],
        [FilterFields.bookingDateFrom, new DateFilter()],
        [FilterFields.bookingDateTo, new DateFilter()],
        [
            FilterFields.invoice,
            new StringFilter([
                Validators.pattern(
                    /(^FA-P-[0-9]+$)|(^FA-V-[0-9]+$)|(^FA-[0-9]+$)|(^FA-[0-9]+-K$)|(^FF-[0-9]+$)|(^FF-[0-9]+-K$)/i
                ),
            ]),
        ],
        [
            FilterFields.status,
            new MultiSelectFilter<CourseStatus | false>([
                {
                    label: '-',
                    value: false,
                },
                ...Object.values(CourseStatus).map(status => ({
                    label: this.courseStatusEnumPipe.transform(status),
                    value: status,
                })),
            ]),
        ],
        [
            FilterFields.examStart,
            new MultiSelectFilter<string>(
                Array.from(
                    { length: new Date().getFullYear() - 2023 + 1 },
                    (_, index) => ({
                        value: 2023 + index + '',
                        label: 2023 + index + '',
                    })
                )
            ),
        ],
        [
            FilterFields.examLocation,
            new MultiSelectFilter<string | false>(
                this.store
                    .select(CustomerListSelectors.GET_EXAM_FILTER_VALUES)
                    .pipe(
                        takeUntil(this._destroying$),
                        map(options => [
                            ...options.locations
                                ?.map(name => ({
                                    label: name,
                                    value: name,
                                }))
                                .sort((l1, l2) =>
                                    l1.label.localeCompare(l2.label)
                                ),
                        ])
                    )
            ),
        ],
        [
            FilterFields.examStatus,
            new MultiSelectFilter<ExamStatus | false>([
                {
                    label: '-',
                    value: false,
                },
                ...Object.values(ExamStatus).map(status => ({
                    label: this.examStatusPipe.transform(status),
                    value: status,
                })),
            ]),
        ],
        [
            FilterFields.annotation,
            new SelectFilter<boolean>([
                { label: 'ja', value: true },
                { label: 'nein', value: false },
            ]),
        ],
    ]);

    form: FormGroup;

    constructor(
        private store: Store,
        private balanceStatusPipe: BalanceStatusPipe,
        private courseTypePipe: CourseTypePipe,
        private courseStatusEnumPipe: CourseStatusEnumPipe,
        private examStatusPipe: ExamStatePipe,
        private courseStatusPipe: CourseStatusPipe
    ) {
        const formControls = {};
        const filterObservables = new Map<FilterFields, Observable<unknown>>();
        for (const [filterName, filter] of this.filter) {
            formControls[filterName] = filter.getFormControl();
            filterObservables.set(filterName, filter.getObservableValue());
        }
        this.form = new FormGroup(formControls);

        flatten(filterObservables)
            .pipe(
                takeUntil(this._destroying$),
                debounceTime(500),
                //distinctUntilChanged(),
                map(v => this.mapFilterValues(v as Map<FilterFields, unknown>))
            )
            .subscribe(filter =>
                this.store.dispatch(CustomerListActions.SET_FILTER({ filter }))
            );
    }

    ngOnDestroy() {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    async reset(field: FilterFields): Promise<void> {
        return this.filter.get(field).reset();
    }

    async resetAll(): Promise<void[]> {
        const promises = [];
        for (const [, filter] of this.filter) {
            promises.push(filter.reset());
        }
        return Promise.all<void>(promises);
    }

    private mapFilterValues(
        values: Map<FilterFields, unknown>
    ): CustomerListFilter {
        const displayName = <string | undefined>(
                values.get(FilterFields.displayName)
            ),
            balanceStatus = <BalanceStatus[] | undefined>(
                values.get(FilterFields.balance)
            ),
            legalAreas = <(UUID | false)[] | undefined>(
                values.get(FilterFields.legalAreaCode)
            ),
            products = <(CourseType | false)[] | undefined>(
                values.get(FilterFields.products)
            ),
            bookingFrom = <Date | undefined>(
                values.get(FilterFields.bookingDateFrom)
            ),
            bookingTo = <Date | undefined>(
                values.get(FilterFields.bookingDateTo)
            ),
            invoice = <string | undefined>values.get(FilterFields.invoice),
            status = <(CourseStatus | false)[] | undefined>(
                values.get(FilterFields.status)
            ),
            examStart = <string[] | undefined>(
                values.get(FilterFields.examStart)
            ),
            examLocations = <(string | false)[] | undefined>(
                values.get(FilterFields.examLocation)
            ),
            examStatus = <(ExamStatus | false)[] | undefined>(
                values.get(FilterFields.examStatus)
            ),
            annotation = <boolean | undefined>(
                values.get(FilterFields.annotation)
            );
        return {
            displayName,
            balanceStatus,
            legalAreas,
            products,
            booking:
                bookingFrom || bookingTo
                    ? {
                          from: bookingFrom ?? new Date(0),
                          to: new Date(
                              (bookingTo ?? new Date()).setHours(23, 59, 59)
                          ),
                      }
                    : undefined,
            invoice: this.createInvoiceFilter(invoice),
            status,
            exam:
                examStart || examLocations || examStatus
                    ? {
                          start: examStart,
                          locations: examLocations,
                          status: examStatus,
                      }
                    : undefined,
            annotation,
        };
    }

    private createInvoiceFilter(
        invoiceNumber?: string
    ): CustomerListFilter['invoice'] {
        if (!invoiceNumber) {
            return undefined;
        }
        const isCourseParticipation = new RegExp(/^(FA|FF)-([0-9]+)$/i).exec(
            invoiceNumber
        );
        const isCourseParticipationCorrection = new RegExp(
            /^(FA|FF)-([0-9]+)-K$/i
        ).exec(invoiceNumber);
        const isExamParticipation = new RegExp(/^FA-P-([0-9]+)$/i).exec(
            invoiceNumber
        );
        const isExtension = new RegExp(/^FA-V-([0-9]+)$/i).exec(invoiceNumber);
        switch (true) {
            case isCourseParticipation !== null:
                return {
                    type: 'COURSE_PARTICIPATION',
                    number: parseInt(isCourseParticipation[2], 10),
                };
            case isCourseParticipationCorrection !== null:
                return {
                    type: 'COURSE_PARTICIPATION_CORRECTION',
                    number: parseInt(isCourseParticipationCorrection[2], 10),
                };
            case isExamParticipation !== null:
                return {
                    type: 'EXAM_PARTICIPATION',
                    number: parseInt(isExamParticipation[1], 10),
                };
            case isExtension !== null:
                return {
                    type: 'COURSE_PARTICIPATION_EXTENSION',
                    number: parseInt(isExtension[1], 10),
                };
            default:
                return undefined;
        }
    }

    applyFiltersLocal(
        customers: CustomerListData[],
        currentFilter: CustomerListFilter
    ): CustomerListData[] {
        return customers.map(customer => ({
            ...customer,
            courses: customer.courses.map(course => {
                let isVisible = true;
                if (
                    isVisible &&
                    currentFilter.legalAreas &&
                    currentFilter.legalAreas.length
                ) {
                    isVisible = currentFilter.legalAreas.includes(
                        course.legalArea.id
                    );
                }
                if (
                    isVisible &&
                    currentFilter.products &&
                    currentFilter.products.length
                ) {
                    isVisible =
                        (currentFilter.products.includes(course.product) &&
                            !course.isTest) ||
                        (currentFilter.products.includes('TEST') &&
                            course.isTest);
                }
                if (isVisible && currentFilter.booking) {
                    isVisible =
                        new Date(currentFilter.booking.from).getTime() <=
                            new Date(course.bookingDate).getTime() &&
                        new Date(currentFilter.booking.to).getTime() >=
                            new Date(course.bookingDate).getTime();
                }
                if (isVisible && currentFilter.invoice) {
                    isVisible =
                        (currentFilter.invoice?.type ===
                            'COURSE_PARTICIPATION' ||
                            currentFilter.invoice?.type ===
                                'COURSE_PARTICIPATION_CORRECTION') &&
                        (currentFilter.invoice?.number ===
                            course.invoice?.number ||
                            currentFilter.invoice?.number ===
                                course.invoice?.corrections[0]?.number);
                }
                if (
                    isVisible &&
                    currentFilter.status &&
                    currentFilter.status.length
                ) {
                    const status = this.courseStatusPipe.transform(
                        course.status,
                        course.product,
                        course.completed
                    );
                    isVisible = currentFilter.status.includes(status);
                }
                if (isVisible && currentFilter.exam) {
                    isVisible = false;
                }

                return {
                    ...course,
                    extensions: course.extensions?.map(extension => {
                        let isVisible = true;

                        if (
                            isVisible &&
                            currentFilter.legalAreas &&
                            currentFilter.legalAreas.length
                        ) {
                            isVisible = currentFilter.legalAreas.includes(
                                course.legalArea.id
                            );
                        }

                        if (
                            isVisible &&
                            currentFilter.products &&
                            currentFilter.products.length
                        ) {
                            isVisible =
                                currentFilter.products.includes('EXTENSION');
                        }

                        if (isVisible && currentFilter.booking) {
                            isVisible =
                                new Date(
                                    currentFilter.booking.from
                                ).getTime() <=
                                    new Date(extension.bookingDate).getTime() &&
                                new Date(currentFilter.booking.to).getTime() >=
                                    new Date(extension.bookingDate).getTime();
                        }

                        if (isVisible && currentFilter.invoice) {
                            isVisible =
                                currentFilter.invoice.type ===
                                    'COURSE_PARTICIPATION_EXTENSION' &&
                                currentFilter.invoice.number ===
                                    extension.invoice?.number;
                        }

                        if (
                            (isVisible && currentFilter.status) ||
                            currentFilter.exam
                        ) {
                            isVisible = false;
                        }

                        return {
                            ...extension,
                            isVisible,
                        };
                    }),
                    exams: course.exams?.map(exam => {
                        let isVisible = true;

                        if (
                            isVisible &&
                            currentFilter.legalAreas &&
                            currentFilter.legalAreas.length
                        ) {
                            isVisible = currentFilter.legalAreas.includes(
                                course.legalArea.id
                            );
                        }

                        if (
                            isVisible &&
                            currentFilter.products &&
                            currentFilter.products.length
                        ) {
                            isVisible = currentFilter.products.includes('EXAM');
                        }

                        if (isVisible && currentFilter.booking) {
                            isVisible =
                                new Date(
                                    currentFilter.booking.from
                                ).getTime() <=
                                    new Date(exam.bookingDate).getTime() &&
                                new Date(currentFilter.booking.to).getTime() >=
                                    new Date(exam.bookingDate).getTime();
                        }

                        if (isVisible && currentFilter.invoice) {
                            isVisible =
                                currentFilter.invoice.type ===
                                    'EXAM_PARTICIPATION' &&
                                currentFilter.invoice.number ===
                                    exam.invoice?.number;
                        }

                        if (
                            isVisible &&
                            currentFilter.exam &&
                            currentFilter.exam.start
                        ) {
                            isVisible = currentFilter.exam.start.includes(
                                exam.start.substr(0, 4)
                            );
                        }

                        if (
                            isVisible &&
                            currentFilter.exam &&
                            currentFilter.exam.locations
                        ) {
                            isVisible = currentFilter.exam.locations.includes(
                                exam.location
                            );
                        }

                        if (
                            isVisible &&
                            currentFilter.exam &&
                            currentFilter.exam.status
                        ) {
                            isVisible = currentFilter.exam.status.includes(
                                exam.status
                            );
                        }

                        if (isVisible && currentFilter.status) {
                            isVisible = false;
                        }

                        return {
                            ...exam,
                            isVisible,
                        };
                    }),
                    isVisible,
                };
            }),
        }));
    }
}
