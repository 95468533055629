import { Route } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { StartPageComponent } from './start-page/start-page.component';

export const backofficeRoutes: Route[] = [
    {
        path: 'exam',
        canActivate: [MsalGuard],
        loadChildren: () =>
            import(
                '@cna/projects/ak-jura/angular/backoffice/exam-management'
            ).then(module => module.ExamManagementModule),
    },
    {
        path: 'customer',
        canActivate: [MsalGuard],
        loadChildren: () =>
            import(
                '@cna/projects/ak-jura/angular/backoffice/customer-management'
            ).then(module => module.CustomerManagementModule),
    },
    {
        path: 'legalarea',
        canActivate: [MsalGuard],
        loadChildren: () =>
            import(
                '@cna/projects/ak-jura/angular/backoffice/legal-area-management'
            ).then(module => module.LegalAreaManagementModule),
    },
    {
        path: 'course',
        canActivate: [MsalGuard],
        loadChildren: () =>
            import(
                '@cna/projects/ak-jura/angular/backoffice/course-management'
            ).then(module => module.CourseManagementModule),
    },
    {
        path: 'mailtemplate',
        canActivate: [MsalGuard],
        loadChildren: () =>
            import(
                '@cna/projects/ak-jura/angular/backoffice/mail-template-management'
            ).then(module => module.MailTemplateManagementModule),
    },
    {
        path: 'discountcode',
        canActivate: [MsalGuard],
        loadChildren: () =>
            import(
                '@cna/projects/ak-jura/angular/backoffice/discount-code-management'
            ).then(module => module.DiscountCodeManagementModule),
    },
    {
        path: '',
        component: StartPageComponent,
        canActivate: [MsalGuard],
    },
];
