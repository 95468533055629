import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { OverlayPanel } from 'primeng/overlaypanel';
import type { UUID } from '../../../../../../../../../shared/generic-types/src';
import {
    CourseType,
    CustomerDetailsCourse,
    CustomerDetailsCourseParticipation,
    CustomerDetailsCourseParticipationExtension,
    CustomerDetailsCourseParticipationInvoice,
    CustomerDetailsModuleProgress,
    CustomerListDataCourse,
    PriceStructure,
} from '../../../../../../../shared/interfaces/src';
import {
    CompleteCourseParticipationEvent,
    DownloadCertificateEvent,
    BlockCourseParticipationEvent,
    BookCourseParticipationExtensionEvent,
} from '../../model';
import { cloneDeepWith } from 'lodash';
import { AddReadingTimeEvent } from '../../model/events/add-reading-time.event';

@Component({
    selector: 'cna-customer-bookings',
    templateUrl: './customer-bookings.component.html',
    styleUrls: ['./customer-bookings.component.scss'],
})
export class CustomerBookingsComponent implements OnInit, OnChanges {
    @Input() bookings: {
        listData: CustomerListDataCourse;
        details: CustomerDetailsCourseParticipation;
    }[];

    @Output() updateCompleted =
        new EventEmitter<CompleteCourseParticipationEvent>();
    @Output() downloadCertificate =
        new EventEmitter<DownloadCertificateEvent>();
    @Output() addReadingTime = new EventEmitter<AddReadingTimeEvent>();
    @Output() updateBlockParticipation =
        new EventEmitter<BlockCourseParticipationEvent>();
    @Output() bookExtension =
        new EventEmitter<BookCourseParticipationExtensionEvent>();

    @ViewChild('addReadingTimeOverlay') addReadingTimeOverlay: OverlayPanel;

    CourseType = CourseType;

    localBookings: {
        listData: CustomerListDataCourse;
        details: CustomerDetailsCourseParticipation;
    }[];

    bookExtensionOptions: { label: string; value: number }[];

    bookExtensionDialogVisible = false;
    addReadingTimeDialogVisible = false;

    addReadingTimeForm = new FormGroup({
        courseParticipationId: new FormControl(),
        moduleId: new FormControl(),
        sectionId: new FormControl(),
        date: new FormControl(),
        time: new FormControl(),
    });

    bookExtensionForm = new FormGroup({
        duration: new FormControl(),
        courseParticipationId: new FormControl(),
    });

    ngOnInit() {
        this.bookExtensionOptions = [
            {
                label: '1 Monat',
                value: 1,
            },
        ];
        for (let value = 2; value <= 12; value++) {
            this.bookExtensionOptions.push({
                label: `${value} Monate`,
                value,
            });
        }
    }

    ngOnChanges() {
        this.localBookings = this.mapModules(cloneDeepWith(this.bookings));
    }

    private mapModules(
        bookings: {
            listData: CustomerListDataCourse;
            details: CustomerDetailsCourseParticipation;
        }[]
    ): {
        listData: CustomerListDataCourse;
        details: {
            id: UUID;
            createdAt: string;
            updatedAt: string;
            validUntil: string;
            isTest: boolean;
            isPaid: boolean;
            isBlocked: boolean;
            instalment: boolean;
            expirationMailReminderMailSent?: string;
            completed: boolean;
            lastLogin: string;
            invoice: CustomerDetailsCourseParticipationInvoice;
            moduleProgress: (CustomerDetailsModuleProgress & {
                controlTestPassed: boolean;
            })[];
            extensions: CustomerDetailsCourseParticipationExtension[];
            bookedCourse: CustomerDetailsCourse;
            priceStructure: PriceStructure;
        };
    }[] {
        return bookings
            .map(booking => ({
                ...booking,
                details: {
                    ...booking.details,
                    moduleProgress: [
                        ...booking.details.moduleProgress.map(mProgress => ({
                            ...mProgress,
                            controlTestPassed: booking.listData.status.find(
                                status => status.id === mProgress.moduleId
                            ).controlTestPassed,
                        })),
                    ].sort(
                        (module1, module2) =>
                            module1.moduleNumber - module2.moduleNumber
                    ),
                },
            }))
            .sort(
                (booking1, booking2) =>
                    new Date(booking2.listData.bookingDate).getTime() -
                    new Date(booking1.listData.bookingDate).getTime()
            );
    }

    openAddReadingTimeDialog(
        courseParticipationId: UUID,
        moduleId: UUID,
        sectionId: UUID
    ): void {
        const defaultTime = new Date('1970-01-01');
        defaultTime.setHours(0, 0, 0, 0);
        this.addReadingTimeForm.patchValue({
            courseParticipationId,
            moduleId,
            sectionId,
            date: new Date(),
            time: defaultTime,
        });
        this.addReadingTimeDialogVisible = true;
    }

    saveReadingTime(event: Event): void {
        const formData = this.addReadingTimeForm.getRawValue();
        const time = formData.time as Date;
        const duration = time.getHours() * 3600 + time.getMinutes() * 60;
        this.addReadingTime.emit({
            timeExpenditure: {
                courseParticipationId: formData.courseParticipationId,
                moduleId: formData.moduleId,
                sectionId: formData.sectionId,
                lastRead: (formData.date as Date).toISOString(),
                duration,
            },
            originalEvent: event,
        });
        this.addReadingTimeDialogVisible = false;
    }

    async emitUpdateCompleted(
        courseParticipationId: UUID,
        { checked, originalEvent }: { originalEvent: Event; checked: boolean }
    ): Promise<void> {
        this.updateCompleted.emit({
            courseParticipationId,
            completed: checked,
            originalEvent,
        });
    }

    async emitDownloadCertificate(
        courseParticipationId: UUID,
        originalEvent: Event
    ): Promise<void> {
        this.downloadCertificate.emit({
            courseParticipationId,
            originalEvent,
        });
    }

    async emitBlockParticipation(
        courseParticipationId: UUID,
        { checked, originalEvent }: { originalEvent: Event; checked: boolean }
    ): Promise<void> {
        this.updateBlockParticipation.emit({
            courseParticipationId,
            originalEvent,
            isBlocked: checked,
        });
    }

    async openBookExtensionDialog(courseParticipationId: UUID): Promise<void> {
        this.bookExtensionForm.patchValue({
            duration: 1,
            courseParticipationId,
        });
        this.bookExtensionDialogVisible = true;
    }

    async saveBookExtension(event: Event): Promise<void> {
        const { courseParticipationId, duration } =
            this.bookExtensionForm.getRawValue();
        this.bookExtension.emit({
            courseParticipationId,
            duration,
            originalEvent: event,
        });
        this.bookExtensionDialogVisible = false;
    }
}
