import { createReducer, on } from '@ngrx/store';
import * as fromGlobal from './actions';
import type { UUID } from '@cna/shared/generic-types';
import { Book, Examinable } from '@cna/projects/ak-jura/shared/interfaces';

export const GLOBAL_STORE_NAME = 'global';

export interface GlobalState {
    loading: boolean;
    examinablesByModuleId: { moduleId: UUID; examinables: Examinable[] }[];
    books: Book[];
    alreadyBookedNotice: boolean;
    openedLegalAreaTabs: number[];
}

const _globalReducer = createReducer<GlobalState>(
    {
        loading: false,
        examinablesByModuleId: [],
        books: [],
        alreadyBookedNotice: false,
        openedLegalAreaTabs: [],
    },
    on(fromGlobal.insertBulkExaminables, state => ({
        ...state,
        loading: true,
    })),
    on(fromGlobal.insertBulkExaminablesSuccess, (state, examinables) => ({
        ...state,
        loading: false,
    })),
    on(fromGlobal.insertBulkExaminablesFailure, state => ({
        ...state,
        loading: false,
    })),

    on(fromGlobal.getExaminablesByModuleId, state => ({
        ...state,
        loading: true,
    })),
    on(fromGlobal.getExaminablesByModuleIdSuccess, (state, action) => {
        if (action.examinables.length > 0) {
            const newObj = {
                moduleId: action.examinables[0].moduleId,
                examinables: action.examinables,
            };

            return {
                ...state,
                loading: false,
                examinablesByModuleId: state.examinablesByModuleId.concat([
                    newObj,
                ]),
            };
        }
        return { ...state, loading: false };
    }),
    on(fromGlobal.getExaminablesByModuleIdFailure, state => ({
        ...state,
        loading: false,
    })),

    on(fromGlobal.getBookById, state => ({
        ...state,
        loading: true,
    })),
    on(fromGlobal.getBookByIdSuccess, (state, action) => {
        if (action.book) {
            state.books.push(action.book);
        }
        return {
            ...state,
            loading: false,
        };
    }),
    on(fromGlobal.getBookByIdFailure, state => ({
        ...state,
        loading: false,
    })),

    on(fromGlobal.setNoticeAlreadyBooked, (state, action) => ({
        ...state,
        alreadyBookedNotice: action.value,
    })),
    on(fromGlobal.addOpenedLegalAreaAccordionId, (state, action) => {
        return {
            ...state,
            openedLegalAreaTabs: state.openedLegalAreaTabs.concat([
                action.accordionId,
            ]),
        };
    }),
    on(fromGlobal.removeOpenedLegalAreaAccordionId, (state, action) => ({
        ...state,
        openedLegalAreaTabs: state.openedLegalAreaTabs.filter(
            t => t !== action.accordionId
        ),
    })),
    on(fromGlobal.resetOpenedLegalAreaAccordionId, state => ({
        ...state,
        openedLegalAreaTabs: [],
    }))
);

export function globalReducer(state, action) {
    return _globalReducer(state, action);
}
