import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { combineLatest, Observable } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { CustomerActions, CustomerSelectors } from '../../+state/customer';
import { CustomerListSelectors } from '../../+state/customer-list';
import type { UUID } from '../../../../../../../../../shared/generic-types/src';
import {
    CourseType,
    CustomerDetails,
    CustomerDetailsCourseParticipation,
    CustomerDetailsCourseParticipationExtensionInvoice,
    CustomerListData,
    CustomerListDataCourse,
    Payment as ApiPayment,
} from '../../../../../../../shared/interfaces/src';
import { ToastType } from '../../../../../../../shared/interfaces/src/lib/toast-type.enum';
import { ToasterService } from '../../../../../../shared-components/src/lib/services/toaster.service';
import {
    AddPaymentEvent,
    BlockCourseParticipationEvent,
    BookCourseParticipationExtensionEvent,
    BookingType,
    ChangeAnnotationEvent,
    ChangeExamStatusEvent,
    CompleteCourseParticipationEvent,
    CorrectInvoiceEvent,
    DeleteExamParticipationEvent,
    DeletePaymentEvent,
    DownloadCertificateEvent,
    DownloadInvoiceEvent,
    EducationCourseBooking,
    ExamBooking,
    ExtensionBooking,
    Payment,
    TrainingCourseBooking,
    Transaction,
    TransactionType,
    TriggerPaymentRequestEvent,
    UpdateIsPaidEvent,
} from '../../model';
import { AddReadingTimeEvent } from '../../model/events/add-reading-time.event';
import { CustomerModalService } from '../../services/customer-modal.service';

@Component({
    selector: 'cna-customer-modal',
    templateUrl: './customer-modal.component.html',
    styleUrls: ['./customer-modal.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
})
export class CustomerModalComponent implements OnInit {
    customerId: UUID;
    customerSub: UUID;
    activeTab = 0;
    customer$: Observable<{
        listData: CustomerListData;
        details: CustomerDetails;
    }>;
    bookings$: Observable<
        {
            listData: CustomerListDataCourse;
            details: CustomerDetailsCourseParticipation;
        }[]
    >;
    transactions$: Observable<Transaction[]>;
    loading$ = this.store.select(CustomerSelectors.GET_LOADING);

    constructor(
        private dialogRef: DynamicDialogRef,
        config: DynamicDialogConfig,
        private toasterService: ToasterService,
        private store: Store,
        private service: CustomerModalService
    ) {
        this.customerId = config.data.id;
        this.customerSub = config.data.sub;
        this.activeTab = config.data.activeTab ?? 0;
    }

    ngOnInit(): void {
        if (!this.customerId) {
            this.dialogRef.close();
            this.toasterService.show(
                'Der Kunde wurde nicht gefunden!',
                ToastType.error
            );
            return;
        } else {
            this.service.loadCustomer(this.customerId);
            this.customer$ = combineLatest([
                this.store
                    .select(CustomerSelectors.GET_CUSTOMER(this.customerSub))
                    .pipe(skipWhile(details => !details)),
                this.store
                    .select(CustomerListSelectors.GET_ONE(this.customerSub))
                    .pipe(skipWhile(listData => !listData)),
            ]).pipe(map(([details, listData]) => ({ details, listData })));
            this.bookings$ = this.customer$.pipe(
                map(({ details, listData }) => {
                    return details.courseParticipations.map(
                        courseParticipation => ({
                            details: courseParticipation,
                            listData: listData.courses.find(
                                course => course.id === courseParticipation.id
                            ),
                        })
                    );
                })
            );
            this.transactions$ = this.customer$.pipe(
                map(({ details }) => {
                    const transactions: Transaction[] = [];
                    details.courseParticipations.forEach(cp => {
                        // course participations
                        const courseType = cp.bookedCourse.type;
                        switch (courseType) {
                            case CourseType.EducationCourse:
                                transactions.push(<EducationCourseBooking>{
                                    id: cp.id,
                                    transactionType: TransactionType.BOOKING,
                                    bookingType: BookingType.COURSE,
                                    courseType,
                                    date: cp.createdAt,
                                    amount: -1 * (cp.invoice?.price ?? 0),
                                    legalAreaCode:
                                        cp.bookedCourse.associatedLegalArea
                                            .code,
                                    invoice: cp.invoice
                                        ? {
                                              id: cp.invoice
                                                  .courseParticipationId,
                                              number: cp.invoice.number,
                                              prefix: cp.invoice.prefix,
                                          }
                                        : undefined,
                                    invoiceCorrections:
                                        cp.invoice?.corrections?.map(
                                            correction => ({
                                                id: correction.id,
                                                number: correction.number,
                                                prefix: correction.prefix,
                                                suffix: correction.suffix,
                                            })
                                        ) ?? [],
                                    isPaid: cp.isPaid,
                                    isTest: cp.isTest,
                                    instalment: cp.instalment,
                                    courseParticipationId: cp.id,
                                });
                                break;
                            case CourseType.TrainingCourse:
                                transactions.push(<TrainingCourseBooking>{
                                    id: cp.id,
                                    transactionType: TransactionType.BOOKING,
                                    bookingType: BookingType.COURSE,
                                    courseType,
                                    date: cp.createdAt,
                                    amount: -1 * (cp.invoice?.price ?? 0),
                                    legalAreaCode:
                                        cp.bookedCourse.associatedLegalArea
                                            .code,
                                    invoice: cp.invoice
                                        ? {
                                              id: cp.invoice
                                                  .courseParticipationId,
                                              number: cp.invoice.number,
                                              prefix: cp.invoice.prefix,
                                          }
                                        : undefined,
                                    isPaid: cp.isPaid,
                                    courseParticipationId: cp.id,
                                });
                                break;
                            default:
                                throw new Error(
                                    'Unknown course type: ' +
                                        cp.bookedCourse.type
                                );
                        }

                        // extensions
                        cp.extensions?.forEach(extension =>
                            transactions.push(<ExtensionBooking>{
                                id: extension.id,
                                transactionType: TransactionType.BOOKING,
                                bookingType: BookingType.EXTENSION,
                                courseType,
                                date:
                                    extension.invoice?.createdAt ??
                                    extension.createdAt,
                                amount:
                                    -1 * (extension.invoice?.totalPrice ?? 0),
                                legalAreaCode:
                                    cp.bookedCourse.associatedLegalArea.code,
                                invoice: extension.invoice
                                    ? {
                                          id: extension.invoice.id,
                                          prefix: extension.invoice.prefix,
                                          number: extension.invoice.number,
                                      }
                                    : undefined,
                                isPaid: extension.isPaid,
                            })
                        );
                    });

                    // exams
                    details.bookedExams?.forEach(exam => {
                        if (exam.invoice) {
                            transactions.push(<ExamBooking>{
                                id: exam.id,
                                transactionType: TransactionType.BOOKING,
                                bookingType: BookingType.EXAM,
                                courseType: CourseType.EducationCourse,
                                date: exam.invoice.createdAt,
                                amount: -1 * (exam.invoice?.totalPrice ?? 0),
                                legalAreaCode: exam.legalArea.code,
                                invoice: exam.invoice
                                    ? {
                                          id: exam.invoice.id,
                                          prefix: exam.invoice.prefix,
                                          number: exam.invoice.number,
                                      }
                                    : undefined,
                                isPaid: exam.isPaid,
                            });
                        }
                    });

                    details.payments.forEach(p => {
                        const invoice = this.getPaymentInvoice(p, details);
                        transactions.push(<Payment>{
                            transactionType: TransactionType.PAYMENT,
                            date: p.paymentDate,
                            amount: p.amount,
                            id: p.id,
                            invoice: {
                                id: invoice.id,
                                prefix: invoice.prefix,
                                suffix: invoice.suffix,
                                number: invoice.number,
                                isCorrection: invoice.isCorrection,
                            },
                            bookingType: invoice.bookingType,
                        });
                    });

                    return transactions.sort(
                        (t1, t2) =>
                            new Date(t2.date).getTime() -
                            new Date(t1.date).getTime()
                    );
                })
            );
        }
    }

    private getPaymentInvoice(
        payment: ApiPayment,
        details: CustomerDetails
    ): {
        id: UUID;
        prefix: string;
        number: number;
        bookingType: BookingType;
        suffix?: string;
        isCorrection?: boolean;
    } {
        if (payment.courseParticipationInvoiceId) {
            const invoice = details.courseParticipations.find(
                cp =>
                    cp.invoice?.courseParticipationId ===
                    payment.courseParticipationInvoiceId
            ).invoice;
            return {
                id: invoice.courseParticipationId,
                prefix: invoice.prefix,
                number: invoice.number,
                bookingType: BookingType.COURSE,
            };
        } else if (payment.courseParticipationExtensionInvoiceId) {
            let invoice: CustomerDetailsCourseParticipationExtensionInvoice;
            loop: for (const cp of details.courseParticipations) {
                for (const ex of cp.extensions) {
                    if (
                        ex.invoice?.id ===
                        payment.courseParticipationExtensionInvoiceId
                    ) {
                        invoice = ex.invoice;
                        break loop;
                    }
                }
            }
            return {
                id: invoice.id,
                prefix: invoice.prefix,
                number: invoice.number,
                bookingType: BookingType.EXTENSION,
            };
        } else if (payment.examParticipationInvoiceId) {
            const invoice = details.bookedExams.find(
                exam => exam.invoice?.id === payment.examParticipationInvoiceId
            ).invoice;
            return {
                id: invoice.id,
                prefix: invoice.prefix,
                number: invoice.number,
                bookingType: BookingType.EXAM,
            };
        } else if (payment.courseParticipationInvoiceCorrectionId) {
            const invoice = details.courseParticipations?.find(
                cp =>
                    cp.invoice?.corrections[0]?.id ===
                    payment.courseParticipationInvoiceCorrectionId
            )?.invoice?.corrections[0];
            return {
                id: invoice.id,
                prefix: invoice.prefix,
                suffix: invoice.suffix,
                number: invoice.number,
                bookingType: BookingType.COURSE,
                isCorrection: true,
            };
        } else {
            return undefined;
        }
    }

    async onCompleteParticipation({
        courseParticipationId,
        completed,
    }: CompleteCourseParticipationEvent): Promise<void> {
        this.store.dispatch(
            CustomerActions.UPDATE_COMPLETED({
                sub: this.customerSub,
                id: courseParticipationId,
                completed,
            })
        );
    }

    async onDownloadCertificate(
        event: DownloadCertificateEvent
    ): Promise<void> {
        this.store.dispatch(
            CustomerActions.DOWNLOAD_CERTIFICATE({
                sub: this.customerSub,
                courseParticipationId: event.courseParticipationId,
            })
        );
    }

    async onChangeAnnotation(event: ChangeAnnotationEvent): Promise<void> {
        this.store.dispatch(
            CustomerActions.UPDATE_ANNOTATION({
                sub: event.sub,
                id: event.id,
                annotation: event.annotation,
            })
        );
    }

    async onChangeExamStatus(event: ChangeExamStatusEvent): Promise<void> {
        this.store.dispatch(
            CustomerActions.UPDATE_EXAM_STATUS({
                examParticipationId: event.examParticipationId,
                status: event.status,
                sub: this.customerSub,
            })
        );
    }

    async onDeleteExamParticipation(
        event: DeleteExamParticipationEvent
    ): Promise<void> {
        this.store.dispatch(
            CustomerActions.DELETE_EXAM_PARTICIPATION({
                examParticipationId: event.examParticipationId,
                sub: this.customerSub,
            })
        );
    }

    onAddReadingTime(event: AddReadingTimeEvent) {
        this.store.dispatch(
            CustomerActions.ADD_READING_TIME({
                timeExpenditure: event.timeExpenditure,
                sub: this.customerSub,
            })
        );
    }

    onUpdateBlockCourseParticipation(event: BlockCourseParticipationEvent) {
        this.store.dispatch(
            CustomerActions.UPDATE_BLOCK_COURSE_PARTICIPATION({
                courseParticipationId: event.courseParticipationId,
                isBlocked: event.isBlocked,
                sub: this.customerSub,
            })
        );
    }

    onBookExtension(event: BookCourseParticipationExtensionEvent) {
        this.store.dispatch(
            CustomerActions.BOOK_COURSE_PARTICIPATION_EXTENSION({
                courseParticipationId: event.courseParticipationId,
                duration: event.duration,
                sub: this.customerSub,
            })
        );
    }

    async onAddPayment(event: AddPaymentEvent): Promise<void> {
        this.store.dispatch(
            CustomerActions.ADD_PAYMENT({
                sub: this.customerSub,
                customerId: this.customerId,
                amount: event.amount,
                invoiceId: event.invoiceId,
                date: event.date,
                bookingType: event.bookingType,
                isCorrection: event.isCorrection,
            })
        );
    }

    async onDeletePayment(event: DeletePaymentEvent): Promise<void> {
        this.store.dispatch(
            CustomerActions.DELETE_PAYMENT({
                sub: this.customerSub,
                id: event.id,
                amount: event.amount,
            })
        );
    }

    async onDownloadInvoice(event: DownloadInvoiceEvent): Promise<void> {
        this.store.dispatch(
            CustomerActions.DOWNLOAD_INVOICE({
                invoiceId: event.invoiceId,
                bookingType: event.bookingType,
                isCorrection: event.isCorrection,
            })
        );
    }

    async onUpdateIsPaid(event: UpdateIsPaidEvent): Promise<void> {
        this.store.dispatch(
            CustomerActions.UPDATE_IS_PAID({
                sub: this.customerSub,
                id: event.id,
                isPaid: event.isPaid,
                bookingType: event.bookingType,
            })
        );
    }

    async onTriggerPaymentRequest(
        event: TriggerPaymentRequestEvent
    ): Promise<void> {
        this.store.dispatch(
            CustomerActions.TRIGGER_PAYMENT_REQUEST({
                id: event.id,
                bookingType: event.bookingType,
                sub: this.customerSub,
            })
        );
    }

    async onCorrectInvoice(event: CorrectInvoiceEvent): Promise<void> {
        this.store.dispatch(
            CustomerActions.CORRECT_INVOICE({
                invoiceId: event.invoiceId,
                bookingType: event.bookingType,
                totalAmount: event.totalAmount,
                sub: this.customerSub,
            })
        );
    }
}
