import { Pipe, PipeTransform } from '@angular/core';
import { CourseStatus } from '../../../../../../shared/interfaces/src';

@Pipe({ name: 'courseStatusEnum' })
export class CourseStatusEnumPipe implements PipeTransform {
    transform(status: CourseStatus): string {
        switch (status) {
            case CourseStatus.EDUCATION_MODULE_1:
                return 'Modul I';
            case CourseStatus.EDUCATION_MODULE_2:
                return 'Modul II';
            case CourseStatus.EDUCATION_MODULE_3:
                return 'Modul III';
            case CourseStatus.EDUCATION_MODULE_4:
                return 'Modul IV';
            case CourseStatus.EDUCATION_REPETITORIUM:
                return 'Repetitorium';
            case CourseStatus.EDUCATION_DONE:
                return 'Abgeschlossen';
            case CourseStatus.TRAINING_IN_PROGRESS:
                return 'Fortbildung in Arbeit';
            case CourseStatus.TRAINING_DONE:
                return 'Fortbildung Beendet';
            default:
                return 'Kein Status';
        }
    }
}
