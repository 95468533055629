import { createReducer, on } from '@ngrx/store';
import {
    LOAD_CUSTOMER,
    LOAD_CUSTOMER_SUCCESS,
    LOAD_CUSTOMER_FAILURE,
    UPDATE_COMPLETED,
    UPDATE_COMPLETED_SUCCESS,
    UPDATE_COMPLETED_FAILURE,
    UPDATE_ANNOTATION,
    UPDATE_ANNOTATION_SUCCESS,
    ADD_PAYMENT,
    ADD_PAYMENT_SUCCESS,
    ADD_PAYMENT_FAILURE,
    DELETE_PAYMENT,
    DELETE_PAYMENT_SUCCESS,
    DELETE_PAYMENT_FAILURE,
    UPDATE_EXAM_STATUS,
    UPDATE_EXAM_STATUS_SUCCESS,
    UPDATE_EXAM_STATUS_FAILURE,
    UPDATE_BLOCK_COURSE_PARTICIPATION,
    UPDATE_BLOCK_COURSE_PARTICIPATION_SUCCESS,
    UPDATE_BLOCK_COURSE_PARTICIPATION_FAILURE,
    ADD_READING_TIME,
    ADD_READING_TIME_SUCCESS,
    ADD_READING_TIME_FAILURE,
    BOOK_COURSE_PARTICIPATION_EXTENSION,
    BOOK_COURSE_PARTICIPATION_EXTENSION_SUCCESS,
    BOOK_COURSE_PARTICIPATION_EXTENSION_FAILURE,
    DELETE_EXAM_PARTICIPATION,
    DELETE_EXAM_PARTICIPATION_SUCCESS,
    DELETE_EXAM_PARTICIPATION_FAILURE,
    UPDATE_IS_PAID,
    UPDATE_IS_PAID_SUCCESS,
    CORRECT_INVOICE,
    CORRECT_INVOICE_SUCCESS,
    CORRECT_INVOICE_FAILURE,
} from './actions';
import { Dictionary } from '../../utils';
import { CustomerDetails } from '../../../../../../../shared/interfaces/src';
import { BookingType } from '../../model';

export const CUSTOMER_STORE_NAME = 'customer';

export interface CustomerReducer {
    data: Dictionary<CustomerDetails>;
    loading: boolean;
}

const initialState: CustomerReducer = {
    data: {},
    loading: false,
};

export const customerReducer = createReducer<CustomerReducer>(
    initialState,

    on(LOAD_CUSTOMER, state => ({
        ...state,
        loading: true,
    })),

    on(LOAD_CUSTOMER_SUCCESS, (state, { customer }) => ({
        ...state,
        data: {
            ...state.data,
            ...customer,
        },
        loading: false,
    })),

    on(LOAD_CUSTOMER_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(UPDATE_COMPLETED, state => ({
        ...state,
        loading: true,
    })),

    on(UPDATE_COMPLETED_SUCCESS, (state, { sub, id, completed }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                courseParticipations: state.data[sub].courseParticipations.map(
                    cp =>
                        cp.id === id
                            ? {
                                  ...cp,
                                  completed,
                              }
                            : cp
                ),
            },
        },
        loading: false,
    })),

    on(UPDATE_COMPLETED_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(UPDATE_ANNOTATION, state => ({
        ...state,
        loading: true,
    })),

    on(UPDATE_ANNOTATION_SUCCESS, (state, { sub, annotation }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                annotation,
            },
        },
        loading: false,
    })),

    on(UPDATE_COMPLETED_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(ADD_PAYMENT, state => ({
        ...state,
        loading: true,
    })),

    on(ADD_PAYMENT_SUCCESS, (state, { sub, payment }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                payments: [...state.data[sub].payments, payment],
            },
        },
        loading: false,
    })),

    on(ADD_PAYMENT_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(CORRECT_INVOICE, state => ({
        ...state,
        loading: true,
    })),

    on(CORRECT_INVOICE_SUCCESS, (state, { sub, invoice }) => ({
        ...state,
        data: {
            [sub]: {
                ...state.data[sub],
                courseParticipations: state.data[sub].courseParticipations.map(
                    cp => ({
                        ...cp,
                        invoice:
                            invoice?.courseParticipationInvoiceId ===
                            cp.invoice?.courseParticipationId
                                ? {
                                      ...cp.invoice,
                                      corrections: [invoice],
                                  }
                                : cp.invoice,
                    })
                ),
            },
        },
        loading: false,
    })),

    on(CORRECT_INVOICE_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(DELETE_PAYMENT, state => ({
        ...state,
        loading: true,
    })),

    on(DELETE_PAYMENT_SUCCESS, (state, { id, sub }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                payments: state.data[sub].payments.filter(
                    payment => payment.id !== id
                ),
            },
        },
        loading: false,
    })),

    on(DELETE_PAYMENT_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(UPDATE_EXAM_STATUS, state => ({
        ...state,
        loading: true,
    })),

    on(
        UPDATE_EXAM_STATUS_SUCCESS,
        (state, { sub, status, examParticipationId }) => ({
            ...state,
            data: {
                ...state.data,
                [sub]: {
                    ...state.data[sub],
                    bookedExams: state.data[sub].bookedExams.map(eP => ({
                        ...eP,
                        status:
                            eP.id === examParticipationId ? status : eP.status,
                    })),
                },
            },
            loading: false,
        })
    ),

    on(UPDATE_EXAM_STATUS_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(UPDATE_BLOCK_COURSE_PARTICIPATION, state => ({
        ...state,
        loading: true,
    })),

    on(
        UPDATE_BLOCK_COURSE_PARTICIPATION_SUCCESS,
        (state, { sub, courseParticipationId, isBlocked }) => ({
            ...state,
            data: {
                ...state.data,
                [sub]: {
                    ...state.data[sub],
                    courseParticipations: state.data[
                        sub
                    ].courseParticipations.map(cP => ({
                        ...cP,
                        isBlocked:
                            cP.id === courseParticipationId
                                ? isBlocked
                                : cP.isBlocked,
                    })),
                },
            },
            loading: false,
        })
    ),

    on(UPDATE_BLOCK_COURSE_PARTICIPATION_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(ADD_READING_TIME, state => ({
        ...state,
        loading: true,
    })),

    on(ADD_READING_TIME_SUCCESS, (state, { sub, timeExpenditure }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                courseParticipations: state.data[sub].courseParticipations.map(
                    cP => ({
                        ...cP,
                        moduleProgress:
                            cP.id === timeExpenditure.courseParticipationId
                                ? cP.moduleProgress.map(mP => ({
                                      ...mP,
                                      totalTimeRead:
                                          mP.moduleId ===
                                          timeExpenditure.moduleId
                                              ? mP.totalTimeRead +
                                                timeExpenditure.duration
                                              : mP.totalTimeRead,
                                  }))
                                : cP.moduleProgress,
                    })
                ),
            },
        },
        loading: false,
    })),

    on(ADD_READING_TIME_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(BOOK_COURSE_PARTICIPATION_EXTENSION, state => ({
        ...state,
        loading: true,
    })),

    on(
        BOOK_COURSE_PARTICIPATION_EXTENSION_SUCCESS,
        (state, { sub, courseParticipationExtension, newValidUntil }) => ({
            ...state,
            data: {
                ...state.data,
                [sub]: {
                    ...state.data[sub],
                    courseParticipations: state.data[
                        sub
                    ].courseParticipations.map(cP => ({
                        ...cP,
                        extensions:
                            cP.id ===
                            courseParticipationExtension.courseParticipationId
                                ? [
                                      ...cP.extensions,
                                      {
                                          id: courseParticipationExtension.id,
                                          courseParticipationId:
                                              courseParticipationExtension.courseParticipationId,
                                          extensionMonthsNumber:
                                              courseParticipationExtension.extensionMonthsNumber,
                                          costPerMonth:
                                              courseParticipationExtension.costPerMonth,
                                          createdAt:
                                              courseParticipationExtension.createdAt.toString(),
                                          updatedAt:
                                              courseParticipationExtension.updatedAt.toString(),
                                          isPaid: courseParticipationExtension.isPaid,
                                      },
                                  ]
                                : cP.extensions,
                        validUntil:
                            cP.id ===
                            courseParticipationExtension.courseParticipationId
                                ? newValidUntil
                                : cP.validUntil,
                    })),
                },
            },
            loading: false,
        })
    ),

    on(BOOK_COURSE_PARTICIPATION_EXTENSION_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(DELETE_EXAM_PARTICIPATION, state => ({
        ...state,
        loading: true,
    })),

    on(
        DELETE_EXAM_PARTICIPATION_SUCCESS,
        (state, { sub, examParticipationId }) => ({
            ...state,
            data: {
                ...state.data,
                [sub]: {
                    ...state.data[sub],
                    bookedExams: state.data[sub].bookedExams.filter(
                        eP => eP.id !== examParticipationId
                    ),
                },
            },
            loading: false,
        })
    ),

    on(DELETE_EXAM_PARTICIPATION_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(UPDATE_IS_PAID, state => ({
        ...state,
        loading: true,
    })),

    on(UPDATE_IS_PAID_SUCCESS, (state, { sub, id, isPaid, bookingType }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                courseParticipations:
                    bookingType === BookingType.COURSE ||
                    bookingType === BookingType.EXTENSION
                        ? state.data[sub].courseParticipations.map(cP => ({
                              ...cP,
                              isPaid:
                                  bookingType === BookingType.COURSE &&
                                  cP.id === id
                                      ? isPaid
                                      : cP.isPaid,
                              extensions:
                                  bookingType === BookingType.EXTENSION
                                      ? cP.extensions.map(ex => ({
                                            ...ex,
                                            isPaid:
                                                ex.id === id
                                                    ? isPaid
                                                    : ex.isPaid,
                                        }))
                                      : cP.extensions,
                          }))
                        : state.data[sub].courseParticipations,
                bookedExams:
                    bookingType === BookingType.EXAM
                        ? state.data[sub].bookedExams.map(eP => ({
                              ...eP,
                              isPaid: eP.id === id ? isPaid : eP.isPaid,
                          }))
                        : state.data[sub].bookedExams,
            },
        },
        loading: false,
    }))
);
