import { Pipe, PipeTransform } from '@angular/core';
import { CustomerListDataExam } from '../../../../../../shared/interfaces/src';

@Pipe({ name: 'latest' })
export class LatestPipe implements PipeTransform {
    transform(values: CustomerListDataExam[]): CustomerListDataExam {
        return values.length > 0
            ? values.reduce((latest, value) =>
                  new Date(value.start).getTime() >
                  new Date(latest.start).getTime()
                      ? value
                      : latest
              )
            : undefined;
    }
}
