import { Filter } from './filter';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export class SelectFilter<T> extends Filter<T | undefined, T> {
    private options: { label: string; value: T }[];

    constructor(
        options:
            | { label: string; value: T }[]
            | Observable<{ label: string; value: T }[]>
    ) {
        super(null, false);

        if (options instanceof Observable) {
            options
                .pipe(filter(o => !o))
                .subscribe(asyncOptions => (this.options = asyncOptions));
        } else {
            this.options = options;
        }
    }

    protected inputValueFromFilterValue(filterValue: T | undefined): T {
        if (filterValue === undefined) {
            return null;
        }
        return filterValue;
    }

    protected filterValueFromInputValue(inputValue: T): T | undefined {
        if (inputValue === null) {
            return undefined;
        }
        return inputValue;
    }
}
