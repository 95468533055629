import { createAction, props } from '@ngrx/store';
import { Book, Examinable } from '@cna/projects/ak-jura/shared/interfaces';
import type { Id, UUID } from '@cna/shared/generic-types';

// Insert Examinables Bulk
export const insertBulkExaminables = createAction(
    '[global] insertBulk examinables',
    props<{ examinables: Examinable[] }>()
);
export const insertBulkExaminablesSuccess = createAction(
    '[global] insertBulk examinables success',
    props<{ examinables: Examinable[] }>()
);
export const insertBulkExaminablesFailure = createAction(
    '[global] insertBulk examinables failure'
);

// Get Examinables By ModuleId
export const getExaminablesByModuleId = createAction(
    '[global] get examinables by moduleId',
    props<{ moduleId: Id | UUID }>()
);

export const getExaminablesByModuleIdSuccess = createAction(
    '[global] get examinables by moduleId success',
    props<{ examinables: Examinable[] }>()
);
export const getExaminablesByModuleIdFailure = createAction(
    '[global] get examinables by moduleId failure'
);

// get Book
export const getBookById = createAction(
    '[global] get book by Id',
    props<{ bookId: UUID }>()
);

export const getBookByIdSuccess = createAction(
    '[global] get book by Id success',
    props<{ book: Book }>()
);
export const getBookByIdFailure = createAction(
    '[global] get book by Id failure',
    props<{ error: any }>()
);

export const setNoticeAlreadyBooked = createAction(
    '[global] set already booked Notice',
    props<{ value: boolean }>()
);

export const addOpenedLegalAreaAccordionId = createAction(
    '[global] add opened legalArea accordion Id',
    props<{ accordionId: number }>()
);

export const removeOpenedLegalAreaAccordionId = createAction(
    '[global] remove opened legalArea accordion Id',
    props<{ accordionId: number }>()
);
export const resetOpenedLegalAreaAccordionId = createAction(
    '[global] reset opened legalArea accordion Id'
);
