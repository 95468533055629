import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dayCountdown' })
export class DayCountdownPipe implements PipeTransform {
    transform(date: Date | string | number): number {
        const targetTimestamp = new Date(date).getTime();
        const now = Date.now();

        return Math.max(
            Math.round((targetTimestamp - now) / (1000 * 60 * 60 * 24)),
            0
        );
    }
}
