import { Pipe, PipeTransform } from '@angular/core';
import { ExamStatus } from '../../../../../../shared/interfaces/src/lib/data-management/exam/exam-participation';

@Pipe({ name: 'examState' })
export class ExamStatePipe implements PipeTransform {
    transform(examStatus: ExamStatus) {
        switch (examStatus) {
            case ExamStatus.Booked:
                return 'Gebucht';
            case ExamStatus.Participated:
                return 'Teilgenommen';
            case ExamStatus.NotParticipated:
                return 'Nicht Teilgenommen';
            case ExamStatus.CertificateSent:
                return 'Zertifikat versendet';
        }
    }
}
