import { Observable } from 'rxjs';

export function flatten<T extends string, V>(
    input: Map<T, Observable<V>>
): Observable<Map<string, unknown>> {
    return new Observable<Map<string, unknown>>(subscriber => {
        const values = new Map<string, unknown>();
        for (const [key, observable] of input) {
            values.set(key, undefined);
            observable.subscribe(
                value => {
                    values.set(key, value);
                    subscriber.next(values);
                },
                subscriber.error,
                subscriber.complete
            );
        }
    });
}
