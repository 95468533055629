import { createReducer, on } from '@ngrx/store';
import { CustomerListState } from '../../model';
import {
    DESELECT_ALL,
    LOAD_FILTER_VALUES,
    LOAD_FILTER_VALUES_FAILURE,
    LOAD_FILTER_VALUES_SUCCESS,
    LOAD_LIST_DATA,
    LOAD_LIST_DATA_FAILURE,
    LOAD_LIST_DATA_SUCCESS,
    LOAD_SUBS,
    LOAD_SUBS_FAILURE,
    LOAD_SUBS_SUCCESS,
    SELECT_ALL,
    SET_FILTER,
    SET_QUERY,
    TOGGLE_SELECT,
} from './actions';
import {
    ADD_PAYMENT,
    ADD_PAYMENT_FAILURE,
    ADD_PAYMENT_SUCCESS,
    BOOK_COURSE_PARTICIPATION_EXTENSION,
    BOOK_COURSE_PARTICIPATION_EXTENSION_FAILURE,
    BOOK_COURSE_PARTICIPATION_EXTENSION_SUCCESS,
    CORRECT_INVOICE,
    CORRECT_INVOICE_FAILURE,
    CORRECT_INVOICE_SUCCESS,
    DELETE_EXAM_PARTICIPATION,
    DELETE_EXAM_PARTICIPATION_FAILURE,
    DELETE_EXAM_PARTICIPATION_SUCCESS,
    DELETE_PAYMENT,
    DELETE_PAYMENT_FAILURE,
    DELETE_PAYMENT_SUCCESS,
    UPDATE_ANNOTATION,
    UPDATE_ANNOTATION_FAILURE,
    UPDATE_ANNOTATION_SUCCESS,
    UPDATE_BLOCK_COURSE_PARTICIPATION,
    UPDATE_BLOCK_COURSE_PARTICIPATION_SUCCESS,
    UPDATE_COMPLETED,
    UPDATE_COMPLETED_FAILURE,
    UPDATE_COMPLETED_SUCCESS,
    UPDATE_EXAM_STATUS,
    UPDATE_EXAM_STATUS_FAILURE,
    UPDATE_EXAM_STATUS_SUCCESS,
} from '../customer/actions';
import * as _ from 'lodash';

export const CUSTOMER_LIST_STORE_NAME = 'customer-list';

const initialState: CustomerListState = {
    data: {},
    subs: [],
    selected: [],
    filter: undefined,
    filterValues: {
        legalAreas: [],
        exam: {
            locations: [],
        },
    },
    query: {
        first: 0,
        rows: 10,
    },
    loading: false,
};

export const CustomerListReducer = createReducer<CustomerListState>(
    initialState,

    on(SET_QUERY, (state, { query }) => ({
        ...state,
        query,
    })),

    on(SET_FILTER, (state, { filter }) => ({
        ...state,
        filter,
    })),

    on(LOAD_SUBS, state => ({
        ...state,
        loading: true,
    })),

    on(LOAD_SUBS_SUCCESS, (state, { subs }) => ({
        ...state,
        subs,
        loading: false,
    })),

    on(LOAD_SUBS_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(LOAD_LIST_DATA, state => ({
        ...state,
        loading: true,
    })),

    on(LOAD_LIST_DATA_SUCCESS, (state, { customers }) => ({
        ...state,
        data: {
            ...state.data,
            ...customers,
        },
        loading: false,
    })),

    on(LOAD_LIST_DATA_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(UPDATE_COMPLETED, state => ({
        ...state,
        loading: true,
    })),

    on(UPDATE_COMPLETED_SUCCESS, (state, { sub, id, completed }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                courses: state.data[sub].courses.map(cp =>
                    cp.id === id
                        ? {
                              ...cp,
                              completed,
                          }
                        : cp
                ),
            },
        },
        loading: false,
    })),

    on(UPDATE_ANNOTATION, state => ({
        ...state,
        loading: true,
    })),

    on(UPDATE_ANNOTATION_SUCCESS, (state, { sub, annotation }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                annotation,
            },
        },
        loading: false,
    })),

    on(UPDATE_ANNOTATION_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(UPDATE_COMPLETED_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(LOAD_FILTER_VALUES, state => ({
        ...state,
        loading: true,
    })),

    on(LOAD_FILTER_VALUES_SUCCESS, (state, { filterValues }) => ({
        ...state,
        filterValues,
        loading: false,
    })),

    on(LOAD_FILTER_VALUES_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(TOGGLE_SELECT, (state, { sub }) => ({
        ...state,
        selected: state.selected.includes(sub)
            ? state.selected.filter(s => s !== sub)
            : [...state.selected, sub],
    })),

    on(SELECT_ALL, state => ({
        ...state,
        selected: _.uniq([...state.selected, ...state.subs]),
    })),

    on(DESELECT_ALL, state => ({
        ...state,
        selected: [],
    })),

    on(ADD_PAYMENT, state => ({
        ...state,
        loading: true,
    })),

    on(ADD_PAYMENT_SUCCESS, (state, { sub, payment }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                balance: state.data[sub].balance + payment.amount,
                credit: state.data[sub].credit + payment.amount,
            },
        },
        loading: false,
    })),

    on(ADD_PAYMENT_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(CORRECT_INVOICE, state => ({
        ...state,
        loading: true,
    })),

    on(CORRECT_INVOICE_SUCCESS, (state, { sub, invoice }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                courses: state.data[sub].courses.map(cp => ({
                    ...cp,
                    invoice:
                        cp.invoice?.id === invoice?.courseParticipationInvoiceId
                            ? {
                                  ...cp.invoice,
                                  corrections: [invoice],
                              }
                            : cp.invoice,
                })),
            },
        },
        loading: false,
    })),

    on(CORRECT_INVOICE_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(DELETE_PAYMENT, state => ({
        ...state,
        loading: true,
    })),

    on(DELETE_PAYMENT_SUCCESS, (state, { sub, amount }) => ({
        ...state,
        data: {
            ...state.data,
            [sub]: {
                ...state.data[sub],
                balance: state.data[sub].balance - amount,
                credit: state.data[sub].credit - amount,
            },
        },
        loading: false,
    })),

    on(DELETE_PAYMENT_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(UPDATE_EXAM_STATUS, state => ({
        ...state,
        loading: true,
    })),

    on(
        UPDATE_EXAM_STATUS_SUCCESS,
        (state, { sub, status, examParticipationId }) => ({
            ...state,
            data: {
                ...state.data,
                [sub]: {
                    ...state.data[sub],
                    courses: state.data[sub].courses.map(course => ({
                        ...course,
                        exams: course.exams.map(exam => ({
                            ...exam,
                            status:
                                exam.id === examParticipationId
                                    ? status
                                    : exam.status,
                        })),
                    })),
                },
            },
            loading: false,
        })
    ),

    on(UPDATE_EXAM_STATUS_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(BOOK_COURSE_PARTICIPATION_EXTENSION, state => ({
        ...state,
        loading: true,
    })),

    on(
        BOOK_COURSE_PARTICIPATION_EXTENSION_SUCCESS,
        (state, { sub, courseParticipationExtension }) => ({
            ...state,
            data: {
                ...state.data,
                [sub]: {
                    ...state.data[sub],
                    courses: state.data[sub].courses.map(course => ({
                        ...course,
                        // TODO add extension
                    })),
                },
            },
            loading: false,
        })
    ),

    on(BOOK_COURSE_PARTICIPATION_EXTENSION_FAILURE, state => ({
        ...state,
        loading: false,
    })),

    on(DELETE_EXAM_PARTICIPATION, state => ({
        ...state,
        loading: true,
    })),

    on(
        DELETE_EXAM_PARTICIPATION_SUCCESS,
        (state, { sub, examParticipationId }) => ({
            ...state,
            data: {
                ...state.data,
                [sub]: {
                    ...state.data[sub],
                    courses: state.data[sub].courses.map(cP => ({
                        ...cP,
                        exams: cP.exams.filter(
                            exam => exam.id !== examParticipationId
                        ),
                    })),
                },
            },
            loading: false,
        })
    ),

    on(DELETE_EXAM_PARTICIPATION_FAILURE, state => ({
        ...state,
        loading: false,
    }))
);
