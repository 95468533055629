import { Pipe, PipeTransform } from '@angular/core';
import { LocalDate, LocalTime } from '@js-joda/core';
import { GermanWeekdays } from '../../../../translations/src/lib/src/weekdays';

@Pipe({ name: 'prettifyDateTimeString' })
export class PrettifyDateTimeString implements PipeTransform {
    transform(dateTimeString: string) {
        if (
            dateTimeString === '' ||
            dateTimeString === null ||
            dateTimeString === undefined
        ) {
            return dateTimeString;
        }
        try {
            const dateObj = new Date(Date.parse(dateTimeString));
            return dateObj.toLocaleString();
        } catch (e) {
            console.log('Error in PrettifyDateTimeString:');
            console.log(e);
            return dateTimeString;
        }
    }
}

@Pipe({ name: 'prettifyDateString' })
export class PrettifyDateString implements PipeTransform {
    transform(dateString: string) {
        if (
            dateString === '' ||
            dateString === null ||
            dateString === undefined
        ) {
            return dateString;
        }
        try {
            const dateObj = new Date(Date.parse(dateString));
            return dateObj.toLocaleDateString('de');
        } catch (e) {
            console.log('Error in PrettifyDateString:');
            console.log(e);
            return dateString;
        }
    }
}

@Pipe({ name: 'prettifyTimeString' })
export class PrettifyTimeString implements PipeTransform {
    transform(timeString: string, withUhrString = false) {
        if (
            timeString === '' ||
            timeString === null ||
            timeString === undefined
        ) {
            return timeString;
        }
        try {
            const timeObj = LocalTime.parse(timeString);
            const hourString =
                timeObj.hour() < 10 ? '0' + timeObj.hour() : timeObj.hour();

            const minuteString =
                timeObj.minute() < 10
                    ? '0' + timeObj.minute()
                    : timeObj.minute();

            return (
                hourString + ':' + minuteString + (withUhrString ? ' Uhr' : '')
            );
        } catch (e) {
            console.log('Error in PrettifyTimeString:');
            console.log(e);
            return timeString;
        }
    }
}

@Pipe({ name: 'prettifyDateStringWithDayName' })
export class PrettifyDateStringWithDayName implements PipeTransform {
    transform(dateString: string) {
        if (
            dateString === '' ||
            dateString === null ||
            dateString === undefined
        ) {
            return dateString;
        }
        try {
            const dateObj = new Date(Date.parse(dateString));
            const dayValue = LocalDate.parse(dateString).dayOfWeek().value();
            return (
                GermanWeekdays[dayValue - 1] +
                ' - ' +
                dateObj.toLocaleDateString()
            );
        } catch (e) {
            console.log('Error in PrettifyDateStringWithDayName:');
            console.log(e);
            return dateString;
        }
    }
}
