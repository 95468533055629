<p-progressBar
    class="akJura-progressbar-cadetblue akJura-progressbar-small"
    mode="indeterminate"
    *ngIf="loading$ | async"
></p-progressBar>

<p-panel header="Nutzerverwaltung" class="akJura-content-panel">
    <p-table
        #dataTable
        *ngLet="query$ | async as query"
        [value]="customers$ | async"
        [lazy]="true"
        [scrollable]="true"
        (onLazyLoad)="load($event, query)"
        dataKey="sub"
        [rows]="query.rows"
        [first]="query.first"
        [totalRecords]="total$ | async"
        [showCurrentPageReport]="true"
        [rowsPerPageOptions]="[10, 25, 50]"
        [paginator]="true"
        currentPageReportTemplate="Zeigt {first} bis {last} von {totalRecords} Kunden"
        [loading]="loading$ | async"
    >
        <ng-template
            pTemplate="caption"
            *ngLet="selectedCount$ | async as selectedCount"
        >
            <div class="table-header p-d-flex p-jc-between p-ai-baseline">
                <div>
                    <span *ngIf="selectedCount === 0" class="p-flex-">
                        Es wurde noch kein Nutzer ausgewählt
                    </span>
                    <span *ngIf="selectedCount === 1">
                        Es ist {{ selectedCount }} Nutzer ausgewählt
                    </span>
                    <span *ngIf="selectedCount > 1">
                        Es sind {{ selectedCount }} Nutzer ausgewählt
                    </span>
                </div>
                <div>
                    <button
                        type="button"
                        pButton
                        icon="pi pi-file-excel"
                        (click)="createExport()"
                        class="p-button-secondary p-ml-4"
                        label="Export Kundenliste"
                    ></button>
                    <button
                        type="button"
                        pButton
                        icon="pi pi-envelope"
                        (click)="mailtoSelected()"
                        class="p-button-secondary p-ml-4"
                        label="E-Mail Senden"
                        [disabled]="selectedCount === 0"
                    ></button>
                    <button
                        type="button"
                        pButton
                        icon="pi pi-times"
                        (click)="filterService.resetAll()"
                        class="p-button-secondary p-ml-4"
                        label="Filter zurücksetzen"
                    ></button>
                </div>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th class="first-column"></th>
                <th>Name</th>
                <th>SOLL</th>
                <th>HABEN</th>
                <th>Konto</th>
                <th>Rechtsgebiet</th>
                <th>Produkt</th>
                <th>Buchungsdatum</th>
                <th>Rechnungsnummer</th>
                <th>Status</th>
                <th>Prüfungen</th>
                <th>Status Prüfungen</th>
                <th>Anmerkung</th>
            </tr>
            <!-- Search field Header-->
            <tr [formGroup]="filterService.form">
                <th class="first-column">
                    <button
                        pButton
                        icon="pi pi-plus"
                        class="small green"
                        (click)="selectAll()"
                        [disabled]="isAllSelected$ | async"
                    ></button>
                    <br />
                    <button
                        pButton
                        class="p-mt-2 small red"
                        icon="pi pi-minus"
                        (click)="deselectAll()"
                        [disabled]="isNoneSelected$ | async"
                    ></button>
                </th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <input
                            class="c-width-100"
                            pInputText
                            type="text"
                            formControlName="displayName"
                            placeholder="Name"
                        />
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="
                                filterService.reset(FilterFields.displayName)
                            "
                        ></i>
                    </span>
                </th>
                <th></th>
                <th></th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-multiSelect
                            class="c-width-100"
                            dropdownIcon=""
                            styleClass="c-width-100"
                            [options]="
                                filterService.filter.get(FilterFields.balance)
                                    .options
                            "
                            formControlName="balance"
                            optionLabel="label"
                            optionValue="value"
                            appendTo="body"
                            placeholder="Konto"
                            [filter]="false"
                        ></p-multiSelect>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="filterService.reset(FilterFields.balance)"
                        ></i>
                    </span>
                </th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-multiSelect
                            class="c-width-100"
                            dropdownIcon=""
                            styleClass="c-width-100"
                            [options]="
                                filterService.filter.get(
                                    FilterFields.legalAreaCode
                                ).options
                            "
                            formControlName="legalAreaCode"
                            optionLabel="label"
                            optionValue="value"
                            appendTo="body"
                            placeholder="Rechtsgebiet"
                            [filter]="false"
                        ></p-multiSelect>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="
                                filterService.reset(FilterFields.legalAreaCode)
                            "
                        ></i>
                    </span>
                </th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-multiSelect
                            class="c-width-100"
                            dropdownIcon=""
                            styleClass="c-width-100"
                            [options]="
                                filterService.filter.get(FilterFields.products)
                                    .options
                            "
                            formControlName="products"
                            optionLabel="label"
                            optionValue="value"
                            appendTo="body"
                            placeholder="Produkt"
                            [filter]="false"
                        ></p-multiSelect>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="filterService.reset(FilterFields.products)"
                        ></i>
                    </span>
                </th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-calendar
                            class="c-width-100"
                            appendTo="body"
                            dateFormat="dd.mm.yy"
                            readonlyInput="true"
                            formControlName="bookingDateFrom"
                            placeholder="Von"
                        ></p-calendar>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="
                                filterService.reset(
                                    FilterFields.bookingDateFrom
                                )
                            "
                        ></i>
                    </span>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-calendar
                            class="c-width-100 p-pt-2"
                            appendTo="body"
                            dateFormat="dd.mm.yy"
                            readonlyInput="true"
                            formControlName="bookingDateTo"
                            placeholder="Bis"
                        ></p-calendar>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="
                                filterService.reset(FilterFields.bookingDateTo)
                            "
                        ></i>
                    </span>
                </th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <input
                            class="c-width-100"
                            pInputText
                            type="text"
                            formControlName="invoice"
                            placeholder="Rechnungsnummer"
                        />
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="filterService.reset(FilterFields.invoice)"
                        ></i>
                    </span>
                </th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-multiSelect
                            class="c-width-100"
                            dropdownIcon=""
                            styleClass="c-width-100"
                            [options]="
                                filterService.filter.get(FilterFields.status)
                                    .options
                            "
                            optionLabel="label"
                            optionValue="value"
                            formControlName="status"
                            appendTo="body"
                            placeholder="Status"
                            [filter]="false"
                        ></p-multiSelect>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="filterService.reset(FilterFields.status)"
                        ></i>
                    </span>
                </th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-multiSelect
                            class="c-width-100 p-pt-2"
                            dropdownIcon=""
                            styleClass="c-width-100"
                            [options]="
                                filterService.filter.get(FilterFields.examStart)
                                    .options
                            "
                            formControlName="examStart"
                            optionLabel="label"
                            optionValue="value"
                            appendTo="body"
                            placeholder="Prüfungstermin"
                            [filter]="false"
                        ></p-multiSelect>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="
                                filterService.reset(FilterFields.examStart)
                            "
                        ></i>
                    </span>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-multiSelect
                            class="c-width-100 p-pt-2"
                            dropdownIcon=""
                            styleClass="c-width-100"
                            [options]="
                                filterService.filter.get(
                                    FilterFields.examLocation
                                ).options
                            "
                            formControlName="examLocation"
                            optionLabel="label"
                            optionValue="value"
                            appendTo="body"
                            placeholder="Prüfungsort"
                            [filter]="false"
                        ></p-multiSelect>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="
                                filterService.reset(FilterFields.examLocation)
                            "
                        ></i>
                    </span>
                </th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-multiSelect
                            class="c-width-100"
                            dropdownIcon=""
                            styleClass="c-width-100"
                            [options]="
                                filterService.filter.get(
                                    FilterFields.examStatus
                                ).options
                            "
                            optionLabel="label"
                            optionValue="value"
                            formControlName="examStatus"
                            appendTo="body"
                            placeholder="Prüfungsstatus"
                            [filter]="false"
                        ></p-multiSelect>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="
                                filterService.reset(FilterFields.examStatus)
                            "
                        ></i>
                    </span>
                </th>
                <th>
                    <span
                        class="p-input-icon-right p-d-flex p-ai-baseline c-width-100"
                    >
                        <p-dropdown
                            class="c-width-100"
                            dropdownIcon=""
                            styleClass="c-width-100"
                            [options]="
                                filterService.filter.get(
                                    FilterFields.annotation
                                ).options
                            "
                            optionLabel="label"
                            optionValue="value"
                            formControlName="annotation"
                            appendTo="body"
                            placeholder="Anmerkung"
                        ></p-dropdown>
                        <i
                            class="pi pi-times c-clear-icon"
                            (click)="
                                filterService.reset(FilterFields.annotation)
                            "
                        ></i>
                    </span>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowIndex="rowIndex" let-customerInfo>
            <tr (click)="openModal(customerInfo)" class="clickable-row">
                <td class="first-column">
                    <p-checkbox
                        [binary]="true"
                        [ngModel]="customerInfo.selected"
                        (ngModelChange)="toggle(customerInfo.sub)"
                    ></p-checkbox>
                </td>
                <!-- Name -->
                <td>
                    <span class="p-text-bold">
                        {{ customerInfo.displayName }}
                    </span>
                </td>
                <!-- Gesamtkosten -->
                <td>
                    {{ customerInfo.debit / 100 | currency: 'EUR' }}
                </td>
                <td>
                    {{ customerInfo.credit / 100 | currency: 'EUR' }}
                </td>
                <td>
                    {{ customerInfo.balance / 100 | currency: 'EUR' }}
                </td>
                <!-- Kursbuchungen -->
                <td colspan="7" class="p-p-0">
                    <table>
                        <tbody class="p-datatable-tbody custom-table">
                            <ng-container
                                *ngFor="let course of customerInfo.courses"
                            >
                                <tr *ngIf="course.isVisible">
                                    <td>{{ course.legalArea?.code || '-' }}</td>
                                    <td>
                                        {{
                                            (course.product
                                                | courseType
                                                    : course.isTest
                                                    : true) || '-'
                                        }}
                                    </td>
                                    <td>
                                        {{ (course.bookingDate | date) || '-' }}
                                    </td>
                                    <td>
                                        <ng-container
                                            *ngIf="
                                                course.invoice?.corrections &&
                                                    course.invoice?.corrections
                                                        .length;
                                                else noCorrection
                                            "
                                        >
                                            {{
                                                course.invoice.corrections[0]
                                                    .prefix
                                            }}-{{
                                                course.invoice.corrections[0]
                                                    .number
                                            }}-{{
                                                course.invoice.corrections[0]
                                                    .suffix
                                            }}
                                        </ng-container>
                                        <ng-template #noCorrection>
                                            <ng-container
                                                *ngIf="
                                                    course.invoice;
                                                    else noInvoice
                                                "
                                            >
                                                {{ course.invoice?.prefix }}-{{
                                                    course.invoice?.number
                                                }}
                                            </ng-container>
                                        </ng-template>
                                        <ng-template #noInvoice>-</ng-template>
                                    </td>
                                    <td>
                                        <cna-course-status
                                            [status]="
                                                course.status
                                                    | courseStatus
                                                        : course.product
                                                        : course.completed
                                            "
                                        ></cna-course-status>
                                    </td>
                                    <td>-</td>
                                    <td>-</td>
                                </tr>
                            </ng-container>
                            <tr *ngIf="customerInfo.courses.length === 0">
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                                <td>-</td>
                            </tr>
                            <ng-container
                                *ngFor="let course of customerInfo.courses"
                            >
                                <ng-container
                                    *ngFor="let extension of course.extensions"
                                >
                                    <tr *ngIf="extension.isVisible">
                                        <td>
                                            {{ course.legalArea?.code || '-' }}
                                        </td>
                                        <td>
                                            {{
                                                (BookingType.EXTENSION
                                                    | bookingType
                                                        : course.type
                                                        : course.isTest
                                                        : true) || '-'
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                (extension.bookingDate
                                                    | date) || '-'
                                            }}
                                        </td>
                                        <td>
                                            <ng-container
                                                *ngIf="
                                                    extension.invoice;
                                                    else noInvoice
                                                "
                                            >
                                                {{
                                                    extension.invoice.prefix
                                                }}-{{
                                                    extension.invoice.number
                                                }}
                                            </ng-container>
                                            <ng-template #noInvoice
                                                >-</ng-template
                                            >
                                        </td>
                                        <td>-</td>
                                        <td>-</td>
                                        <td>-</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <ng-container
                                *ngFor="let course of customerInfo.courses"
                            >
                                <ng-container *ngFor="let exam of course.exams">
                                    <tr *ngIf="exam.isVisible">
                                        <td>
                                            {{ course.legalArea?.code || '-' }}
                                        </td>
                                        <td>
                                            {{
                                                (BookingType.EXAM
                                                    | bookingType
                                                        : course.type
                                                        : course.isTest
                                                        : true) || '-'
                                            }}
                                        </td>
                                        <td>
                                            {{
                                                (exam.bookingDate | date) || '-'
                                            }}
                                        </td>
                                        <td>
                                            <ng-container
                                                *ngIf="
                                                    exam.invoice;
                                                    else noInvoice
                                                "
                                            >
                                                {{ exam.invoice.prefix }}-{{
                                                    exam.invoice.number
                                                }}
                                            </ng-container>
                                            <ng-template #noInvoice
                                                >-</ng-template
                                            >
                                        </td>
                                        <td>-</td>
                                        <td>
                                            {{ exam.start | date: 'YYYY-MM' }}
                                            {{ exam.location }}
                                        </td>
                                        <td>
                                            {{
                                                (exam.status | examState) || '-'
                                            }}
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                        </tbody>
                    </table>
                </td>
                <!-- Anmerkung -->
                <td style="text-align: center">
                    <i
                        *ngIf="customerInfo.annotation; else noAnnotation"
                        class="pi pi-check p-m-auto"
                    ></i>
                    <ng-template #noAnnotation> - </ng-template>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-panel>
