import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'cna-start-page',
    templateUrl: './start-page.component.html',
    styleUrls: ['./start-page.component.css'],
})
export class StartPageComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {}
}
