import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import type { UUID } from '../../../../../../../../shared/generic-types/src';
import { CustomerActions } from '../+state/customer';

@Injectable()
export class CustomerModalService {
    constructor(private store: Store) {}

    loadCustomer(id: UUID): void {
        this.store.dispatch(CustomerActions.LOAD_CUSTOMER({ id }));
    }
}
