import { Injectable } from '@angular/core';
import { Examinable } from '@cna/projects/ak-jura/shared/interfaces';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Id, UUID } from '@cna/shared/generic-types';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { environment } from '../../../../../../../../apps/ak-jura/frontends/backoffice/src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ExaminableService extends EntityCollectionServiceBase<Examinable> {
    constructor(
        elementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient
    ) {
        super('Examinable', elementsFactory);
    }

    /***
     * return all Examinables for the given moduleId WITHOUT saving them to the store.
     * @param moduleId UUID of the Module Entity.
     */
    getManyByModuleId(moduleId: Id | UUID): Observable<Examinable[]> {
        return this.http.get<Examinable[]>(
            `${environment.apiHost}/examinable?filter=moduleId||$eq||${moduleId}`
        );
    }
}
