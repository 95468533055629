import { Customer } from '../../../../../../nest/customer-management/src/lib/entities/customer';
import { UUID } from '@cna/shared/generic-types';
import { LegalAreaEntity } from '../../../../../../nest/data-management/src/lib/entities/legal-area.entity';
import { Exam } from './exam';

export enum ExamStatus {
    Booked = 'BOOKED',
    Participated = 'PARTICIPATED',
    NotParticipated = 'NOT_PARTICIPATED',
    CertificateSent = 'CERTIFICATE_SENT',
}

export interface ExamParticipation {
    id: UUID;
    participant?: Customer;
    participantId: UUID;
    exam: Exam;
    examId: UUID;
    isPaid: boolean;
    legalArea: LegalAreaEntity;
    legalAreaId: UUID;
    status: ExamStatus;
    createdAt: string;
}
