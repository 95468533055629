import { Booking, isBooking } from './booking';
import { BookingType } from './booking-type.enum';

export class ExamBooking extends Booking {}

export function isExamBooking(examBooking: any): examBooking is ExamBooking {
    return (
        isBooking(examBooking) && examBooking.bookingType === BookingType.EXAM
    );
}
