import { Booking, isBooking } from './booking';
import { BookingType } from './booking-type.enum';

export class ExtensionBooking extends Booking {}

export function isCourseExtensionBooking(
    extensionBooking: any
): extensionBooking is ExtensionBooking {
    return (
        isBooking(extensionBooking) &&
        extensionBooking.bookingType === BookingType.EXTENSION
    );
}
