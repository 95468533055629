import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CustomerReadingTimesComponent } from './components/customer-reading-times/customer-reading-times.component';
import { ListCustomerComponent } from './components/list-customer/list-customer.component';
import { RouterModule } from '@angular/router';
import { customerManagementRoutes } from './routes';
import { TableModule } from 'primeng/table';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FieldsetModule } from 'primeng/fieldset';
import { AngularUiPipesModule } from '../../../../../../../angular/ui/pipes/src';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { MoneyModule } from '../../../../../../../angular/commerce/money/src';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { BlockUIModule } from 'primeng/blockui';
import { ToolbarModule } from 'primeng/toolbar';
import { CardModule } from 'primeng/card';
import { TriStateCheckboxModule } from 'primeng/tristatecheckbox';
import { ToastModule } from 'primeng/toast';
import { NgLetModule } from '@ngrx-utils/store';
import { PrettifyDateString } from '../../../../../../../angular/ui/pipes/src/lib/pipes/pretify-date-time-strings';
import { CheckboxModule } from 'primeng/checkbox';
import { TooltipModule } from 'primeng/tooltip';
import { DropdownModule } from 'primeng/dropdown';
import { RippleModule } from 'primeng/ripple';
import { AkJuraAngularSharedComponentsModule } from '../../../../shared-components/src';
import { CustomerModalComponent } from './components/customer-modal/customer-modal.component';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { TabViewModule } from 'primeng/tabview';
import { DialogModule } from 'primeng/dialog';
import { StoreModule } from '@ngrx/store';
import {
    CUSTOMER_STORE_NAME,
    customerReducer,
    CustomerEffects,
} from './+state/customer';
import { EffectsModule } from '@ngrx/effects';
import { CustomerService } from './services/customer.service';
import {
    ExamStatePipe,
    CourseTypePipe,
    TransactionTypePipe,
    BookingTypePipe,
    CourseStatusPipe,
    DayCountdownPipe,
    InvoiceNumberPipe,
    BalanceStatusPipe,
    CourseStatusEnumPipe,
    ExamTimesPipe,
} from './pipes';
import { CustomerDetailsComponent } from './components/customer-details/customer-details.component';
import { CustomerBookingsComponent } from './components/customer-bookings/customer-bookings.component';
import { CustomerPaymentsComponent } from './components/customer-payments/customer-payments.component';
import { CustomerExamsComponent } from './components/customer-exams/customer-exams.component';
import {
    CUSTOMER_LIST_STORE_NAME,
    CustomerListEffects,
    CustomerListReducer,
} from './+state/customer-list';
import { CustomerListService } from './services/customer-list.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CourseStatusComponent } from './components/course-status/course-status.component';
import { StyleClassModule } from 'primeng/styleclass';
import { MultiSelectModule } from 'primeng/multiselect';
import { FilterService } from './services/filter.service';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CustomerModalService } from './services/customer-modal.service';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { LatestPipe } from './pipes/latest.pipe';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(customerManagementRoutes),
        TableModule,
        ButtonModule,
        InputTextModule,
        FieldsetModule,
        AngularUiPipesModule,
        OverlayPanelModule,
        ReactiveFormsModule,
        CalendarModule,
        InputNumberModule,
        MoneyModule,
        ConfirmDialogModule,
        PanelModule,
        ProgressBarModule,
        BlockUIModule,
        ToolbarModule,
        CardModule,
        TriStateCheckboxModule,
        FormsModule,
        ToastModule,
        NgLetModule,
        CheckboxModule,
        TooltipModule,
        DropdownModule,
        RippleModule,
        AkJuraAngularSharedComponentsModule,
        DynamicDialogModule,
        TabViewModule,
        DialogModule,
        InputSwitchModule,
        StoreModule.forFeature(CUSTOMER_STORE_NAME, customerReducer),
        StoreModule.forFeature(CUSTOMER_LIST_STORE_NAME, CustomerListReducer),
        EffectsModule.forFeature([CustomerEffects, CustomerListEffects]),
        StyleClassModule,
        MultiSelectModule,
        InputTextareaModule,
        ProgressSpinnerModule,
    ],
    declarations: [
        ListCustomerComponent,
        CustomerModalComponent,
        CustomerDetailsComponent,
        CustomerBookingsComponent,
        CustomerPaymentsComponent,
        CustomerExamsComponent,
        CustomerReadingTimesComponent,
        CourseStatusComponent,
        ExamStatePipe,
        CourseTypePipe,
        TransactionTypePipe,
        BookingTypePipe,
        CourseStatusPipe,
        DayCountdownPipe,
        InvoiceNumberPipe,
        BalanceStatusPipe,
        CourseStatusEnumPipe,
        ExamTimesPipe,
        LatestPipe,
    ],
    providers: [
        ConfirmationService,
        DatePipe,
        ExamStatePipe,
        PrettifyDateString,
        CustomerService,
        CustomerListService,
        ExamStatePipe,
        CourseTypePipe,
        TransactionTypePipe,
        BookingTypePipe,
        CourseStatusPipe,
        DayCountdownPipe,
        InvoiceNumberPipe,
        BalanceStatusPipe,
        FilterService,
        CourseStatusEnumPipe,
        CustomerModalService,
        ExamTimesPipe,
        LatestPipe,
    ],
    entryComponents: [CustomerModalComponent],
})
export class CustomerManagementModule {}
