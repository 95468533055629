import { Booking, isBooking } from './booking';
import { BookingType } from './booking-type.enum';

export abstract class CourseBooking extends Booking {}

export function isCourseBooking(
    courseBooking: any
): courseBooking is CourseBooking {
    return (
        isBooking(courseBooking) &&
        courseBooking.bookingType === BookingType.COURSE
    );
}
