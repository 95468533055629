import { Pipe, PipeTransform } from '@angular/core';
import { ExaminationPeriod } from '../../../../../../shared/interfaces/src/lib/data-management/exam/examination-period';

@Pipe({ name: 'examTimes' })
export class ExamTimesPipe implements PipeTransform {
    transform(
        examinationPeriods: ExaminationPeriod[],
        type: 'start' | 'end'
    ): Date {
        if (type === 'start') {
            let min = Number.MAX_VALUE;
            for (const period of examinationPeriods) {
                min = Math.min(
                    min,
                    new Date(`${period.date} ${period.startTime}`).getTime()
                );
            }
            return new Date(min);
        } else if (type === 'end') {
            let max = 0;
            for (const period of examinationPeriods) {
                max = Math.max(
                    max,
                    new Date(`${period.date} ${period.endTime}`).getTime()
                );
            }
            return new Date(max);
        }
    }
}
