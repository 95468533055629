import { EntityMetadataMap } from '@ngrx/data';
const entityMetadata: EntityMetadataMap = {
    Exam: {},
    Customer: {},
    LegalArea: {},
    Book: { selectId: model => model.documentRef },
    Course: {},
    Examinable: {},
    Repetitorium: {},
    'Module-document-template': {},
    'Module-document-info': {},
    'Control-test': {},
    'Course-Participation': {},
    'Discount-code': {},
    Payment: {},
    'time-expenditure': {},
};

const pluralNames = {
    Exam: 'Exam',
    Customer: 'Customer',
    LegalArea: 'LegalArea',
    Book: 'Book',
    Course: 'Course',
    Examinable: 'Examinable',
    Repetitorium: 'Repetitorium',
    'Module-document-template': 'module-document-templates',
    'Control-test': 'Control-tests',
    'Discount-code': 'Discount-code',
    Payment: 'Payment',
    'time-expenditure': 'time-expenditure',
    'Module-document-info': 'Module-document-info',
};

export const entityConfig = {
    entityMetadata,
    pluralNames,
};
