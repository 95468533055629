import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CustomerService } from '../../services/customer.service';
import {
    ADD_PAYMENT,
    ADD_PAYMENT_FAILURE,
    ADD_PAYMENT_SUCCESS,
    ADD_READING_TIME,
    ADD_READING_TIME_FAILURE,
    ADD_READING_TIME_SUCCESS,
    BOOK_COURSE_PARTICIPATION_EXTENSION,
    BOOK_COURSE_PARTICIPATION_EXTENSION_FAILURE,
    BOOK_COURSE_PARTICIPATION_EXTENSION_SUCCESS,
    DELETE_EXAM_PARTICIPATION,
    DELETE_EXAM_PARTICIPATION_FAILURE,
    DELETE_EXAM_PARTICIPATION_SUCCESS,
    DELETE_PAYMENT,
    DELETE_PAYMENT_FAILURE,
    DELETE_PAYMENT_SUCCESS,
    DOWNLOAD_CERTIFICATE,
    DOWNLOAD_CERTIFICATE_FAILURE,
    DOWNLOAD_CERTIFICATE_SUCCESS,
    DOWNLOAD_INVOICE,
    DOWNLOAD_INVOICE_FAILURE,
    DOWNLOAD_INVOICE_SUCCESS,
    LOAD_CUSTOMER,
    LOAD_CUSTOMER_FAILURE,
    LOAD_CUSTOMER_SUCCESS,
    TRIGGER_PAYMENT_REQUEST,
    TRIGGER_PAYMENT_REQUEST_FAILURE,
    TRIGGER_PAYMENT_REQUEST_SUCCESS,
    UPDATE_ANNOTATION,
    UPDATE_ANNOTATION_FAILURE,
    UPDATE_ANNOTATION_SUCCESS,
    UPDATE_BLOCK_COURSE_PARTICIPATION,
    UPDATE_BLOCK_COURSE_PARTICIPATION_FAILURE,
    UPDATE_BLOCK_COURSE_PARTICIPATION_SUCCESS,
    UPDATE_COMPLETED,
    UPDATE_COMPLETED_FAILURE,
    UPDATE_COMPLETED_SUCCESS,
    UPDATE_EXAM_STATUS,
    UPDATE_EXAM_STATUS_FAILURE,
    UPDATE_EXAM_STATUS_SUCCESS,
    UPDATE_IS_PAID,
    UPDATE_IS_PAID_FAILURE,
    UPDATE_IS_PAID_SUCCESS,
    CORRECT_INVOICE,
    CORRECT_INVOICE_SUCCESS,
    CORRECT_INVOICE_FAILURE,
} from './actions';
import { catchError, concatMap, map, mergeMap, take } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import {
    CustomerDetailsCourseParticipation,
    CustomerDetailsExamParticipation,
    CustomerListData,
} from '../../../../../../../shared/interfaces/src';
import { CustomerListSelectors } from '../customer-list';
import { GET_COURSE_PARTICIPATION, GET_EXAM_PARTICIPATION } from './selectors';

@Injectable()
export class CustomerEffects {
    constructor(
        private actions$: Actions,
        private store: Store,
        private service: CustomerService
    ) {}

    loadCustomerDetails$ = createEffect(() =>
        this.actions$.pipe(
            ofType(LOAD_CUSTOMER),
            mergeMap(({ id }) => this.service.getCustomerDetails(id)),
            map(LOAD_CUSTOMER_SUCCESS),
            catchError(({ message }) => of(LOAD_CUSTOMER_FAILURE({ message })))
        )
    );

    updateCompleted$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_COMPLETED),
            mergeMap(({ sub, id, completed }) =>
                this.service.updateCompleted(sub, id, completed)
            ),
            map(UPDATE_COMPLETED_SUCCESS),
            catchError(({ message }) =>
                of(UPDATE_COMPLETED_FAILURE({ message }))
            )
        )
    );

    updateAnnotation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_ANNOTATION),
            mergeMap(action =>
                forkJoin([
                    this.store
                        .select(CustomerListSelectors.GET_ONE(action.sub))
                        .pipe(take(1)),
                ]).pipe(
                    map<[CustomerListData], [typeof action, CustomerListData]>(
                        ([customerListData]) => [action, customerListData]
                    )
                )
            ),
            mergeMap(([{ id, sub, annotation }, customerListData]) =>
                this.service.putAnnotation(
                    sub,
                    id,
                    annotation,
                    customerListData
                )
            ),
            map(UPDATE_ANNOTATION_SUCCESS),
            catchError(({ message }) =>
                of(UPDATE_ANNOTATION_FAILURE({ message }))
            )
        )
    );

    addPayment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ADD_PAYMENT),
            mergeMap(
                ({
                    customerId,
                    sub,
                    amount,
                    invoiceId,
                    date,
                    bookingType,
                    isCorrection,
                }) =>
                    this.service.postPayment(
                        customerId,
                        sub,
                        date,
                        amount,
                        bookingType,
                        invoiceId,
                        isCorrection
                    )
            ),
            map(ADD_PAYMENT_SUCCESS),
            catchError(({ message }) => of(ADD_PAYMENT_FAILURE({ message })))
        )
    );

    deletePayment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DELETE_PAYMENT),
            mergeMap(({ id, sub, amount }) =>
                this.service.deletePayment(id, sub, amount)
            ),
            map(DELETE_PAYMENT_SUCCESS),
            catchError(({ message }) => of(DELETE_PAYMENT_FAILURE({ message })))
        )
    );

    downloadInvoice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DOWNLOAD_INVOICE),
            mergeMap(({ invoiceId, bookingType, isCorrection }) =>
                this.service.getInvoice(bookingType, invoiceId, isCorrection)
            ),
            map(DOWNLOAD_INVOICE_SUCCESS),
            catchError(({ message }) =>
                of(DOWNLOAD_INVOICE_FAILURE({ message }))
            )
        )
    );

    updateExamStatus$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_EXAM_STATUS),
            mergeMap(({ sub, status, examParticipationId }) =>
                this.service.patchExamStatus(sub, status, examParticipationId)
            ),
            map(UPDATE_EXAM_STATUS_SUCCESS),
            catchError(({ message }) =>
                of(UPDATE_EXAM_STATUS_FAILURE({ message }))
            )
        )
    );

    updateCourseParticipationIsBlocked$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_BLOCK_COURSE_PARTICIPATION),
            mergeMap(({ sub, courseParticipationId, isBlocked }) =>
                this.service.patchCourseParticipationIsBlocked(
                    sub,
                    courseParticipationId,
                    isBlocked
                )
            ),
            map(UPDATE_BLOCK_COURSE_PARTICIPATION_SUCCESS),
            catchError(({ message }) =>
                of(UPDATE_BLOCK_COURSE_PARTICIPATION_FAILURE({ message }))
            )
        )
    );

    addReadingTime$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ADD_READING_TIME),
            mergeMap(({ sub, timeExpenditure }) =>
                this.service.postTimeExpenditure(sub, timeExpenditure)
            ),
            map(ADD_READING_TIME_SUCCESS),
            catchError(({ message }) =>
                of(ADD_READING_TIME_FAILURE({ message }))
            )
        )
    );

    bookCourseParticipationExtension$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BOOK_COURSE_PARTICIPATION_EXTENSION),
            mergeMap(({ sub, courseParticipationId, duration }) =>
                this.service.postCourseParticipationFreeExtension(
                    sub,
                    courseParticipationId,
                    duration
                )
            ),
            map(BOOK_COURSE_PARTICIPATION_EXTENSION_SUCCESS),
            catchError(({ message }) =>
                of(BOOK_COURSE_PARTICIPATION_EXTENSION_FAILURE({ message }))
            )
        )
    );

    triggerPaymentRequest$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TRIGGER_PAYMENT_REQUEST),
            mergeMap(({ sub, id, bookingType }) =>
                this.service.postPaymentRequest(sub, id, bookingType)
            ),
            map(TRIGGER_PAYMENT_REQUEST_SUCCESS),
            catchError(({ message }) =>
                of(TRIGGER_PAYMENT_REQUEST_FAILURE({ message }))
            )
        )
    );

    updateIsPaid$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UPDATE_IS_PAID),
            mergeMap(({ sub, id, bookingType, isPaid }) =>
                this.service.patchIsPaid(sub, id, bookingType, isPaid)
            ),
            map(UPDATE_IS_PAID_SUCCESS),
            catchError(({ message }) => of(UPDATE_IS_PAID_FAILURE({ message })))
        )
    );

    downloadCertificate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DOWNLOAD_CERTIFICATE),
            mergeMap(action =>
                forkJoin([
                    this.store
                        .select(CustomerListSelectors.GET_ONE(action.sub))
                        .pipe(take(1)),
                    this.store
                        .select(
                            GET_COURSE_PARTICIPATION(
                                action.sub,
                                action.courseParticipationId
                            )
                        )
                        .pipe(take(1)),
                    this.store
                        .select(
                            GET_EXAM_PARTICIPATION(
                                action.sub,
                                action.courseParticipationId
                            )
                        )
                        .pipe(take(1)),
                ]).pipe(
                    map<
                        [
                            CustomerListData,
                            CustomerDetailsCourseParticipation,
                            CustomerDetailsExamParticipation
                        ],
                        [
                            typeof action,
                            CustomerListData,
                            CustomerDetailsCourseParticipation,
                            CustomerDetailsExamParticipation
                        ]
                    >(
                        ([
                            customerListData,
                            courseParticipation,
                            examParticipation,
                        ]) => [
                            action,
                            customerListData,
                            courseParticipation,
                            examParticipation,
                        ]
                    )
                )
            ),
            mergeMap(
                ([
                    { sub, courseParticipationId },
                    customerListData,
                    courseParticipation,
                    examParticipation,
                ]) =>
                    this.service.downloadCertificate(
                        sub,
                        courseParticipationId,
                        customerListData,
                        courseParticipation,
                        examParticipation
                    )
            ),
            map(DOWNLOAD_CERTIFICATE_SUCCESS),
            catchError(({ message }) => {
                return of(DOWNLOAD_CERTIFICATE_FAILURE({ message }));
            })
        )
    );

    deleteExamParticipation$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DELETE_EXAM_PARTICIPATION),
            mergeMap(({ sub, examParticipationId }) =>
                this.service.deleteExamParticipation(sub, examParticipationId)
            ),
            map(DELETE_EXAM_PARTICIPATION_SUCCESS),
            catchError(({ message }) =>
                of(DELETE_EXAM_PARTICIPATION_FAILURE({ message }))
            )
        )
    );

    correctInvoice$ = createEffect(() =>
        this.actions$.pipe(
            ofType(CORRECT_INVOICE),
            concatMap(({ sub, invoiceId, bookingType, totalAmount }) =>
                this.service
                    .postCourseParticipationInvoiceCorrection(
                        invoiceId,
                        totalAmount
                    )
                    .pipe(map(invoice => ({ invoice, sub })))
            ),
            map(CORRECT_INVOICE_SUCCESS),
            catchError(({ message }) =>
                of(CORRECT_INVOICE_FAILURE({ message }))
            )
        )
    );
}
