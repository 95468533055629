import { Pipe, PipeTransform } from '@angular/core';
import { BalanceStatus } from '../../../../../../shared/interfaces/src';

@Pipe({ name: 'balanceStatus' })
export class BalanceStatusPipe implements PipeTransform {
    transform(status: BalanceStatus): string {
        switch (status) {
            case BalanceStatus.Balanced:
                return 'Ausgeglichen';
            case BalanceStatus.Outstanding:
                return 'Negativ';
            case BalanceStatus.Overpaid:
                return 'Positiv';
            default:
                throw new Error('Unknown balance status: ' + status);
        }
    }
}
