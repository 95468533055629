<p-table
    [value]="localBookings"
    dataKey="listData.id"
    [autoLayout]="true"
    rowExpandMode="single"
>
    <ng-template pTemplate="header">
        <tr>
            <th>Name</th>
            <th>Produkt</th>
            <th>Rechtsgebiet</th>
            <th>Tarif</th>
            <th>Preis</th>
            <th>Buchung</th>
            <th>Laufzeit</th>
            <th>Status</th>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <p>Dieser Nutzer hat noch keine Kurse gebucht.</p>
    </ng-template>
    <ng-template pTemplate="body" let-booking let-expanded="expanded">
        <tr>
            <td class="flex">
                <button
                    type="button"
                    pButton
                    pRipple
                    [pRowToggler]="booking"
                    class="p-button-text p-button-rounded p-button-plain p-mr-2"
                    [icon]="
                        expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'
                    "
                ></button>
                <span class="highlight">{{
                    booking.details.bookedCourse.description
                }}</span>
            </td>
            <td>
                {{
                    booking.details.bookedCourse.type
                        | courseType: booking.details.isTest
                }}
            </td>
            <td>{{ booking.details.bookedCourse.associatedLegalArea.name }}</td>
            <td>
                {{
                    booking.details.priceStructure?.name ||
                        (booking.details.isTest ? 'Testzugang' : '-')
                }}
            </td>
            <td>
                {{
                    booking.details.priceStructure?.price / 100 || 0
                        | currency: 'EUR'
                }}
            </td>
            <td>
                {{ booking.details.createdAt | date }}
            </td>
            <td>{{ booking.details.validUntil | dayCountdown }}d</td>
            <td>
                <cna-course-status
                    [status]="
                        booking.listData.status
                            | courseStatus
                                : booking.listData.product
                                : booking.listData.completed
                    "
                ></cna-course-status>
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion" let-booking>
        <tr>
            <td colspan="8">
                <p-table
                    [value]="booking.details.moduleProgress"
                    dataKey="moduleId"
                    styleClass="p-datatable-sm"
                    [autoLayout]="true"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Modul</th>
                            <th>Lernzeit</th>
                            <th>Lernstatus</th>
                            <th>Lernkontrolle</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-module let-booking>
                        <tr>
                            <td>
                                Modul
                                {{
                                    module.moduleNumber
                                        | numberToNumbering
                                            : 'upperRomanNumerals'
                                }}
                            </td>
                            <td class="flex">
                                <span>{{
                                    module.totalTimeRead | secondsToHours
                                }}</span>
                                <button
                                    pButton
                                    type="button"
                                    icon="pi pi-plus"
                                    class="p-button-outlined p-button-rounded p-button-raised p-button-secondary p-button-sm p-ml-2"
                                    (click)="
                                        openAddReadingTimeDialog(
                                            booking.courseParticipationId,
                                            module.moduleId,
                                            module.firstSection
                                        )
                                    "
                                ></button>
                            </td>
                            <td>
                                {{
                                    module.percentageRead * 100
                                        | number: '1.2-2':'de'
                                }}
                                %
                            </td>
                            <td>
                                <i
                                    class="{{
                                        module.controlTestPassed
                                            ? 'pi pi-check'
                                            : 'pi pi-times'
                                    }}"
                                ></i>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
                <div class="p-d-flex p-flex-row p-jc-between p-pt-3">
                    <div>
                        <div class="p-mb-3">
                            <span class="p-text-bold">Letzter Login:</span>
                            <span
                                *ngIf="
                                    booking.details.lastLogin;
                                    else noLastLogin
                                "
                            >
                                {{
                                    booking.details.lastLogin
                                        | prettifyDateTimeString
                                }}
                                Uhr
                            </span>
                            <ng-template #noLastLogin>
                                <span> - </span>
                            </ng-template>
                        </div>
                        <div *ngIf="booking.details.invoice; else noAddress">
                            <p class="p-mb-1 p-text-bold">Rechnungsadresse</p>
                            <div
                                *ngIf="
                                    booking.details.invoice
                                        .differentInvoiceAddress &&
                                        (booking.details.invoice
                                            .differentInvoiceAddress
                                            .firstName &&
                                        booking.details.invoice
                                            .differentInvoiceAddress.lastName || booking.details.invoice.differentInvoiceAddress.company);
                                    else customerAddress
                                "
                            >
                                <ng-container *ngIf="booking.details.invoice.differentInvoiceAddress.firstName && booking.details.invoice.differentInvoiceAddress.lastName">
                                    {{
                                        booking.details.invoice
                                            .differentInvoiceAddress.salutation
                                    }}
                                    {{
                                        booking.details.invoice
                                            .differentInvoiceAddress.title
                                    }}
                                    {{
                                        booking.details.invoice
                                            .differentInvoiceAddress.firstName
                                    }}
                                    {{
                                        booking.details.invoice
                                            .differentInvoiceAddress.lastName
                                    }}
                                    <br />
                                </ng-container>
                                {{
                                    booking.details.invoice
                                        .differentInvoiceAddress.company
                                }}
                                <br />
                                {{
                                    booking.details.invoice
                                        .differentInvoiceAddress.addressLine1
                                }}
                                <br />
                                {{
                                    booking.details.invoice
                                        .differentInvoiceAddress.postCode
                                }}
                                {{
                                    booking.details.invoice
                                        .differentInvoiceAddress.city
                                }}
                                <br />
                                <span
                                    *ngLet="
                                        booking.details.invoice
                                            .differentInvoiceAddress
                                            .mail as mail
                                    "
                                >
                                    E-Mail:
                                    <a href="mailto:{{ mail }}">{{ mail }}</a>
                                </span>
                            </div>
                            <ng-template #customerAddress>
                                <div>
                                    {{
                                        booking.details.invoice
                                            .customerSalutation
                                    }}
                                    {{ booking.details.invoice.customerTitle }}
                                    {{
                                        booking.details.invoice
                                            .customerFirstName
                                    }}
                                    {{
                                        booking.details.invoice.customerLastName
                                    }}
                                    <br />
                                    {{
                                        booking.details.invoice
                                            .customerAddressLine1
                                    }}
                                    <br />
                                    {{
                                        booking.details.invoice.customerPostCode
                                    }}
                                    {{ booking.details.invoice.customerCity }}
                                    <br />
                                    <span
                                        *ngLet="
                                            booking.details.invoice
                                                .customerMail as mail
                                        "
                                    >
                                        E-Mail:
                                        <a href="mailto:{{ mail }}">{{
                                            mail
                                        }}</a>
                                    </span>
                                </div>
                            </ng-template>
                        </div>
                        <ng-template #noAddress>
                            <div>Keine Rechnungsadresse vorhanden</div>
                        </ng-template>
                    </div>
                    <div class="p-d-flex p-flex-column p-jc-end p-ai-stretch">
                        <div
                            *ngIf="
                                booking.listData.product ==
                                CourseType.EducationCourse
                            "
                            class="p-d-flex p-flex-row p-jc-between p-ai-center p-mb-2"
                        >
                            <label
                                for="done"
                                class="c-cursor-pointer p-checkbox-label p-mr-3"
                                >Abgeschlossen:
                            </label>
                            <p-inputSwitch
                                [(ngModel)]="booking.listData.completed"
                                (click)="$event.stopPropagation()"
                                (onChange)="
                                    emitUpdateCompleted(
                                        booking.listData.id,
                                        $event
                                    )
                                "
                                inputId="done"
                            ></p-inputSwitch>
                        </div>
                        <div
                            class="p-d-flex p-flex-row p-jc-between p-ai-center p-mb-2"
                        >
                            <label
                                for="done"
                                class="c-cursor-pointer p-checkbox-label p-mr-3"
                                >Teilnehmer sperren:
                            </label>
                            <p-inputSwitch
                                [(ngModel)]="booking.details.isBlocked"
                                (click)="$event.stopPropagation()"
                                (onChange)="
                                    emitBlockParticipation(
                                        booking.listData.id,
                                        $event
                                    )
                                "
                                inputId="done"
                            ></p-inputSwitch>
                        </div>
                        <button
                            *ngIf="
                                booking.listData.product ===
                                CourseType.EducationCourse
                            "
                            type="button"
                            pButton
                            icon="pi pi-download"
                            (click)="
                                emitDownloadCertificate(
                                    booking.listData.id,
                                    $event
                                )
                            "
                            class="p-button-secondary"
                            label="Zertifikat herunterladen"
                            [disabled]="
                                !booking.listData.completed ||
                                booking.listData.exams.length === 0
                            "
                        ></button>
                        <button
                            *ngIf="
                                booking.listData.product ===
                                CourseType.EducationCourse
                            "
                            type="button"
                            pButton
                            icon="pi pi-plus"
                            (click)="
                                openBookExtensionDialog(booking.listData.id)
                            "
                            class="p-button-secondary p-mt-2"
                            label="Verlängerung buchen"
                        ></button>
                    </div>
                </div>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-dialog
    [(visible)]="bookExtensionDialogVisible"
    appendTo="body"
    [style]="{ minWidth: '320px' }"
    [dismissableMask]="true"
>
    <ng-template pTemplate="header">
        <h3>Verlängerung buchen</h3>
    </ng-template>
    <ng-template pTemplate="content">
        <form [formGroup]="bookExtensionForm">
            <p-dropdown
                [options]="bookExtensionOptions"
                styleClass="c-width-100"
                optionLabel="label"
                optionValue="value"
                formControlName="duration"
                appendTo="body"
            ></p-dropdown>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button
            pButton
            type="button"
            label="Speichern"
            icon="pi pi-save"
            class="p-button-primary p-button-primary p-mr-0"
            (click)="saveBookExtension($event)"
        ></button>
    </ng-template>
</p-dialog>
<p-dialog
    [(visible)]="addReadingTimeDialogVisible"
    appendTo="body"
    [style]="{ minWidth: '320px' }"
    [dismissableMask]="true"
>
    <ng-template pTemplate="header">
        <h3>Lesezeit erfassen</h3>
    </ng-template>
    <ng-template pTemplate="content">
        <form [formGroup]="addReadingTimeForm">
            <table>
                <tr>
                    <td><label for="readingDate">Lesedatum</label></td>
                    <td>
                        <p-calendar
                            inputId="readingDate"
                            formControlName="date"
                            [firstDayOfWeek]="1"
                            dateFormat="dd.mm.yy"
                            required="true"
                            [showIcon]="true"
                            appendTo="body"
                        ></p-calendar>
                    </td>
                </tr>
                <tr>
                    <td><label for="readingTime">Lesezeit</label></td>
                    <td>
                        <p-calendar
                            [timeOnly]="true"
                            inputId="readingTime"
                            formControlName="time"
                            hourFormat="24"
                            required="true"
                            [showIcon]="true"
                            appendTo="body"
                        ></p-calendar>
                    </td>
                </tr>
            </table>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button
            pButton
            type="button"
            class="p-button-primary p-button-raised p-mr-0"
            icon="pi pi-save"
            label="Speichern"
            (click)="saveReadingTime($event)"
        ></button>
    </ng-template>
</p-dialog>
