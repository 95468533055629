import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UUID } from '../../../../../../../../../shared/generic-types/src';
import { ExamStatus } from '../../../../../../../shared/interfaces/src/lib/data-management/exam/exam-participation';
import { ExamStatePipe } from '../../pipes';
import { CustomerDetailsExamParticipation } from '../../../../../../../shared/interfaces/src';
import {
    ChangeExamStatusEvent,
    DeleteExamParticipationEvent,
} from '../../model';

@Component({
    selector: 'cna-customer-exams',
    templateUrl: './customer-exams.component.html',
    styleUrls: ['./customer-exams.component.scss'],
})
export class CustomerExamsComponent {
    @Input() exams: CustomerDetailsExamParticipation[];

    @Output() changeStatus = new EventEmitter<ChangeExamStatusEvent>();
    @Output() deleteParticipation =
        new EventEmitter<DeleteExamParticipationEvent>();

    editExamDialogVisible = false;

    examStatusOptions = Object.values(ExamStatus).map(examStatus => ({
        value: examStatus,
        label: this.examStatusPipe.transform(examStatus),
    }));

    editExamParticipationForm = new FormGroup({
        examParticipationId: new FormControl(),
        status: new FormControl(),
    });

    constructor(private examStatusPipe: ExamStatePipe) {}

    openEditExamParticipationOverlay(
        examParticipationId: UUID,
        currentStatus: ExamStatus
    ): void {
        this.editExamParticipationForm.patchValue({
            examParticipationId,
            status: currentStatus,
        });
        this.editExamDialogVisible = true;
    }

    saveExamParticipation(event: Event): void {
        const { examParticipationId, status } =
            this.editExamParticipationForm.getRawValue();
        this.changeStatus.emit({
            examParticipationId,
            status,
            originalEvent: event,
        });
        this.editExamDialogVisible = false;
    }

    deleteExamParticipation(examParticipationId: UUID, event: Event): void {
        this.deleteParticipation.emit({
            examParticipationId,
            originalEvent: event,
        });
    }
}
