import { CourseBooking, isCourseBooking } from './course-booking';
import { CourseType } from '../../../../../../../shared/interfaces/src';

export class TrainingCourseBooking extends CourseBooking {}

export function isTrainingCourseBooking(
    trainingCourseBooking: any
): trainingCourseBooking is TrainingCourseBooking {
    return (
        isCourseBooking(trainingCourseBooking) &&
        trainingCourseBooking.courseType === CourseType.TrainingCourse
    );
}
