import { FormControl } from '@angular/forms';
import { identity, Observable } from 'rxjs';
import { debounceTime, map, startWith } from 'rxjs/operators';

export abstract class Filter<InputType, FilterType> {
    protected readonly formControl: FormControl;
    private readonly value$: Observable<FilterType>;

    protected constructor(private defaultValue: InputType, debounce = false) {
        this.formControl = new FormControl(defaultValue);

        this.value$ = this.formControl.valueChanges.pipe(
            startWith(defaultValue),
            debounce ? debounceTime(1000) : identity,
            map(this.filterValueFromInputValue)
        );
    }

    async reset(): Promise<void> {
        this.formControl.patchValue(this.defaultValue);
    }

    async set(value: FilterType): Promise<void> {
        this.formControl.patchValue(this.inputValueFromFilterValue(value));
    }

    getFormControl(): FormControl {
        return this.formControl;
    }

    getObservableValue(): Observable<FilterType> {
        return this.value$;
    }

    protected abstract filterValueFromInputValue(
        inputValue: InputType
    ): FilterType;

    protected abstract inputValueFromFilterValue(
        filterValue: FilterType
    ): InputType;
}
