import { CourseBooking, isCourseBooking } from './course-booking';
import { CourseType } from '../../../../../../../shared/interfaces/src';

export class EducationCourseBooking extends CourseBooking {
    instalment: boolean;
}

export function isEducationCourseBooking(
    educationCourseBooking: any
): educationCourseBooking is EducationCourseBooking {
    return (
        isCourseBooking(educationCourseBooking) &&
        educationCourseBooking.courseType === CourseType.EducationCourse
    );
}
