import { Route } from '@angular/router';
import { ListCustomerComponent } from './components/list-customer/list-customer.component';

export const customerManagementRoutes: Route[] = [
    {
        path: 'customer',
        component: ListCustomerComponent,
    },
    {
        path: 'customer/list',
        component: ListCustomerComponent,
    },
];
