import { AkJuraBackofficeConfig } from './environment.interface';
import { sharedEnvironment } from './environment.shared';
import { InteractionType, LogLevel } from '@azure/msal-browser';

const apiHost = 'https://api.ak-jura.de/api';
export const environment: AkJuraBackofficeConfig = {
    ...sharedEnvironment,
    production: true,
    apiHost: apiHost,
    msalConfiguration: {
        auth: {
            clientId: 'b8384ac9-9080-40c4-b710-a1f5ce8d9e3f',
            authority:
                'https://akjura.b2clogin.com/akjura.onmicrosoft.com/B2C_1_login',
            knownAuthorities: ['akjura.b2clogin.com'],
            navigateToLoginRequestUrl: false,
            postLogoutRedirectUri: 'https://backoffice.ak-jura.de',
            redirectUri: 'https://backoffice.ak-jura.de',
        },
    },
    passwordResetAuthority:
        'https://akjura.b2clogin.com/akjura.onmicrosoft.com/B2C_1_reset',
    msalGuardConfiguration: {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: ['https://akjura.onmicrosoft.com/webapi/Admin'],
        },
    },
    msalInterceptorConfiguration: {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
            [`${apiHost}\*`, ['https://akjura.onmicrosoft.com/webapi/Admin']],
        ]),
    },
};
