import { createAction, props } from '@ngrx/store';
import type { UUID } from '../../../../../../../../../shared/generic-types/src';
import { CustomerListData } from '../../../../../../../shared/interfaces/src';
import {
    CustomerListFilter,
    CustomerListQuery,
    CustomerListFilterValues,
} from '../../model';
import { Dictionary } from '../../utils';

export const SET_QUERY = createAction(
    '[CustomerList] Set Query',
    props<{ query: CustomerListQuery }>()
);

export const SET_FILTER = createAction(
    '[CustomerList] Set Filter',
    props<{ filter: CustomerListFilter }>()
);

export const LOAD_SUBS = createAction('[CustomerList] Load all subs');

export const LOAD_SUBS_SUCCESS = createAction(
    '[CustomerList] Load all subs success',
    props<{ subs: UUID[] }>()
);

export const LOAD_SUBS_FAILURE = createAction(
    '[CustomerList] Load all subs failure',
    props<{ error: Error }>()
);

export const LOAD_LIST_DATA = createAction(
    '[CustomerList] Load list data',
    props<{ subs: string[] }>()
);

export const LOAD_LIST_DATA_SUCCESS = createAction(
    '[CustomerList] Load list data success',
    props<{ customers: Dictionary<CustomerListData> }>()
);

export const LOAD_LIST_DATA_FAILURE = createAction(
    '[CustomerList] Load list data failure',
    props<{ error: Error }>()
);

export const LOAD_FILTER_VALUES = createAction(
    '[CustomerList] Load filter values'
);

export const LOAD_FILTER_VALUES_SUCCESS = createAction(
    '[CustomerList] Load filter values success',
    props<{ filterValues: CustomerListFilterValues }>()
);

export const LOAD_FILTER_VALUES_FAILURE = createAction(
    '[CustomerList] Load filter values failure',
    props<{ error: Error }>()
);

export const TOGGLE_SELECT = createAction(
    '[CustomerList] Toggle select for list item',
    props<{ sub: UUID }>()
);

export const SELECT_ALL = createAction('[CustomerList] Select all list items');

export const DESELECT_ALL = createAction(
    '[CustomerList] Deselect all list items'
);

export const SEND_MAIL_TO_SELECTED = createAction(
    '[CustomerList] Send mail to all selected'
);

export const SEND_MAIL_TO_SELECTED_SUCCESS = createAction(
    '[CustomerList] Send mail to selected success'
);

export const SEND_MAIL_TO_SELECTED_FAILURE = createAction(
    '[CustomerList] Send mail to selected failure',
    props<{ error: Error }>()
);

export const DOWNLOAD_USER_EXPORT = createAction(
    '[CustomerList] Download user export'
);

export const DOWNLOAD_USER_EXPORT_SUCCESS = createAction(
    '[CustomerList] Download user export success'
);

export const DOWNLOAD_USER_EXPORT_FAILURE = createAction(
    '[CustomerList] Download user export failure'
);
