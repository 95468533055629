<div>
    <div class="p-grid">
        <p-table [value]="transactions" [scrollable]="true" [autoLayout]="true">
            <ng-template pTemplate="header">
                <tr>
                    <th>Datum</th>
                    <th>Typ</th>
                    <th>Produkt</th>
                    <th>Rechtsgebiet</th>
                    <th>Betrag</th>
                    <th>Rechnungsnummer</th>
                    <th>Bezahlt</th>
                    <th>Aktionen</th>
                </tr>
                <tr>
                    <th colspan="7">
                        <span class="p-text-bold">
                            Bilanz: {{ balance / 100 | currency: 'EUR' }}
                        </span>
                    </th>
                    <th>
                        <button
                            [disabled]="invoiceOptions.length === 0"
                            pButton
                            type="button"
                            class="p-button-secondary p-button-raised"
                            icon="pi pi-plus"
                            label="Zahlung erfassen"
                            (click)="openAddPaymentModal()"
                        ></button>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <p>
                    Dieser Nutzer hat bisher weder Buchungen noch Zahlungen
                    getätigt.
                </p>
            </ng-template>
            <ng-template pTemplate="body" let-transaction>
                <tr>
                    <td>
                        {{ transaction.date | prettifyDateString }}
                    </td>
                    <td>
                        {{ transaction.transactionType | transactionType }}
                    </td>
                    <td>
                        <span
                            *ngIf="
                                transaction.transactionType ===
                                TransactionType.BOOKING
                            "
                            >{{
                                transaction.bookingType
                                    | bookingType
                                        : transaction.courseType
                                        : transaction.isTest
                                        : false
                            }}</span
                        >
                    </td>
                    <td>
                        {{ transaction.legalAreaCode }}
                    </td>
                    <td>
                        <span>{{
                            transaction.amount / 100 | currency: 'EUR'
                        }}</span>
                    </td>
                    <td>
                        <a
                            *ngIf="transaction.invoice"
                            (click)="
                                emitDownloadInvoice(
                                    transaction.invoice,
                                    transaction.bookingType,
                                    transaction.invoice?.isCorrection || false,
                                    $event
                                )
                            "
                            class="p-link download-link"
                        >
                            {{ transaction.invoice | invoiceNumber }}
                        </a>
                        <ng-container *ngIf="transaction.invoiceCorrections">
                            <a
                                *ngFor="
                                    let correction of transaction.invoiceCorrections
                                "
                                (click)="
                                    emitDownloadInvoice(
                                        correction,
                                        transaction.bookingType,
                                        true,
                                        $event
                                    )
                                "
                                class="p-link download-link"
                            >
                                {{ correction | invoiceNumber }}
                            </a>
                        </ng-container>
                    </td>
                    <td>
                        <p-inputSwitch
                            *ngIf="
                                transaction.transactionType ===
                                    TransactionType.BOOKING &&
                                transaction.invoice
                            "
                            [ngModel]="transaction.isPaid"
                            (onChange)="emitUpdateIsPaid(transaction, $event)"
                        ></p-inputSwitch>
                    </td>
                    <td>
                        <span
                            style="
                                display: block;
                                height: 2.357rem;
                                min-width: 1px;
                            "
                        >
                            <button
                                *ngIf="
                                    transaction.transactionType ===
                                    TransactionType.PAYMENT
                                "
                                pButton
                                class="p-button-rounded p-button-outlined p-button-danger"
                                icon="pi pi-trash"
                                (click)="openDeletePaymentModal(transaction)"
                            ></button>
                            <button
                                *ngIf="
                                    transaction.transactionType ===
                                        TransactionType.BOOKING &&
                                    transaction.bookingType ===
                                        BookingType.COURSE &&
                                    transaction.courseType ===
                                        CourseType.EducationCourse &&
                                    !transaction.isPaid &&
                                    transaction.invoice
                                "
                                pButton
                                class="p-button-rounded p-button-outlined p-button-info"
                                icon="pi pi-pencil"
                                pTooltip="Rechnungskorrektur erstellen"
                                tooltipPosition="bottom"
                                (click)="openCorrectInvoiceModal(transaction)"
                            ></button>
                        </span>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
<p-dialog
    appendTo="body"
    [(visible)]="addPaymentDialogVisible"
    [style]="{ minWidth: '320px' }"
    dismissableMask="true"
>
    <ng-template pTemplate="header">
        <h3>Zahlung erfassen</h3>
    </ng-template>
    <ng-template pTemplate="content">
        <form [formGroup]="addPaymentForm">
            <table>
                <tr>
                    <td><label for="readingDate">Lesedatum</label></td>
                    <td>
                        <p-calendar
                            inputId="readingDate"
                            formControlName="date"
                            [firstDayOfWeek]="1"
                            dateFormat="dd.mm.yy"
                            required="true"
                            [showIcon]="true"
                            appendTo="body"
                        ></p-calendar>
                    </td>
                </tr>
                <tr>
                    <td><label for="value">Betrag</label></td>
                    <td>
                        <p-inputNumber
                            id="value"
                            [minFractionDigits]="2"
                            [maxFractionDigits]="5"
                            formControlName="amount"
                            suffix=" €"
                            styleClass="c-width-100"
                        ></p-inputNumber>
                    </td>
                </tr>
                <tr>
                    <td><label for="invoice">Rechnung</label></td>
                    <td>
                        <p-dropdown
                            [options]="invoiceOptions"
                            optionValue="value"
                            optionLabel="label"
                            styleClass="c-width-100"
                            appendTo="body"
                            formControlName="invoice"
                        ></p-dropdown>
                    </td>
                </tr>
            </table>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button
            pButton
            type="button"
            class="p-button-raised p-button-primary"
            icon="pi pi-save"
            label="Speichern"
            (click)="emitAddPayment($event)"
        ></button>
    </ng-template>
</p-dialog>
<p-confirmDialog
    [visible]="deletePaymentDialogVisible"
    header="Zahlung löschen"
    icon="pi pi-exclamation-triangle"
    message="Soll diese Zahlung wirklich gelöscht werden?"
    acceptLabel="Ja"
    rejectLabel="Nein"
    (onHide)="emitDeletePayment($event)"
></p-confirmDialog>

<p-dialog
    appendTo="body"
    [(visible)]="correctInvoiceDialogVisible"
    [style]="{ minWidth: '320px' }"
    dismissableMask="true"
>
    <ng-template pTemplate="header">
        <h3>Rechnung korrigieren</h3>
    </ng-template>
    <ng-template pTemplate="content">
        <form [formGroup]="correctInvoiceForm">
            <table>
                <tr>
                    <td><label for="value">Neuer Betrag</label></td>
                    <td>
                        <p-inputNumber
                            id="total-amount"
                            [minFractionDigits]="2"
                            [maxFractionDigits]="5"
                            formControlName="totalAmount"
                            suffix=" €"
                            styleClass="c-width-100"
                        ></p-inputNumber>
                    </td>
                </tr>
                <!--                <tr>-->
                <!--                    <td><label for="invoice">Rechnung</label></td>-->
                <!--                    <td>-->
                <!--                        <p-dropdown-->
                <!--                                [options]="invoiceOptions"-->
                <!--                                optionValue="value"-->
                <!--                                optionLabel="label"-->
                <!--                                styleClass="c-width-100"-->
                <!--                                appendTo="body"-->
                <!--                                formControlName="invoice"-->
                <!--                        ></p-dropdown>-->
                <!--                    </td>-->
                <!--                </tr>-->
            </table>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button
            pButton
            type="button"
            class="p-button-raised p-button-primary"
            icon="pi pi-save"
            label="Speichern"
            (click)="emitCorrectInvoice($event)"
        ></button>
    </ng-template>
</p-dialog>
