<div *ngIf="!(loading$ | async); else loading">
    <div *ngIf="customer$ | async as customer; else noCustomerSelected">
        <p-tabView [scrollLeft]="true" [(activeIndex)]="activeTab">
            <p-tabPanel header="Grunddaten">
                <cna-customer-details
                    [customer]="customer"
                    (changeAnnotation)="onChangeAnnotation($event)"
                ></cna-customer-details>
            </p-tabPanel>
            <p-tabPanel header="Buchungen">
                <cna-customer-bookings
                    [bookings]="bookings$ | async"
                    (downloadCertificate)="onDownloadCertificate($event)"
                    (updateCompleted)="onCompleteParticipation($event)"
                    (addReadingTime)="onAddReadingTime($event)"
                    (updateBlockParticipation)="
                        onUpdateBlockCourseParticipation($event)
                    "
                    (bookExtension)="onBookExtension($event)"
                ></cna-customer-bookings>
            </p-tabPanel>
            <p-tabPanel header="Konto">
                <cna-customer-payments
                    [transactions]="transactions$ | async"
                    [balance]="customer.listData.balance"
                    (addPayment)="onAddPayment($event)"
                    (deletePayment)="onDeletePayment($event)"
                    (downloadInvoice)="onDownloadInvoice($event)"
                    (updateIsPaid)="onUpdateIsPaid($event)"
                    (triggerPaymentRequest)="onTriggerPaymentRequest($event)"
                    (correctInvoice)="onCorrectInvoice($event)"
                ></cna-customer-payments>
            </p-tabPanel>
            <p-tabPanel header="Prüfungen">
                <cna-customer-exams
                    [exams]="customer.details.bookedExams"
                    (changeStatus)="onChangeExamStatus($event)"
                    (deleteParticipation)="onDeleteExamParticipation($event)"
                ></cna-customer-exams>
            </p-tabPanel>
            <p-tabPanel header="Lernzeiten">
                <cna-customer-reading-times
                    [courseParticipations]="
                        customer.details.courseParticipations
                    "
                ></cna-customer-reading-times>
            </p-tabPanel>
        </p-tabView>
    </div>
    <ng-template #noCustomerSelected>
        Es konnte kein Kunde geladen werden.
    </ng-template>
</div>
<ng-template #loading>
    <div class="p-d-flex p-ai-center p-jc-center c-width-100 c-height-100">
        <p-progressSpinner></p-progressSpinner>
    </div>
</ng-template>
