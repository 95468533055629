<div class="p-grid" style="background-color: #ffffff">
    <div class="p-col-1">
        <img
            *ngIf="showLogo"
            src="https://gnasandbox.blob.core.windows.net/daniel/ostfriesische_inseln_logo.png"
            style="width: 59px; height: 53px"
        />
    </div>
    <div class="p-offset-6 p-col-5">
        <div style="float: right">
            <button
                *ngIf="userIsLoggedIn && showReservationsListButton"
                pButton
                type="button"
                icon="pi pi-list"
                label="Meine Reservierungen"
                class="p-button-outlined p-button-raised p-button-sm p-button-info p-mr-1"
                (click)="showReservationsClicked()"
                style="height: 30px"
            ></button>

            <button
                *ngIf="userIsLoggedIn"
                pButton
                type="button"
                icon="pi pi-key"
                label="Passwort ändern"
                class="p-button-outlined p-button-raised p-button-sm p-button-info p-mr-1"
                (click)="changePasswordClicked()"
                style="height: 30px"
            ></button>

            <button
                *ngIf="userIsLoggedIn"
                pButton
                type="button"
                icon="pi pi-sign-out"
                label="Logout"
                class="p-button-outlined p-button-raised p-button-sm p-button-info p-ml-1"
                (click)="logoutClicked()"
                style="height: 30px"
            ></button>
            <button
                *ngIf="!userIsLoggedIn"
                pButton
                type="button"
                icon="pi pi-sign-out"
                label="Login / Registrieren"
                class="p-button-outlined p-button-raised p-button-sm p-button-info p-ml-1"
                (click)="loginClicked()"
                style="height: 30px"
            ></button>
        </div>
    </div>
</div>
