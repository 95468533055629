import { ValidatorFn } from '@angular/forms';
import { Filter } from './filter';

export class StringFilter extends Filter<string | undefined, string | null> {
    constructor(validators?: ValidatorFn | ValidatorFn[]) {
        super(null, true);

        if (validators) {
            this.formControl.setValidators(validators);
        }
    }

    protected inputValueFromFilterValue(
        filterValue: string | undefined
    ): string | null {
        if (filterValue === undefined) {
            return null;
        }
        return filterValue;
    }

    protected filterValueFromInputValue(
        inputValue: string | null
    ): string | undefined {
        if (inputValue === null) {
            return undefined;
        }
        return inputValue;
    }
}
