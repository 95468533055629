<table>
    <tr>
        <td>Account ID</td>
        <td>{{ customer.listData.accountId }}</td>
    </tr>
    <tr>
        <td>Anrede</td>
        <td>{{ customer.listData.salutation || '-' }}</td>
    </tr>
    <tr>
        <td>Titel</td>
        <td>{{ customer.listData.title || '-' }}</td>
    </tr>
    <tr>
        <td>Name</td>
        <td class="highlight">
            {{ customer.listData.firstName }}
            {{ customer.listData.lastName }}
        </td>
    </tr>
    <tr>
        <td>Anschrift</td>
        <td class="highlight">
            {{ customer.listData.address.addressLine1 || '-' }}
        </td>
    </tr>
    <tr>
        <td>PLZ/ Ort</td>
        <td class="highlight">
            {{ customer.listData.address.postCode || '-' }}
            {{ customer.listData.address.city || '-' }}
        </td>
    </tr>
    <tr>
        <td>E-Mail</td>
        <td>
            <a
                *ngIf="customer.listData.email"
                href="mailto:{{ customer.listData.email }}"
                >{{ customer.listData.email }}</a
            >
            <span *ngIf="!customer.listData.email"> - </span>
        </td>
    </tr>
    <tr>
        <td>Telefon</td>
        <td>{{ customer.listData.phone || '-' }}</td>
    </tr>
    <tr>
        <td>Newsletter</td>
        <td>{{ customer.listData.newsletter ? 'ja' : 'nein' }}</td>
    </tr>
    <tr>
        <td>Anmerkung</td>
        <td [style]="{ maxWidth: '20rem', overflowWrap: 'break-word' }">
            {{ customer.details.annotation || '-' }}
        </td>
        <span class="p-ml-2">
            <button
                pButton
                icon="pi pi-pencil"
                class="p-button-rounded p-button-secondary p-button-outlined"
                (click)="openUpdateAnnotationOverlay($event)"
            ></button>
            <button
                pButton
                icon="pi pi-trash"
                class="p-button-rounded p-button-danger p-button-outlined p-ml-2"
                (click)="emitDeleteAnnotation($event)"
            ></button>
        </span>
    </tr>
</table>
<p-dialog
    [(visible)]="changeAnnotationDialogVisible"
    appendTo="body"
    [dismissableMask]="true"
    [style]="{ minWidth: '250px' }"
>
    <ng-template pTemplate="header">
        <h3>Anmerkung</h3>
    </ng-template>
    <ng-template pTemplate="content">
        <form
            [formGroup]="updateAnnotationForm"
            class="p-d-flex p-flex-column p-ai-stretch c-height-100"
        >
            <textarea
                pInputTextarea
                rows="5"
                formControlName="annotation"
                class="p-flex c-flex-grow-1"
                [style]="{ resize: 'none' }"
            ></textarea>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button
            pButton
            (click)="updateAnnotation($event)"
            icon="pi pi-save"
            label="Speichern"
            class="p-button p-button-primary p-button-raised"
        ></button>
    </ng-template>
</p-dialog>
