import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DefaultDataServiceConfig, EntityDataModule } from '@ngrx/data';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CascadeSelectModule } from 'primeng/cascadeselect';
import { StartPageComponent } from './start-page/start-page.component';
import { AppComponent } from './app.component';
import { entityConfig } from './+state/entity-metadata';
import { GLOBAL_STORE_NAME, globalReducer } from './+state/reducer';
import { environment } from '../environments/environment';
import { AngularUiStatusBarModule } from '@cna/angular/ui/status-bar';
import {
    MsalInterceptor,
    MsalModule,
    MsalRedirectComponent,
    MsalService,
} from '@azure/msal-angular';
import { backofficeRoutes } from './routes';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GlobalEffects } from './+state/effects';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { DataServicesModule } from '@cna/projects/ak-jura/angular/data-services';

import { DatePipe, registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { CanDeactivateGuard } from '../../../../../../libs/angular/ui/confirm-before-leave/can-deactivate-guard.service';
import { AngularPrimeNgSettingsModule } from '@cna/angular/prime-ng-settings';
import {
    BrowserCacheLocation,
    PublicClientApplication,
} from '@azure/msal-browser';
import { SectionNumberingPipe } from '../../../../../../libs/angular/ui/pipes/src/lib/section-numbering.pipe';
import { SectionTitlePipe } from '../../../../../../libs/angular/ui/pipes/src/lib/section-title.pipe';
import { NumberToNumberingPipe } from '../../../../../../libs/angular/ui/pipes/src/lib/number-to-numbering.pipe';
import { SecondsToHoursPipe } from '../../../../../../libs/angular/ui/pipes/src/lib/seconds-to-hours.pipe';
import { PrettifyDateString } from '../../../../../../libs/angular/ui/pipes/src/lib/pipes/pretify-date-time-strings';
import { CustomerManagementModule } from '@cna/projects/ak-jura/angular/backoffice/customer-management';

registerLocaleData(localeDe, localeDeExtra);

const isIE =
    window.navigator.userAgent.indexOf('MSIE ') > -1 ||
    window.navigator.userAgent.indexOf('Trident/') > -1;
const isIframe = window !== window.parent && !window.opener;

@NgModule({
    declarations: [AppComponent, StartPageComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ButtonModule,
        MsalModule.forRoot(
            new PublicClientApplication({
                ...environment.msalConfiguration,
                cache: {
                    cacheLocation: BrowserCacheLocation.LocalStorage,
                    storeAuthStateInCookie: isIE,
                },
            }),
            environment.msalGuardConfiguration,
            environment.msalInterceptorConfiguration
        ),
        HttpClientModule,
        RouterModule.forRoot(backofficeRoutes, {
            initialNavigation: !isIframe ? 'enabled' : 'disabled',
            relativeLinkResolution: 'legacy',
        }),
        StoreModule.forRoot({
            [GLOBAL_STORE_NAME]: globalReducer,
        }),
        EffectsModule.forRoot([GlobalEffects]),
        EntityDataModule.forRoot(entityConfig),
        TieredMenuModule,
        AngularUiStatusBarModule,
        ProgressSpinnerModule,
        CascadeSelectModule,
        ToastModule,
        DataServicesModule,
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            name: 'AK JURA Backoffice', // Retains last 25 states
        }),
        CustomerManagementModule,
    ],
    providers: [
        {
            provide: DefaultDataServiceConfig,
            useValue: {
                root: environment.apiHost,
            },
        },
        {
            provide: LOCALE_ID,
            useValue: 'de',
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true,
        },
        MsalService,
        MessageService,
        CanDeactivateGuard,
        DatePipe,
        AngularPrimeNgSettingsModule,
        SectionNumberingPipe,
        SectionTitlePipe,
        SecondsToHoursPipe,
        NumberToNumberingPipe,
        PrettifyDateString,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
