import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
    CustomerDetails,
    CustomerListData,
} from '../../../../../../../shared/interfaces/src';
import { ChangeAnnotationEvent } from '../../model';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'cna-customer-details',
    templateUrl: './customer-details.component.html',
    styleUrls: ['./customer-details.component.scss'],
})
export class CustomerDetailsComponent {
    @Input() customer: {
        listData: CustomerListData;
        details: CustomerDetails;
    };

    @Output() changeAnnotation = new EventEmitter<ChangeAnnotationEvent>();

    changeAnnotationDialogVisible = false;

    updateAnnotationForm = new FormGroup({
        id: new FormControl(null),
        sub: new FormControl(null),
        annotation: new FormControl(null),
    });

    async openUpdateAnnotationOverlay(event: Event): Promise<void> {
        this.updateAnnotationForm.patchValue({
            id: this.customer.listData.id,
            sub: this.customer.listData.sub,
            annotation: this.customer.listData.annotation,
        });
        this.changeAnnotationDialogVisible = true;
    }

    async updateAnnotation(event: Event): Promise<void> {
        const formData = this.updateAnnotationForm.getRawValue();
        this.changeAnnotation.emit({
            id: formData.id,
            sub: formData.sub,
            annotation: formData.annotation,
            originalEvent: event,
        });
        this.changeAnnotationDialogVisible = false;
    }

    emitDeleteAnnotation(event: MouseEvent) {
        this.changeAnnotation.emit({
            id: this.customer.listData.id,
            sub: this.customer.listData.sub,
            annotation: null,
            originalEvent: event,
        });
    }
}
