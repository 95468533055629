import { Pipe, PipeTransform } from '@angular/core';
import { Transaction } from '../model';

@Pipe({ name: 'invoiceNumber' })
export class InvoiceNumberPipe implements PipeTransform {
    transform(invoice: Transaction['invoice']): string {
        return (
            invoice.prefix +
            '-' +
            invoice.number +
            (invoice.suffix ? '-' + invoice.suffix : '')
        );
    }
}
