import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CustomerDetailsCourseParticipation } from '../../../../../../../shared/interfaces/src';
import { CustomerService } from '../../services/customer.service';
import * as _ from 'lodash';

@Component({
    selector: 'cna-customer-reading-times',
    templateUrl: './customer-reading-times.component.html',
    styleUrls: ['./customer-reading-times.component.scss'],
})
export class CustomerReadingTimesComponent implements OnInit {
    @Input()
    courseParticipations: CustomerDetailsCourseParticipation[];

    constructor(private readonly customerService: CustomerService) {}

    timeExpenditures$: Observable<
        {
            courseParticipation: CustomerDetailsCourseParticipation;
            months: {
                month: Date;
                timeExpenditures: { duration: number; date: Date }[];
                duration: number;
            }[];
            duration: number;
        }[]
    >;

    ngOnInit(): void {
        this.timeExpenditures$ = this.customerService
            .getTimeExpenditure(this.courseParticipations?.map(c => c.id) ?? [])
            .pipe(
                map(timeExpenditures =>
                    _.map(
                        _.map(
                            _.map(
                                _.groupBy(
                                    _.map(timeExpenditures, te => ({
                                        ...te,
                                        date: te.lastRead.substr(0, 10),
                                        month: te.lastRead.substr(0, 7),
                                    })),
                                    'courseParticipationId'
                                ),
                                (
                                    timeExpendituresDir,
                                    courseParticipationId
                                ) => ({
                                    courseParticipation:
                                        this.courseParticipations.find(
                                            cp =>
                                                cp.id === courseParticipationId
                                        ),
                                    months: _.sortBy(
                                        _.map(
                                            _.groupBy(
                                                timeExpendituresDir,
                                                'month'
                                            ),
                                            (monthsDir, month) => ({
                                                month: new Date(month),
                                                timeExpenditures: _.sortBy(
                                                    _.map(
                                                        _.groupBy(
                                                            monthsDir,
                                                            'date'
                                                        ),
                                                        (value, date) => ({
                                                            duration: _.reduce(
                                                                value,
                                                                (sum, te) =>
                                                                    (sum +=
                                                                        te.duration),
                                                                0
                                                            ),
                                                            date: new Date(
                                                                date
                                                            ),
                                                        })
                                                    ),
                                                    'date'
                                                ),
                                            })
                                        ),
                                        'month'
                                    ),
                                })
                            ),
                            item => ({
                                ...item,
                                months: _.map(item.months, month => ({
                                    ...month,
                                    duration: _.reduce(
                                        month.timeExpenditures,
                                        (sum, te) => (sum += te.duration),
                                        0
                                    ),
                                })),
                            })
                        ),
                        item => ({
                            ...item,
                            duration: _.reduce(
                                item.months,
                                (sum, month) => (sum += month.duration),
                                0
                            ),
                        })
                    )
                )
            );
    }

    protected readonly takeUntil = takeUntil;
}
