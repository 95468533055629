import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { PrettifyDateString } from '../../../../../../../../../angular/ui/pipes/src/lib/pipes/pretify-date-time-strings';
import { EnvironmentService } from '../../../../../../../../../angular/services/src/lib/environment/environment.service';
import { Store } from '@ngrx/store';
import { LazyLoadEvent } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CustomerModalComponent } from '../customer-modal/customer-modal.component';
import { Subject } from 'rxjs';
import { filter, map, withLatestFrom } from 'rxjs/operators';
import {
    CustomerListSelectors,
    CustomerListActions,
} from '../../+state/customer-list';
import {
    CustomerListData,
    CustomerListDataCourse,
    CustomerListDataExam,
} from '../../../../../../../shared/interfaces/src';
import { CustomerListQuery, FilterFields, BookingType } from '../../model';
import { UUID } from '../../../../../../../../../shared/generic-types/src';
import { FilterService } from '../../services/filter.service';
import { LatestPipe } from '../../pipes/latest.pipe';

@Component({
    selector: 'cna-list-customer',
    templateUrl: './list-customer.component.html',
    styleUrls: ['./list-customer.component.css'],
    encapsulation: ViewEncapsulation.None,
    providers: [DialogService],
})
export class ListCustomerComponent implements OnInit, OnDestroy {
    customers$ = this.store.select(CustomerListSelectors.GET_CURRENT_LIST).pipe(
        filter(customers => !customers.includes(undefined)),
        withLatestFrom(this.store.select(CustomerListSelectors.GET_FILTER)),
        map(([customers, currentFilter]) =>
            this.filterService.applyFiltersLocal(customers, currentFilter)
        ),
        withLatestFrom(this.store.select(CustomerListSelectors.GET_SELECTED)),
        map(([customers, selected]) => this.mapForRowSpan(customers, selected))
    );
    isNoneSelected$ = this.store.select(
        CustomerListSelectors.GET_IS_NONE_SELECTED
    );
    isAllSelected$ = this.store.select(
        CustomerListSelectors.GET_IS_ALL_SELECTED
    );
    selectedCount$ = this.store.select(
        CustomerListSelectors.GET_SELECTED_COUNT
    );
    total$ = this.store.select(CustomerListSelectors.GET_TOTAL);
    loading$ = this.store.select(CustomerListSelectors.GET_LOADING);
    query$ = this.store.select(CustomerListSelectors.GET_QUERY);

    FilterFields = FilterFields;
    BookingType = BookingType;

    private readonly _destroying$ = new Subject<void>();
    private modalRef: DynamicDialogRef;

    constructor(
        private router: Router,
        private prettifyDateString: PrettifyDateString,
        private environmentService: EnvironmentService,
        private store: Store,
        private dialogService: DialogService,
        private latestPipe: LatestPipe,
        public filterService: FilterService
    ) {}

    async ngOnInit(): Promise<void> {
        await this.filterService.resetAll();
        this.store.dispatch(CustomerListActions.LOAD_FILTER_VALUES());
    }

    async ngOnDestroy(): Promise<void> {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    async openModal(customer: CustomerListData, activeTab = 0): Promise<void> {
        this.modalRef = this.dialogService.open(CustomerModalComponent, {
            data: {
                id: customer.id,
                sub: customer.sub,
                activeTab,
            },
            header: 'Nutzerdetails: ' + customer.displayName,
            width: '80%',
            height: '90%',
            contentStyle: {
                backgroundColor: '#fff',
                height: '100%',
            },
            dismissableMask: true,
        });
    }

    mailtoSelected() {
        this.store.dispatch(CustomerListActions.MAIL_TO_SELECTED());
    }

    createExport(): void {
        this.store.dispatch(CustomerListActions.DOWNLOAD_USER_EXPORT());
    }

    async load(
        event: LazyLoadEvent,
        currentQuery: CustomerListQuery
    ): Promise<void> {
        if (
            event.first !== currentQuery.first ||
            event.rows !== currentQuery.rows
        ) {
            this.store.dispatch(
                CustomerListActions.SET_QUERY({
                    query: {
                        first: event.first,
                        rows: event.rows,
                    },
                })
            );
        }
    }

    async toggle(sub: UUID): Promise<void> {
        return this.store.dispatch(CustomerListActions.TOGGLE_SELECT({ sub }));
    }

    async selectAll(): Promise<void> {
        return this.store.dispatch(CustomerListActions.SELECT_ALL());
    }

    async deselectAll(): Promise<void> {
        return this.store.dispatch(CustomerListActions.DESELECT_ALL());
    }

    private mapForRowSpan(
        customers: CustomerListData[],
        selected: UUID[]
    ): (CustomerListData & {
        courses: (CustomerListDataCourse & {
            latestExam: CustomerListDataExam;
        })[];
        selected: boolean;
    })[] {
        return customers.map(customer => ({
            ...customer,
            courses: customer.courses.map(course => ({
                ...course,
                latestExam: this.latestPipe.transform(course.exams),
            })),
            selected: selected.includes(customer.sub),
        }));
    }
}
