<p-table [value]="exams" scrollable="true" dataKey="examParticipation.id">
    <ng-template pTemplate="header">
        <tr>
            <th>Rechtsgebiet</th>
            <th>Zeitraum</th>
            <th>Ort</th>
            <th>Buchung</th>
            <th>Status</th>
            <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="emptymessage">
        <p>Dieser Nutzer hat noch keine Prüfungen gebucht.</p>
    </ng-template>
    <ng-template pTemplate="body" let-examData>
        <tr>
            <td>{{ examData.legalArea.name }}</td>
            <td>
                {{
                    examData.exam.examinationPeriods
                        | examTimes: 'start'
                        | date: 'dd.MM.YYYY HH:mm':'Europe/Berlin':'de'
                }}
                Uhr<br />
                -
                {{
                    examData.exam.examinationPeriods
                        | examTimes: 'end'
                        | date: 'dd.MM.YYYY HH:mm':'Europe/Berlin':'de'
                }}
                Uhr
            </td>
            <td>
                {{ examData.exam.address.city }}
                <span *ngIf="examData.exam.address.name">
                    , {{ examData.exam.address.name }}
                </span>
            </td>
            <td>
                {{
                    examData.createdAt
                        | date: 'dd.MM.YYYY HH:mm':'Europe/Berlin':'de'
                }}
                Uhr
            </td>
            <td>{{ examData.status | examState }}</td>
            <td>
                <button
                    pButton
                    type="button"
                    icon="pi pi-pencil"
                    class="p-button-outlined p-button-rounded p-button-raised p-button-secondary p-button-sm p-ml-2"
                    (click)="
                        openEditExamParticipationOverlay(
                            examData.id,
                            examData.status
                        )
                    "
                ></button>
                <span class="p-px-2">
                    <button
                        *ngIf="examData.status !== 'PASSED'"
                        pButton
                        class="p-button-rounded p-button-outlined p-button-danger"
                        icon="pi pi-trash"
                        (click)="deleteExamParticipation(examData.id, $event)"
                    ></button>
                </span>
            </td>
        </tr>
    </ng-template>
</p-table>
<p-dialog
    [(visible)]="editExamDialogVisible"
    appendTo="body"
    [style]="{ minWidth: '320px' }"
    [dismissableMask]="true"
>
    <ng-template pTemplate="header">
        <h3>Prüfungsstatus</h3>
    </ng-template>
    <ng-template pTemplate="content">
        <form [formGroup]="editExamParticipationForm">
            <p-dropdown
                [options]="examStatusOptions"
                optionLabel="label"
                optionValue="value"
                formControlName="status"
                appendTo="body"
                styleClass="c-width-100"
            ></p-dropdown>
        </form>
    </ng-template>
    <ng-template pTemplate="footer">
        <button
            pButton
            type="button"
            icon="pi pi-save"
            label="Speichern"
            class="p-button p-button-raised p-button-primary p-mr-0"
            (click)="saveExamParticipation($event)"
        ></button>
    </ng-template>
</p-dialog>
