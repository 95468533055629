import { Pipe, PipeTransform } from '@angular/core';
import { CourseType } from '../../../../../../shared/interfaces/src/lib/data-management/course/course';

@Pipe({ name: 'courseType' })
export class CourseTypePipe implements PipeTransform {
    transform(courseType: CourseType, isTest = false, short = false): string {
        switch (courseType) {
            case CourseType.EducationCourse:
                return isTest
                    ? short
                        ? 'T'
                        : 'Testzugang'
                    : short
                    ? 'A'
                    : 'Ausbildung';
            case CourseType.TrainingCourse:
                return short ? 'F' : 'Fortbildung';
        }
    }
}
