import { Pipe, PipeTransform } from '@angular/core';
import { BookingType } from '../model';
import { CourseType } from '../../../../../../shared/interfaces/src';
import { CourseTypePipe } from './course-type.pipe';

@Pipe({ name: 'bookingType' })
export class BookingTypePipe implements PipeTransform {
    constructor(private courseTypePipe: CourseTypePipe) {}

    transform(
        value: BookingType,
        courseType?: CourseType,
        isTest?: boolean,
        short = false
    ): string {
        switch (value) {
            case BookingType.COURSE:
                return this.courseTypePipe.transform(courseType, isTest, short);
            case BookingType.EXTENSION:
                return short ? 'V' : 'Verlängerung';
            case BookingType.EXAM:
                return short ? 'P' : 'Prüfung';
        }
    }
}
