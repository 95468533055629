<span [ngSwitch]="status">
    <span *ngSwitchCase="CourseStatus.EDUCATION_MODULE_1">I</span>
    <span *ngSwitchCase="CourseStatus.EDUCATION_MODULE_2">II</span>
    <span *ngSwitchCase="CourseStatus.EDUCATION_MODULE_3">III</span>
    <span *ngSwitchCase="CourseStatus.EDUCATION_MODULE_4">IV</span>
    <span *ngSwitchCase="CourseStatus.EDUCATION_REPETITORIUM">R</span>
    <span *ngSwitchCase="CourseStatus.TRAINING_IN_PROGRESS">FA</span>
    <span *ngSwitchCase="CourseStatus.TRAINING_DONE">FB</span>
    <span *ngSwitchCase="CourseStatus.EDUCATION_DONE">
        <i class="pi pi-check"></i>
    </span>
    <span *ngSwitchDefault>-</span>
</span>
