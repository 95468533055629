import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { EnvironmentService } from '../../../../../../libs/angular/services/src/lib/environment/environment.service';
import { PrimeNGConfig } from 'primeng/api';
import { AngularPrimeNgSettingsModule } from '@cna/angular/prime-ng-settings';
import { Store } from '@ngrx/store';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { BrowserUtils, InteractionStatus } from '@azure/msal-browser';
import { Location } from '@angular/common';
import { filter, takeUntil } from 'rxjs/operators';
import { setNoticeAlreadyBooked } from './+state/actions';

@Component({
    selector: 'cna-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    showPage = false;
    isIframe = false;
    title = environment.title;
    environment = environment;
    menuItems = [
        {
            label: 'Rechtsgebiete',
            icon: 'pi pi-fw pi-briefcase',
            routerLink: 'legalarea',
        },
        {
            label: 'Nutzerverwaltung',
            icon: 'pi pi-fw pi-users',
            routerLink: 'customer',
        },
        {
            label: 'Rabattcodes',
            icon: 'pi pi-fw pi-percentage',
            routerLink: 'discountcode',
        },
        {
            label: 'Prüfungsverwaltung',
            icon: 'pi pi-fw pi-file',
            routerLink: 'exam/list',
        },
    ];
    user: any;

    private readonly _destroying$ = new Subject<void>();

    constructor(
        public authService: MsalService,
        private msalBroadcastService: MsalBroadcastService,
        private router: Router,
        private environmentService: EnvironmentService,
        private primeConfig: PrimeNGConfig,
        private primeSettingsProvider: AngularPrimeNgSettingsModule,
        private store: Store,
        private http: HttpClient,
        private location: Location
    ) {
        this.primeConfig.setTranslation(
            this.primeSettingsProvider.getGermanCalendarLocalisation()
        );
        this.environmentService.setEnvironment(environment);
    }

    ngOnInit() {
        this.store.dispatch(setNoticeAlreadyBooked({ value: false }));
        this.isIframe =
            BrowserUtils.isInIframe() &&
            !window.opener &&
            this.location.path().indexOf('logout') < 0;

        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                    (status: InteractionStatus) =>
                        status === InteractionStatus.None
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                this.checkAdmin();
            });
    }

    ngOnDestroy() {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    private checkAdmin() {
        this.http.get(environment.apiHost + '/customer/is-admin').subscribe(
            async res => {
                if (!res) {
                    this.authService.logout();
                } else {
                    this.showPage = true;
                }
            },
            async error => {
                if (error.error.statusCode === 401) {
                    this.authService.logout();
                }
            }
        );
    }
}
