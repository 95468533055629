import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CUSTOMER_STORE_NAME, CustomerReducer } from './reducer';
import { UUID } from '../../../../../../../../../shared/generic-types/src';

export const GET_STATE =
    createFeatureSelector<CustomerReducer>(CUSTOMER_STORE_NAME);

export const GET_CUSTOMER = (sub: UUID) =>
    createSelector(GET_STATE, state => state.data[sub]);

export const GET_LOADING = createSelector(GET_STATE, ({ loading }) => loading);

export const GET_COURSE_PARTICIPATION = (
    sub: UUID,
    courseParticipationId: UUID
) =>
    createSelector(GET_CUSTOMER(sub), customer =>
        customer.courseParticipations.find(
            cP => cP.id === courseParticipationId
        )
    );

export const GET_EXAM_PARTICIPATION = (
    sub: UUID,
    courseParticipationId: UUID
) =>
    createSelector(GET_CUSTOMER(sub), customer =>
        customer.bookedExams.find(
            ex => ex.courseParticipationId === courseParticipationId
        )
    );
