import type { UUID } from '../../../../../../../../../shared/generic-types/src';
import { TransactionType } from './transaction-type.enum';
import { isTransaction, Transaction } from './transaction';
import { BookingType } from './booking-type.enum';
import { CourseType } from '../../../../../../../shared/interfaces/src';

export abstract class Booking extends Transaction {
    bookingType: BookingType;
    isPaid: boolean;
    legalAreaCode: string;
    courseType: CourseType;
    courseParticipationId?: UUID;
}

export function isBooking(booking: any): booking is Booking {
    return (
        isTransaction(booking) &&
        booking.transactionType === TransactionType.BOOKING
    );
}
