import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    Output,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CourseType } from '../../../../../../../shared/interfaces/src';
import {
    AddPaymentEvent,
    Booking,
    BookingType,
    CorrectInvoiceEvent,
    DeletePaymentEvent,
    DownloadInvoiceEvent,
    isBooking,
    Payment,
    Transaction,
    TransactionType,
    TriggerPaymentRequestEvent,
    UpdateIsPaidEvent,
} from '../../model';
import type {
    CurrencyValue,
    UUID,
} from '../../../../../../../../../shared/generic-types/src';
import { InvoiceNumberPipe } from '../../pipes';
import { ConfirmEventType } from 'primeng/api';

@Component({
    selector: 'cna-customer-payments',
    templateUrl: './customer-payments.component.html',
    styleUrls: ['./customer-payments.component.scss'],
})
export class CustomerPaymentsComponent implements OnChanges {
    @Input() transactions: Transaction[];
    @Input() balance: CurrencyValue;
    @Output() downloadInvoice = new EventEmitter<DownloadInvoiceEvent>();
    @Output() addPayment = new EventEmitter<AddPaymentEvent>();
    @Output() deletePayment = new EventEmitter<DeletePaymentEvent>();
    @Output() updateIsPaid = new EventEmitter<UpdateIsPaidEvent>();
    @Output() triggerPaymentRequest =
        new EventEmitter<TriggerPaymentRequestEvent>();
    @Output() correctInvoice = new EventEmitter<CorrectInvoiceEvent>();

    readonly TransactionType = TransactionType;
    readonly CourseType = CourseType;
    readonly BookingType = BookingType;

    addPaymentDialogVisible = false;
    deletePaymentDialogVisible = false;
    correctInvoiceDialogVisible = false;

    invoiceOptions: {
        label: string;
        value: {
            invoiceId: UUID;
            bookingType: BookingType;
            isCorrection: boolean;
        };
    }[] = [];

    addPaymentForm = new FormGroup({
        date: new FormControl(null, [Validators.required]),
        amount: new FormControl(null, [Validators.required]),
        invoice: new FormControl(null, [Validators.required]),
    });

    deletePaymentForm = new FormGroup({
        paymentId: new FormControl(),
        amount: new FormControl(),
    });

    correctInvoiceForm = new FormGroup({
        invoiceId: new FormControl(null, [Validators.required]),
        bookingType: new FormControl(null, [Validators.required]),
        totalAmount: new FormControl(null, [Validators.required]),
    });

    constructor(private invoiceNumberPipe: InvoiceNumberPipe) {}

    ngOnChanges() {
        this.invoiceOptions =
            this.transactions
                ?.filter(
                    transaction =>
                        isBooking(transaction) &&
                        !transaction.isPaid &&
                        (transaction.invoice ||
                            transaction.invoiceCorrections?.length)
                )
                .map((booking: Booking) => ({
                    value: {
                        invoiceId: booking.invoiceCorrections?.length
                            ? booking.invoiceCorrections[0].id
                            : booking.invoice.id,
                        bookingType: booking.bookingType,
                        isCorrection: !!booking.invoiceCorrections?.length,
                    },
                    label: this.invoiceNumberPipe.transform(
                        booking.invoiceCorrections?.length
                            ? booking.invoiceCorrections[0]
                            : booking.invoice
                    ),
                })) ?? [];
    }

    async emitAddPayment(event: Event): Promise<void> {
        const paymentData = this.addPaymentForm.getRawValue();
        const date = paymentData.date as Date;
        this.addPayment.emit({
            date: date.toISOString(),
            amount: paymentData.amount * 100,
            invoiceId: paymentData.invoice.invoiceId,
            bookingType: paymentData.invoice.bookingType,
            originalEvent: event,
            isCorrection: paymentData.invoice.isCorrection,
        });
        this.addPaymentDialogVisible = false;
    }

    emitDeletePayment(confirmEventType: ConfirmEventType) {
        if (confirmEventType === ConfirmEventType.ACCEPT) {
            const { paymentId, amount } = this.deletePaymentForm.getRawValue();
            this.deletePayment.emit({
                id: paymentId,
                amount,
                originalEvent: null,
            });
        }
        this.deletePaymentDialogVisible = false;
    }

    async emitDownloadInvoice(
        invoice: Booking['invoice'],
        bookingType: BookingType,
        isCorrection: boolean,
        originalEvent: Event
    ): Promise<void> {
        return this.downloadInvoice.emit({
            bookingType,
            invoiceId: invoice.id,
            isCorrection,
            originalEvent,
        });
    }

    emitUpdateIsPaid(
        booking: Booking,
        { checked, originalEvent }: { checked: boolean; originalEvent: Event }
    ) {
        this.updateIsPaid.emit({
            id: booking.id,
            bookingType: booking.bookingType,
            isPaid: checked,
            originalEvent,
        });
    }

    async emitTriggerPaymentRequest(
        booking: Booking,
        event: Event
    ): Promise<void> {
        this.triggerPaymentRequest.emit({
            id: booking.id,
            bookingType: booking.bookingType,
            originalEvent: event,
        });
    }

    emitCorrectInvoice(event: Event): void {
        const { invoiceId, bookingType, totalAmount } =
            this.correctInvoiceForm.getRawValue();
        this.correctInvoice.emit({
            invoiceId,
            bookingType,
            totalAmount: Number.parseInt(totalAmount * 100 + '', 10),
            originalEvent: event,
        });
        this.correctInvoiceDialogVisible = false;
    }

    async openAddPaymentModal(): Promise<void> {
        this.addPaymentForm.patchValue({
            date: new Date(),
            amount: 0,
            invoice: this.invoiceOptions[0].value,
        });
        this.addPaymentDialogVisible = true;
    }

    async openDeletePaymentModal(payment: Payment): Promise<void> {
        this.deletePaymentForm.patchValue({
            paymentId: payment.id,
            amount: payment.amount,
        });
        this.deletePaymentDialogVisible = true;
    }

    async openCorrectInvoiceModal(booking: Booking): Promise<void> {
        this.correctInvoiceForm.patchValue({
            totalAmount: (-1 * booking.amount) / 100,
            invoiceId: booking.invoice.id,
            bookingType: booking.bookingType,
        });
        this.correctInvoiceDialogVisible = true;
    }
}
