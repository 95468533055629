export enum FilterFields {
    displayName = 'displayName',
    balance = 'balance',
    legalAreaCode = 'legalAreaCode',
    products = 'products',
    bookingDateFrom = 'bookingDateFrom',
    bookingDateTo = 'bookingDateTo',
    invoice = 'invoice',
    status = 'status',
    examStart = 'examStart',
    examLocation = 'examLocation',
    examStatus = 'examStatus',
    annotation = 'annotation',
}
