import { TransactionType } from './transaction-type.enum';
import type { UUID } from '../../../../../../../../../shared/generic-types/src';

export abstract class Transaction {
    id: UUID;
    date: string;
    amount: number;
    transactionType: TransactionType;
    invoice: {
        id: UUID;
        prefix: string;
        number: number;
        suffix?: string;
        isCorrection?: boolean;
    };
    invoiceCorrections?: {
        id: UUID;
        prefix: string;
        number: number;
        suffix: string;
    }[];
}

export function isTransaction(transaction: any): transaction is Transaction {
    return 'amount' in transaction && 'transactionType' in transaction;
}
