import { NgModule } from '@angular/core';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [CommonModule, ButtonModule],
    declarations: [StatusBarComponent],
    exports: [StatusBarComponent],
})
export class AngularUiStatusBarModule {}
