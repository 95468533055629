import { Pipe, PipeTransform } from '@angular/core';
import { TransactionType } from '../model';

@Pipe({ name: 'transactionType' })
export class TransactionTypePipe implements PipeTransform {
    transform(value: TransactionType): string {
        switch (value) {
            case TransactionType.BOOKING:
                return 'Buchung';
            case TransactionType.PAYMENT:
                return 'Zahlung';
        }
    }
}
