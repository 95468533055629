import { Injectable } from '@angular/core';
import { ModuleDocumentTemplate } from '@cna/projects/ak-jura/shared/interfaces';
import {
    EntityCollectionServiceBase,
    EntityCollectionServiceElementsFactory,
} from '@ngrx/data';
import { Id, UUID } from '@cna/shared/generic-types';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../../../apps/ak-jura/frontends/backoffice/src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ModuleDocumentTemplateService extends EntityCollectionServiceBase<ModuleDocumentTemplate> {
    constructor(
        elementsFactory: EntityCollectionServiceElementsFactory,
        private http: HttpClient
    ) {
        super('Module-document-template', elementsFactory);
    }

    /***
     * downloads and return the moduleDocumentTemplate for the given moduleId
     * @param moduleId: id of the module
     */
    getTemplateByModuleId(
        moduleId: Id | UUID
    ): Observable<ModuleDocumentTemplate> {
        this.setLoading(true);
        const ret = this.http.get<ModuleDocumentTemplate>(
            environment.apiHost + '/module-document-templates',
            {
                params: {
                    moduleId:
                        typeof moduleId === 'string'
                            ? moduleId
                            : moduleId.toString(),
                },
            }
        );
        this.setLoading(false);
        return ret;
    }
}
