import { NumberingTypes } from '../../../../../../libs/angular/services/src/lib/numbering/numbering.service';

export const sharedEnvironment = {
    title: 'Backoffice',
    numberOfModulesEducationCourse: 4,
    numberingOrder: [
        NumberingTypes.UPPERCASE_LETTERS,
        NumberingTypes.UPPERCASE_ROMAN,
        NumberingTypes.DIGITS,
        NumberingTypes.LOWERCASE_ROMAN,
        NumberingTypes.LOWERCASE_LETTERS,
    ],
    pricingStructureElementsEducationCourse: [
        {
            name: 'Standard',
            isSelectable: true,
            price: 168000, //cents
            condition: 'Text fehlt noch',
            details: 'Text fehlt noch',
            associatedCourseId: '',
        },
        {
            name: 'Junganwalt',
            isSelectable: true,
            price: 158000, //cents
            condition: 'weniger als drei Jahre Zugehörigkeit zur Anwaltschaft',
            details: 'Text fehlt noch',
            associatedCourseId: '',
        },
        {
            name: 'Arbeitslos oder Rechtsreferendar/in',
            isSelectable: true,
            price: 138000, //cents
            condition: 'Geeigneten Nachweis nachreichen',
            details: 'Text fehlt noch',
            associatedCourseId: '',
        },
    ],
    pricingStructureElementsTrainingCourse: [
        {
            name: 'Standard',
            isSelectable: true,
            price: 15000, //cents
            condition: 'Text fehlt noch',
            details: 'Text fehlt noch',
            associatedCourseId: '',
        },
    ],
    legalAreaIconsFileNames: [
        'Arbeitsrecht.svg',
        'BankUndKapitalmarktrecht.svg',
        'BauUndArchitektenrecht.svg',
        'Erbrecht.svg',
        'Familienrecht.svg',
        'GewerblicherRechtsschutz.svg',
        'HandelsUndGesellschaftsrecht.svg',
        'InformationsTechnologierecht.svg',
        'Insolvenzrecht.svg',
        'Medienrecht.svg',
        'Medizinrecht.svg',
        'Sozialrecht.svg',
        'Strafrecht.svg',
        'Vergaberecht.svg',
        'Verkehrsrecht.svg',
        'Versicherungsrecht.svg',
        'Verwaltungsrecht.svg',
        'WegMietrecht.svg',
    ],
    customerIdLength: 5,
};
