import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CustomerListFilter, CustomerListFilterValues } from '../model';
import { UUID } from '../../../../../../../../shared/generic-types/src';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
    CustomerBaseData,
    CustomerListData,
} from '../../../../../../shared/interfaces/src';
import { environment } from '../../../../../../../../../apps/ak-jura/frontends/backoffice/src/environments/environment';
import { Dictionary } from '../utils';
import { saveAs } from 'file-saver';

@Injectable()
export class CustomerListService {
    private readonly API_HOST: string;

    constructor(private http: HttpClient) {
        this.API_HOST = environment.apiHost;
    }

    getSubs(filter: CustomerListFilter): Observable<{ subs: UUID[] }> {
        return this.http
            .post<UUID[]>(`${this.API_HOST}/customer/sub`, filter)
            .pipe(map(subs => ({ subs })));
    }

    getListData(
        subs: UUID[]
    ): Observable<{ customers: Dictionary<CustomerListData> }> {
        if (!subs?.length) {
            return of({ customers: {} });
        }
        return this.http
            .get<CustomerListData[]>(`${this.API_HOST}/customer`, {
                params: {
                    sub: subs.join(','),
                },
            })
            .pipe(
                map(customers => ({
                    customers: customers.reduce((dictionary, customer) => {
                        dictionary[customer.sub] = customer;
                        return dictionary;
                    }, {}),
                }))
            );
    }

    getFilterValues(): Observable<{ filterValues: CustomerListFilterValues }> {
        return this.http
            .get<CustomerListFilterValues>(
                `${this.API_HOST}/customer/filter-values`
            )
            .pipe(map(filterValues => ({ filterValues })));
    }

    sendMailToMany(subs: UUID[]): Observable<void> {
        return this.loadCustomerBaseDataForMany(subs).pipe(
            map(customers => {
                const mails = customers.map(customer => customer.email);
                const mailToString = `mailto:?bcc=${mails.join(';')}`;
                document.location.assign(mailToString);
                return;
            })
        );
    }

    loadCustomerBaseDataForMany(subs: UUID[]): Observable<CustomerBaseData[]> {
        return this.http.get<CustomerBaseData[]>(`${this.API_HOST}/users`, {
            params: {
                sub: subs.join(','),
            },
        });
    }

    downloadUserList(): Observable<boolean> {
        return this.http
            .post<{
                base64Content: string;
                contentType: 'application/pdf';
                filename: string;
            }>(`${this.API_HOST}/customer/export`, {})
            .pipe(
                map(data => {
                    saveAs(
                        this.base64ToBlob(data.base64Content, data.contentType),
                        data.filename
                    );
                    return true;
                })
            );
    }

    /**
     * Decodes base64 content and generates a Blob from it.
     * The processing is done in chunks of 512bytes because of performance optimization.
     *
     * @see https://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
     * @param base64Content
     * @param contentType
     * @private
     */
    private base64ToBlob(base64Content: string, contentType: string): Blob {
        const byteCharacters = atob(base64Content);
        const byteArrays = [];

        const sliceSize = 512;
        for (
            let offset = 0;
            offset < byteCharacters.length;
            offset += sliceSize
        ) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            byteArrays.push(new Uint8Array(byteNumbers));
        }

        return new Blob(byteArrays, { type: contentType });
    }
}
